
import { CUSTOMER_FEATURE} from '@/model/dn-customer';
import { fetchAndCheckJson } from '@/js/dn-fetch.js';
import { addDays, getDayDiff } from "@/js/dn-helper.js";
import { getShortDate } from "@/js/dn-uihelper.js";
import { fromISODate, toISODate } from '@/js/dn-helper.js';
import { COLORS } from '@/js/dn-colors.js';
import * as dn_uiHelper from "@/js/dn-uihelper.js";

const MONTH_NAMES = getMonthNames();

export async function getInvoicesByYear() {
  /** @type {{id:number, year:number, month:number,info:{}}[]} */
  const invoices = await fetchAndCheckJson(`invoice?onlyRelevantStatus=0`, 'GET');
  const byYear = [];
  /** @type {null|{year:number, invoices:{id:number, month:number}[]}} */
  let data = null;
  for (const invoice of invoices) {
    if (data === null || data.year !== invoice.year) {
      data = { year: invoice.year, invoices: [] };
      byYear.push(data);
    }

    invoice.dt =new Date(invoice.dt)

    let info=getInvoiceStatusInfo(invoice)
    data.invoices.push({ id: invoice.id, month: invoice.month,info:info });
  }

  return byYear;
}

export async function getRelevantWorstInvoiceStatus(){
  const relevantInvoiceStatuses = await fetchAndCheckJson(`invoice?onlyRelevantStatus=1`, 'GET'); 
let worstStatus = 0
  for(let i=0;i<relevantInvoiceStatuses.length;i++){
   let invoice=relevantInvoiceStatuses[i]
    invoice.dt =new Date(invoice.dt)
    let x=getInvoiceStatusInfo(invoice);
    if(x.status>worstStatus&&!(x.status==INVOICE_STATUS.paid||x.status==INVOICE_STATUS.removed)){worstStatus=x.status}
  }
  return worstStatus
}

export async function getInvoices() {
  /** @type {{id:number, year:number, month:number}[]} */
  const invoiceList = await fetchAndCheckJson('invoice', 'GET');
  let invoices=[]
  for(let i=0;i<invoiceList.length;i++){
    let inv = invoiceList[i]
    inv.invoiceInfo=getInvoiceStatusInfo(inv)
    invoices.push(inv)
}
  return invoices;
}





/**
 * @param {number} id
 */
export async function getInvoice(id) {
  const r = await fetchAndCheckJson('invoice/' + id, 'GET');
  let x = new Invoice(r);
  return x;
}

/**
 * @param {string} currency
 * @param {number} unit_amount
 * @param {string} itemName
 * @param {string} stripeId
 * @param {string} memo
 */
export async function getPaymentLink(currency,unit_amount,itemName,stripeId,memo){
  const r = await fetchAndCheckJson('payment_link','POST',{currency:currency,unit_amount:unit_amount,itemName:itemName,stripeId:stripeId,memo:memo});
  return r;
}

/**
 * @param {number} id
 * @param {number} status
 * @param {string} paymentLink
 */
export async function updateInvoice(id,status=undefined,paymentLink=undefined,users=undefined,scheduled=undefined,payDt=null,paymentLinkId=undefined) {
  const result =  await fetchAndCheckJson(`invoice/${id}`, 'PATCH',{status: status, paymentlink: paymentLink, users: users, scheduled: scheduled,payDt: toISODate(payDt),paymentLinkId: paymentLinkId});
  return result
}

/**
 * @param {number} year
 * @param {number} month
 */
export function getTitleDate(year, month) {
  return MONTH_NAMES[month - 1] + '-' + year;
}

export function getInvoiceStatus(invoice){
  
  let x = invoice.status 
  if(x===null){x=0}
  if(invoice.payDt&&new Date(invoice.payDt)>new Date("1980-01-01")){
    return INVOICE_STATUS.paid}
  
 if(x==INVOICE_STATUS.removed){
    return x
  }else{
    let dueDate=dn_uiHelper.addDays(new Date(invoice.dt),invoice.paymentTerms) 
    let daysDue=(new Date().getTime()-dueDate.getTime())/(1000*60*60*24)-7
    if(daysDue<0){return x }
    else if(daysDue<14){return INVOICE_STATUS.due}
    else{return INVOICE_STATUS.overdue30}
  }
}

export function getInvoiceStatusInfo(i){
  let status = getInvoiceStatus(i)
  switch (status) {
    case 0:
      return {text:"",color:COLORS.lightinfo,status:INVOICE_STATUS.initiated}
      break;
    case 1:
    return {text:"released",color:COLORS.lightinfo,status:INVOICE_STATUS.released}
    break;
    case 2:
    return {text:"downloaded",color:COLORS.stroke4,status:INVOICE_STATUS.downloaded}
    break;
    case 3:
    return {text:"due",color:COLORS.stroke2,status:INVOICE_STATUS.due}
    break;
    case 4:
    return {text:"overdue",color:COLORS.stroke3,status:INVOICE_STATUS.overdue30}
    break;
    case 5:
    return {text:"paid",color:COLORS.stroke5,status:INVOICE_STATUS.paid}
    break;
    case 6:
    return {text:"paid",color:COLORS.dark,status:INVOICE_STATUS.removed}
    break;
    default:
      break;
  }
}

export class Invoice {
  /**
   * @param {{id:number, year:number, month:number, customerId:number, customerName:string, extraStorage:number, currency:string, vat:string|null, address:string, person:string, users:number, scheduled:number, paymentTerms:number, paymentLink:string, status:number, payDt:Date, features:{featureId:number, fee:number; dayCount:number|null}[], stripeId:string, paymentLinkId:string}} r
   */
  constructor(r) {
    const invoiceDate = new Date(r.year, r.month - 1);
    /** @type {number} */
    this.daysInMonth = getDayDiff(new Date(r.year, r.month), invoiceDate);
    this.invoiceNumber = r.id;
    this.customerNumber = r.customerId;
    this.invoiceDate = getShortDate(invoiceDate);
    this.customer = r.customerName;
    this.customerAdress = r.address;
    this.name = r.person;
    this.paymentTerms = r.paymentTerms;
    this._status = r.status;
    this.payDt = r.payDt;
    this.dueDate = getShortDate(addDays(invoiceDate, this.paymentTerms));
    this.licences = r.users + r.scheduled;
    this.stripeId = r.stripeId;
    this.paymentLinkId = r.paymentLinkId;
    /** @private @type {{featureId:number, fee:number; dayCount:number|null}[]} */
    this._features = r.features;
    let dayFactors = [];
    for (let index = 0; index < r.features.length; index++) {
      const feature = r.features[index];
      let fee = feature.fee;
      if (feature.dayCount !== null && feature.dayCount < this.daysInMonth) {
        dayFactors[feature.featureId] = feature.dayCount / this.daysInMonth;
      } else {
        dayFactors[feature.featureId] = 1;
      }
      if (feature.featureId === CUSTOMER_FEATURE.main) { this.licenceFee = fee }
      if (feature.featureId === CUSTOMER_FEATURE.storage) { this.storageFee = fee }
      if (feature.featureId === CUSTOMER_FEATURE.sms) { this.smsFee = fee }
      if (feature.featureId === CUSTOMER_FEATURE.adherence) { this.adherenceFee = fee }
    }
    this.storageAmount = r.extraStorage;
    this.currency = r.currency;
    if (this.currency == 'USD') {
      this.iban = 'SE6650000000050598297065';
    } else {
      this.iban = 'SE9550000000051671037391';
    }
    this.bic = 'ESSESESS';
    this.paymentLink = r.paymentLink
    this.title = getTitleDate(r.year, r.month);
    this.licenseTotal = this.round2(this.licenceFee * this.licences * dayFactors[CUSTOMER_FEATURE.main]);
    this.smsTotal = this.round2(this.smsFee * this.licences * dayFactors[CUSTOMER_FEATURE.sms]);
    this.adherenceTotal = this.round2(this.adherenceFee * this.licences * dayFactors[CUSTOMER_FEATURE.adherence]);
    this.storageTotal = this.round2(this.storageFee * this.storageAmount * this.licences * dayFactors[CUSTOMER_FEATURE.storage]);
    let total = this.round2(this.licenseTotal + (this.smsTotal ? this.smsTotal : 0) + (this.storageTotal ? this.storageTotal : 0) + (this.adherenceTotal ? this.adherenceTotal : 0));
    if (r.stripeId) { this.creditCardFee = this.round2(total * 0.035) }
    this.total = (this.licenseTotal + (this.smsTotal ? this.smsTotal : 0) + (this.storageTotal ? this.storageTotal : 0) + (this.adherenceTotal ? this.adherenceTotal : 0) + (this.creditCardFee ? this.creditCardFee : 0));
  }





    /**
   * @param {number} amount
   */
  round2(amount){
      return Math.round(amount*100)/100
  }

  /**
   * @param {number} featureId
   */
  getDayInfo(featureId) {
    for (const feature of this._features) {
      if (feature.featureId === featureId) {
        if (feature.dayCount !== null && feature.dayCount < this.daysInMonth) {
          return ` (${feature.dayCount} days)`;
        }
        return '';
      }
    }
  }
}

function getMonthNames() {
  const monthFormat = new Intl.DateTimeFormat(['en-US'], { month: 'short' });
  const monthNames = [];
  for (let m = 0; m < 12; m++) {
    const dt = new Date(2000, m);
    monthNames.push(monthFormat.format(dt).toLowerCase());
  }
  return Object.freeze(monthNames);
}

export const INVOICE_STATUS = createInvoiceStatus();
function createInvoiceStatus() {
  const enumObject = {
    initiated: 0,
    released: 1,
    downloaded: 2,
    due: 3,
    overdue30: 4,
    paid: 5,
    removed: 6,
  };

  return Object.freeze(enumObject);
}

export async function checkPaymentStatus(){
  const invoice = await fetchAndCheckJson(`payment_link`, 'GET'); 
  return invoice
}
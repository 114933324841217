import { fetchAndCheckJson } from '@/js/dn-fetch.js';
import { addDays } from '@/js/dn-helper.js';
import { createSchedule } from "@/model/dn-employee-schedule.js";
import { useDataStore } from "@/stores/dataStore.js";
import { useReportStore } from '@/stores/reportStore.js';

/**
 * @typedef {{id?:number;agreementId?:number;code:string;name:string;kind:number;taskTypes?:number[];stMinute:number|null;stDay:number|null;fiMinute:number|null;fiDay:number|null;weekLimitAbove:boolean|null}} PayrollRuleDto
 */

/**
 * @typedef {{id:number;name:string;tagId:number|null;payrollPeriod:number;payrollPeriodShiftBounds:boolean;payrollRules?:PayrollRuleDto[]}} AgreementDto
 */

/**
 * @typedef {{id?:number;name?:string;tagId?:number|null;payrollPeriod?:number;payrollPeriodShiftBounds?:boolean;payrollRules?:PayrollRuleDto[];deletedPayrollRules?:number[]}} SaveAgreementDto
 */

/**
 * @typedef {{empid:string;empName:string;code:string;payrollName:string;amount:number;unit:string}} PayrollReportRow 
 */

/**
 * @typedef {{st:Date;numberOfDays:number}} TimeRange
 */

export const PAYROLL_RULE_KIND = createPayrollRuleKind();

function createPayrollRuleKind() {
  const e = {
    hours: 0,
    dayCount: 1,
    /** @type {(t:(arg0:string) => string) => {id:number;name:string}[]} */
    getOptions: null
  };

  e.getOptions = function (t) {
    return [dd(e.hours, t('settings.hours')), dd(e.dayCount, t('settings.day-count'))];
  }

  return Object.freeze(e);
}

export const PAYROLL_RULE_DAY_KIND = createPayrollRuleDayKind();

function createPayrollRuleDayKind() {
  const e = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    holiday: 7,
    dayBeforeHoliday: 8,
    dayAfterHoliday: 9,
    /** @type {(t:(arg0:string) => string) => {id:number;name:string}[]} */
    getOptions: null
  };

  e.getOptions = function (t) {
    const result = []
    for (let i = 0; i < 7; i++) {
      const dt = new Date(2024, 0, 7 + i);
      result.push(dd(i, dt.toLocaleDateString('en-US', { weekday: 'long' })));
    }
    result.push(dd(e.holiday, t('settings.holiday')));
    result.push(dd(e.dayBeforeHoliday, t('settings.day-before-holiday')));
    result.push(dd(e.dayAfterHoliday, t('settings.day-after-holiday')));

    return result;
  }

  return Object.freeze(e);
}

export function getWeekLimitAboveOptions(t) {
  return [{ id: true, name: 'above' }, { id: false, name: 'below' }];
}

/**
 * 
 * @param {number} id
 * @param {string} name
 */
function dd(id, name) {
  return { id, name };
}

export class Agreements {
  constructor() {
    /** @private @type {Map<number, AgreementDto>} */
    this.byId = null;
    /** @private @type {Map<number, AgreementDto>} */
    this._byTagId = null;
    this.loadCounter = 0;
  }

  get byTagId() {
    if (this._byTagId === null && this.isLoaded) {
      /** @type {Map<number, AgreementDto>} */
      const byTagId = new Map();
      for (const dto of this.byId.values()) {
        byTagId.set(dto.tagId, dto);
      }
      this._byTagId = byTagId;
    }
    return this._byTagId;
  }

  get isLoaded() {
    return this.byId !== null;
  }

  get hasPayrollRules() {
    if (!this.isLoaded) { return undefined; }
    for (const agreement of this.byId.values()) {
      if (agreement.payrollRules.length > 0) {
        return true;
      }
    }
    return false;
  }

  asEditableList() {
    /** @type {EditableAgreement[]} */
    const list = [];
    if (this.byId) {
      for (const dto of this.byId.values()) {
        list.push(new EditableAgreement(dto));
      }
    }

    return list;
  }

  /**
   * @param {number} id
   */
  async delete(id) {
    await fetchAndCheckJson('agreement/' + id, 'DELETE');
    if (this.byId) {
      this.byId.delete(id);
      this._byTagId = null;
    }
  }

  async load() {
    /** @type {AgreementDto[]} */
    const dtoList = await fetchAndCheckJson('agreement', 'GET');
    const byId = new Map();
    for (const dto of dtoList) {
      byId.set(dto.id, dto);
    }
    this.byId = byId;
    this._byTagId = null;
    this.loadCounter += 1;
  }

  /**
   * @param {SaveAgreementDto[]} agreementsToSave
   */
  async save(agreementsToSave) {
    await fetchAndCheckJson('agreement', 'POST', agreementsToSave);
    await this.load();
  }

  /**
   * @private
   * @param {number[]} tagIdList
   * @param {AgreementDto} defaultAgreement
   */
  getByTagId(tagIdList, defaultAgreement = undefined) {
    const byTagId = this.byTagId;
    for (const tagId of tagIdList) {
      const dto = byTagId.get(tagId);
      if (dto) { return dto; }
    }
    if (defaultAgreement === undefined) {
      defaultAgreement = this._byTagId.get(null);
      if (defaultAgreement === undefined) {
        defaultAgreement = null;
      }
    }

    return defaultAgreement;
  }

  /**
   * @param {import("@/model/dn-employee").Employee} employee
   */
  getPayrollPeriod(employee) {
    const dataStore = useDataStore();
    const cc = dataStore.currentCC;
    const defaultAgreement = this.getByTagId(cc.tagIds);
    const agreement = this.getByTagId(employee.tagIds, defaultAgreement);
    return agreement ? agreement.payrollPeriod : 0;
  }

  /**
   * @param {TimeRange} timeRange
   * @param {import("@/model/dn-employee").Employee[]} employees
   * @param {import("@/model/dn-task.js").Task[]} scheduleTasks
   * @param {boolean} [joinSameCode]
   */
  getPayrollReportData(timeRange, employees, scheduleTasks, joinSameCode = false) {
    const dataStore = useDataStore();
    const cc = dataStore.currentCC;
    const holidays = dataStore.holidays;
    const taskTypes = dataStore.taskTypes;
    const reportOptions = useReportStore().reportOptions;
    const weeklyLimit = reportOptions.numHoursWeeklyOvertime ? reportOptions.numHoursWeeklyOvertime : 40;
    const sortedHolidays = getSortedHolidays(timeRange, holidays, reportOptions.weekendAsHoliday);
    const timeRangeWeeks = splitTimeRangeInWeeks(dataStore.generalEmpAppSettings.weekStartDay, timeRange);

    const defaultAgreement = this.getByTagId(cc.tagIds);
    /** @type {import("@/model/dn-employee").Employee[]} */
    const employeesWithAgreement = [];
    /** @type {Map<number, PayrollRuleCalcGroup[]>} */
    const payrollRuleGroupsById = new Map();
    /** @type {Map<number, PayrollRuleCalcGroup[]>} */
    const payrollRuleGroupsEmpId = new Map();

    for (const emp of employees) {
      const agreement = this.getByTagId(emp.tagIds, defaultAgreement);
      if (agreement) {
        employeesWithAgreement.push(emp);
        let groups = payrollRuleGroupsById.get(agreement.id);
        if (groups === undefined) {
          groups = [];
          for (const dto of agreement.payrollRules) {
            /** @type {PayrollRuleCalcGroup} */
            let group = undefined;
            if (joinSameCode && dto.code.length > 0) {
              group = groups.find(x => x.code === dto.code && x.kind === dto.kind);
            }
            if (!group) {
              group = new PayrollRuleCalcGroup(dto);
              groups.push(group);
            }
            if (dto.weekLimitAbove === null) {
              const payrollRule = new PayrollRuleCalc(taskTypes.byId, dto, timeRange, agreement.payrollPeriodShiftBounds, weeklyLimit, sortedHolidays);
              group.payrollRules.push(payrollRule);
            } else {
              for (const tr of timeRangeWeeks) {
                const payrollRule = new PayrollRuleCalc(taskTypes.byId, dto, tr, agreement.payrollPeriodShiftBounds, weeklyLimit, sortedHolidays);
                group.payrollRules.push(payrollRule);
              }
            }
          }
          if (joinSameCode) {
            for (const group of groups) {
              group.calcName();
            }
          }
          payrollRuleGroupsById.set(agreement.id, groups);
        }
        payrollRuleGroupsEmpId.set(emp.id, groups);
      }
    }

    const schedules = createSchedule(timeRange.numberOfDays, timeRange.st, scheduleTasks, taskTypes, employeesWithAgreement, true);
    /** @type {PayrollReportRow[]} */
    const rows = [];

    for (const employeeSchedule of schedules) {
      const groups = payrollRuleGroupsEmpId.get(employeeSchedule.emp.id);
      const empid = employeeSchedule.emp.empid;
      const empName = employeeSchedule.emp.name;
      for (const payrollRuleGroup of groups) {
        let amount = 0;
        for (const payrollRule of payrollRuleGroup.payrollRules) {
          amount += payrollRule.getAmount(employeeSchedule)
        }
        if (amount !== 0) {
          amount = Math.round(10000 * amount) / 10000;
          const code = payrollRuleGroup.code;
          const payrollName = payrollRuleGroup.name;
          const unit = payrollRuleGroup.unit;
          rows.push({ empid, empName, code, payrollName, amount, unit });
        }
      }
    }
    return rows;
  }
}

/**
 * @param {string} [name]
 */
export function createNewAgreement(name) {
  return new EditableAgreement({ id: undefined, name, tagId: null, payrollPeriod: 0, payrollPeriodShiftBounds: false, payrollRules: [] });
}

export class EditableAgreement {
  /**
   * @param {AgreementDto} dto
   */
  constructor(dto) {
    /** @private @type {AgreementDto} */
    this._dto = dto;
    /** @private @type {string} */
    this._name = dto.name;
    /** @private @type {number} */
    this._payrollPeriod = dto.payrollPeriod;
    /** @private @type {boolean} */
    this._payrollPeriodShiftBounds = dto.payrollPeriodShiftBounds;
    /** @private @type {number|null} */
    this._tagId = dto.tagId;
    /** @private @type {EditablePayrollRule[]} */
    this._payrollRules = dto.payrollRules.map(x => new EditablePayrollRule(this, x));
    /** @private @type {boolean} */
    this._hasChanges = false;
    /** @private @type {number[]} */
    this.deletedPayrollRules = [];
  }

  get id() {
    return this._dto.id;
  }

  get oldName() {
    return this._dto.name;
  }

  get name() {
    return this._name;
  }
  set name(value) {
    this._name = value;
    this.setHasChanges();
  }

  get payrollPeriod() {
    return this._payrollPeriod;
  }
  set payrollPeriod(value) {
    this._payrollPeriod = value;
    this.setHasChanges();
  }

  get payrollPeriodShiftBounds() {
    return this._payrollPeriodShiftBounds;
  }
  set payrollPeriodShiftBounds(value) {
    this._payrollPeriodShiftBounds = value;
    this.setHasChanges();
  }

  get payrollRules() {
    return this._payrollRules;
  }

  get tagId() {
    return this._tagId;
  }
  set tagId(value) {
    this._tagId = value;
    this.setHasChanges();
  }

  get hasChanges() {
    return this._hasChanges;
  }

  addPayrollRule() {
    this.payrollRules.push(new EditablePayrollRule(this, {
      code: '', name: '',
      kind: PAYROLL_RULE_KIND.hours, taskTypes: [], stMinute: 0, stDay: null, fiMinute: 0, fiDay: null, weekLimitAbove: null
    }));
  }

  /** @private */
  calcHasChanges() {
    if (this.id === undefined) { return true; }
    if (this.deletedPayrollRules.length > 0) { return true; }
    if (this.name !== this._dto.name) { return true; }
    if (this.payrollPeriod !== this._dto.payrollPeriod) { return true; }
    if (this.payrollPeriodShiftBounds !== this._dto.payrollPeriodShiftBounds) { return true; }
    if (this.tagId !== this._dto.tagId) { return true; }
    return this._payrollRules.some(x => x.hasChanges);
  }

  /**
   * @param {EditablePayrollRule} rule
   */
  deletePayrollRule(rule) {
    if (rule.id) {
      this.deletedPayrollRules.push(rule.id);
    }
    const index = this.payrollRules.findIndex(x => x === rule);
    this.payrollRules.splice(index, 1);
    this.setHasChanges();
  }

  setHasChanges() {
    this._hasChanges = this.calcHasChanges();
  }

  toSaveDto() {
    /** @type {PayrollRuleDto[]} */
    const payrollRules = [];
    for (const payrollRule of this.payrollRules) {
      if (payrollRule.hasChanges) {
        payrollRules.push(payrollRule.toDto());
      }
    }

    /** @type {SaveAgreementDto} */
    const dto = { id: this.id, deletedPayrollRules: this.deletedPayrollRules, payrollRules };
    if (this.name !== this._dto.name) { dto.name = this.name; }
    if (this.payrollPeriod !== this._dto.payrollPeriod) { dto.payrollPeriod = this.payrollPeriod; }
    if (this.payrollPeriodShiftBounds !== this._dto.payrollPeriodShiftBounds) { dto.payrollPeriodShiftBounds = this.payrollPeriodShiftBounds; }
    if (this.tagId !== this._dto.tagId) { dto.tagId = this.tagId; }
    return dto;
  }
}

class EditablePayrollRule {
  /**
   * @param {EditableAgreement} parent
   * @param {PayrollRuleDto} dto
   */
  constructor(parent, dto) {
    /** @private @type {EditableAgreement}  */
    this.parent = parent;
    /** @private @type {PayrollRuleDto} */
    this.dto = dto;
    /** @private @type {string} */
    this._code = dto.code;
    /** @private @type {number} */
    this._fiDay = dto.fiDay;
    /** @private @type {number} */
    this._fiMinute = dto.fiMinute;
    /** @private @type {number} */
    this._kind = dto.kind;
    /** @private @type {string} */
    this._name = dto.name;
    /** @private @type {number} */
    this._stDay = dto.stDay;
    /** @private @type {number} */
    this._stMinute = dto.stMinute;
    /** @private @type {number[]} */
    this._taskTypes = dto.taskTypes.slice();
    /** @private @type {boolean} */
    this._weekLimitAbove = dto.weekLimitAbove;

    /** @private @type {boolean} */
    this._hasChanges = false;
  }

  get id() {
    return this.dto.id;
  }

  get code() {
    return this._code;
  }
  set code(value) {
    this._code = value;
    this.setHasChanges();
  }

  get fiDay() {
    return this._fiDay;
  }
  set fiDay(value) {
    this._fiDay = value;
    this.setHasChanges();
  }

  get fiTime() {
    return minutesToDate(this._fiMinute);
  }
  set fiTime(value) {
    this._fiMinute = dateToMinutes(value);
    this.setHasChanges();
  }

  get kind() {
    return this._kind;
  }
  set kind(value) {
    this._kind = value;
    this.setHasChanges();
  }

  get name() {
    return this._name;
  }
  set name(value) {
    this._name = value;
    this.setHasChanges();
  }

  get stDay() {
    return this._stDay;
  }
  set stDay(value) {
    this._stDay = value;
    this.setHasChanges();
  }

  get stTime() {
    return minutesToDate(this._stMinute);
  }
  set stTime(value) {
    this._stMinute = dateToMinutes(value);
    this.setHasChanges();
  }

  get taskTypes() {
    return this._taskTypes;
  }
  set taskTypes(value) {
    value.sort();
    this._taskTypes = value;
    this.setHasChanges();
  }

  get weekLimitAbove() {
    return this._weekLimitAbove;
  }
  set weekLimitAbove(value) {
    this._weekLimitAbove = value;
    this.setHasChanges();
  }

  get hasChanges() {
    return this._hasChanges;
  }

  /** @private */
  calcHasChanges() {
    if (this.id === undefined) { return true; }
    if (this.code !== this.dto.code) { return true; }
    if (this.fiDay !== this.dto.fiDay) { return true; }
    if (this._fiMinute !== this.dto.fiMinute) { return true; }
    if (this.kind !== this.dto.kind) { return true; }
    if (this.name !== this.dto.name) { return true; }
    if (this.stDay !== this.dto.stDay) { return true; }
    if (this._stMinute !== this.dto.stMinute) { return true; }
    if (this.taskTypes.length !== this.dto.taskTypes.length) { return true; }
    if (this.weekLimitAbove !== this.dto.weekLimitAbove) { return true; }
    for (let i = 0; i < this.taskTypes.length; i++) {
      if (this.taskTypes[i] !== this.dto.taskTypes[i]) {
        return true;
      }
    }
    return false;
  }

  /** @private */
  setHasChanges() {
    this._hasChanges = this.calcHasChanges();
    this.parent.setHasChanges();
  }

  toDelete() {
    this.parent.deletePayrollRule(this);
  }

  /** @returns {PayrollRuleDto} */
  toDto() {
    /** @type {PayrollRuleDto} */
    const dto = {
      code: this.code, fiDay: this.fiDay, fiMinute: this._fiMinute, kind: this.kind, name: this.name,
      stDay: this.stDay, stMinute: this._stMinute, taskTypes: this.taskTypes, weekLimitAbove: this.weekLimitAbove
    };
    if (this.id) { dto.id = this.id; }
    return dto;
  }
}

class PayrollRuleCalc {
  /**
   * @param {Map<number, import("@/model/dn-tasktype.js").TaskType>} taskTypeById
   * @param {PayrollRuleDto} payrollRule
   * @param {TimeRange} timeRange
   * @param {boolean} payrollPeriodShiftBounds
   * @param {number} weeklyLimit
   * @param {Date[]} sortedHolidays
   */
  constructor(taskTypeById, payrollRule, timeRange, payrollPeriodShiftBounds, weeklyLimit, sortedHolidays) {
    const minutesPerDay = 24 * 60;
    /** @type {Map<number, Set<number>>} */
    this.taskTypesByKind = new Map();
    for (const taskTypeId of payrollRule.taskTypes) {
      const tt = taskTypeById.get(taskTypeId);
      let ttSet = this.taskTypesByKind.get(tt.kind);
      if (ttSet === undefined) {
        ttSet = new Set();
        this.taskTypesByKind.set(tt.kind, ttSet);
      }
      ttSet.add(tt.id);
    }
    /** @type {string} */
    this.code = payrollRule.code;
    /** @type {string} */
    this.name = payrollRule.name;
    /** @type {boolean} */
    this.unitDays = payrollRule.kind === PAYROLL_RULE_KIND.dayCount;
    /** @type {boolean} */
    this.useShiftBounds = payrollRule.kind === PAYROLL_RULE_KIND.dayCount || payrollPeriodShiftBounds;
    /** @type {TimeRange} */
    this.timeRange = timeRange
    /** @readonly @type {{st:Date;fi:Date}[]} */
    this.timeToRemove = [];
    /** @type {boolean} */
    this.weeklyLimitAbove = payrollRule.weekLimitAbove;
    /** @type {number} */
    this.weeklyLimit = weeklyLimit;
    if (!this.useShiftBounds) {
      this.addTimeToRemove(addDays(timeRange.st, -7), timeRange.st);
      const st = addDays(timeRange.st, timeRange.numberOfDays);
      this.addTimeToRemove(st, addDays(st, 7));
    }
    if (payrollRule.stDay !== null && payrollRule.fiDay !== null) {
      let stMinute = payrollRule.stMinute !== null ? payrollRule.stMinute : 0;
      let fiMinute = payrollRule.fiMinute !== null ? payrollRule.fiMinute : minutesPerDay;

      if (payrollRule.stDay < 7) {
        let numberOfDays = timeRange.numberOfDays + 1;
        /** @type {Date} */
        let st = null;
        /** @type {Date} */
        let fi = null;
        for (let i = -7; i < numberOfDays; i++) {
          const dt = addDays(timeRange.st, i);
          const day = dt.getDay();
          if (st === null) {
            if (payrollRule.fiDay === day) {
              st = new Date(dt);
              st.setMinutes(fiMinute);
            }
          }
          if (fi === null && (payrollRule.stDay !== payrollRule.fiDay || stMinute > fiMinute)) {
            if (payrollRule.stDay === day) {
              fi = new Date(dt);
              fi.setMinutes(stMinute);
              this.addTimeToRemove(st, fi);
              st = null;
              fi = null;
            }
          }
        }
        if (st !== null) {
          this.addTimeToRemove(st, addDays(timeRange.st, numberOfDays + 7));
        }
      } else {
        if (payrollRule.stDay === PAYROLL_RULE_DAY_KIND.dayBeforeHoliday) {
          stMinute -= minutesPerDay;
        } else if (payrollRule.stDay === PAYROLL_RULE_DAY_KIND.dayAfterHoliday) {
          stMinute += minutesPerDay;
        }
        if (payrollRule.fiDay === PAYROLL_RULE_DAY_KIND.dayBeforeHoliday) {
          fiMinute -= minutesPerDay;
        } else if (payrollRule.fiDay === PAYROLL_RULE_DAY_KIND.dayAfterHoliday) {
          fiMinute += minutesPerDay;
        }
        if (stMinute < fiMinute) {
          let st = addDays(timeRange.st, -7);
          let fi = null;
          for (const holiday of sortedHolidays) {
            fi = new Date(holiday);
            fi.setMinutes(stMinute);
            if (st < fi) {
              this.addTimeToRemove(st, fi);
            }
            st = new Date(holiday)
            st.setMinutes(fiMinute);
          }
          fi = addDays(timeRange.st, timeRange.numberOfDays + 7);
          this.addTimeToRemove(st, fi);
        }
      }
    } else if (payrollRule.stMinute !== null && payrollRule.fiMinute !== null) {
      let dt = addDays(timeRange.st, -1);
      let numberOfDays = timeRange.numberOfDays + 2;
      if (payrollRule.stMinute > 0) {
        const fi = new Date(dt);
        fi.setMinutes(payrollRule.stMinute);
        this.addTimeToRemove(dt, fi);
      }
      let stMinute = payrollRule.stMinute;
      let fiMinute = payrollRule.fiMinute;
      if (fiMinute <= stMinute) {
        dt = timeRange.st;
        numberOfDays -= 1;
      } else {
        stMinute += minutesPerDay;
      }
      for (let i = 0; i < numberOfDays; i++) {
        const st = new Date(dt);
        st.setMinutes(fiMinute);
        const fi = new Date(dt);
        fi.setMinutes(stMinute);
        this.addTimeToRemove(st, fi);
        dt = addDays(dt, 1);
      }
    }
  }

  /**
   * @private
   * @param {Date} st
   * @param {Date} fi
   */
  addTimeToRemove(st, fi) {
    if (st < fi) {
      this.timeToRemove.push({ st, fi });
    }
  }

  /**
   * @param {import("@/model/dn-employee-schedule.js").EmployeeSchedule} employeeSchedule
   */
  getAmount(employeeSchedule) {
    const amount = employeeSchedule.getPayrollRuleHours(this);
    if (this.weeklyLimitAbove === true) {
      return Math.max(0, amount - this.weeklyLimit);
    } else if (this.weeklyLimitAbove === false) {
      return Math.min(amount, this.weeklyLimit);
    }
    return amount;
  }
}

class PayrollRuleCalcGroup {
  /**
   * @param {PayrollRuleDto} payrollRule
   */
  constructor(payrollRule) {
    /** @readonly @type {string} */
    this.code = payrollRule.code;
    /** @type {string} */
    this.name = payrollRule.name;
    /** @readonly @type {number} */
    this.kind = payrollRule.kind;
    /** @readonly @type {string} */
    this.unit = payrollRule.kind === PAYROLL_RULE_KIND.dayCount ? 'days' : 'hours';
    /** @readonly @type {PayrollRuleCalc[]} */
    this.payrollRules = [];
  }

  calcName() {
    if (this.payrollRules.length <= 1) { return; }
    let name = this.name;
    for (const r of this.payrollRules) {
      if (name.length === 0 || r.name.startsWith(name)) { continue; }
      const length = Math.min(name.length, r.name.length)
      let count = 0;
      let newlength = 0;
      for (let i = 0; i < length; i++) {
        if (name[i] !== r.name[i]) { break; }
        if (name[i] === ' ') { newlength = count; }
        count += 1;
      }
      if (newlength !== length) {
        name = name.substring(0, newlength);
      }
    }

    if (name.length > 0) {
      this.name = name;
    } else {
      this.name = this.code;
    }
  }
}

/**
 * Get start time of holidays near the timerange
 * @param {{ st: Date; numberOfDays: number; }} timeRange
 * @param {{ date: Date; }[]} holidays
 * @param {boolean} weekendAsHoliday
 */
function getSortedHolidays(timeRange, holidays, weekendAsHoliday) {
  const st = addDays(timeRange.st, -1);
  const fi = addDays(timeRange.st, timeRange.numberOfDays + 1);
  /** @type {Date[]} */
  const sortedHolidays = [];
  for (const holiday of holidays) {
    const dt = holiday.date;
    if (dt >= st && dt < fi) {
      sortedHolidays.push(dt);
    }
  }
  if (weekendAsHoliday) {
    /** @type {Set<number>} */
    const uniqueDays = new Set();
    for (const h of sortedHolidays) {
      uniqueDays.add(h.getTime());
    }

    let dt = st;
    while (dt < fi) {
      const day = dt.getDay();
      if ((day === 0 || day === 6) && !uniqueDays.has(dt.getTime())) {
        sortedHolidays.push(dt);
      }
      dt = addDays(dt, 1);
    }
  }

  sortedHolidays.sort();
  return sortedHolidays;
}

/**
* @param {number} minutes
*/
function minutesToDate(minutes) {
  if (!minutes) { minutes = 0; }
  return new Date(2030, 1, 1, 0, minutes);
}

/**
 * @param {Date} time
 */
function dateToMinutes(time) {
  return time.getHours() * 60 + time.getMinutes();
}

/**
 * @param {number} weekStartDay
 * @param {TimeRange} timeRange
 */
function splitTimeRangeInWeeks(weekStartDay, timeRange) {
  /** @type {TimeRange[]} */
  const weeks = [];
  let st = timeRange.st;
  let day = timeRange.st.getDay();
  let numberOfDays = 0;
  for (let i = 0; i < timeRange.numberOfDays; i++) {
    day += 1;
    if (day > 6) { day = 0; }
    numberOfDays += 1;
    if (day === weekStartDay) {
      weeks.push({st, numberOfDays});
      st = addDays(timeRange.st, i + 1);
      numberOfDays = 0;
    }
  }
  if (numberOfDays > 0) {
    weeks.push({st, numberOfDays});
  }

  return weeks;
}
import { getBreakSettingsMinWork, getBreakSettingsMaxWork } from '@/model/dn-break-settings.js';
import { TASK_KIND } from '@/model/dn-tasktype.js';

/**
* @typedef {{maxDurQ:number; maxWorkMinutes:number; minStMinute:number; maxFiMinute:number; minStQ:number; maxFiQ:number; unavailabilites:{stQ:number;fiQ:number}[]
* }} DayBounds
*/

/**
 * @typedef {{id?:number; automaticBreaks?:boolean; openForBO?: boolean;
 * fixTasks: null|{st:number, dur:number, taskTypeId:number}[];
 * flexTasks: null|{taskTypeId:number, st:number|null, stSpan:number|null, fi:number|null, fiSpan:number|null, dur:number|null, durSpan:number|null, step:number|null, kind:number}[]
 * }} ShiftDto Flex shift unit is quarter hours fix shift in minutes.
*/

export class Shift {
  /**
   * All units in quarter hours
   * @param {ShiftDto} data
   */
  constructor(data) {
    /** @type {number|null} */
    this.id = data.id;
    /** @private @type {boolean|null} */
    this._automaticBreaks = data.automaticBreaks !== undefined ? data.automaticBreaks : null;
    /** @private @type {boolean|null} */
    this._openForBO = data.openForBO !== undefined ? data.openForBO : null;
    /** @private @type {{st:number, dur:number, taskTypeId:number}[]} */
    this._fixTasks = [];
    if (data.fixTasks) {
      for (const t of data.fixTasks) {
        this._fixTasks.push({ st: t.st, dur: t.dur, taskTypeId: t.taskTypeId });
      }
    }
    /** @private @type {number} */
    this._stMin = 0;
    /** @private @type {number} */
    this._stMax = 0;
    /** @private @type {number} */
    this._fiMin = 0;
    /** @private @type {number} */
    this._fiMax = 0;
    /** @private @type {number} */
    this._durMin = 0;
    /** @private @type {number} */
    this._durMax = 0;
    /** @private @type {number} */
    this._step = 1;
    /** @type {number|null} */
    this.taskTypeId = null;
    /** @type {number|null} */
    this.taskType2Id = null;
    /** @type {number} */
    this.dur2Min = 0;
    /** @type {number} */
    this.dur2Max = 0;

    if (data.flexTasks) {
      for (const flexTask of data.flexTasks) {
        if (flexTask.kind === 0) {
          this.taskTypeId = flexTask.taskTypeId;
          if (flexTask.st !== null) { this._stMin = flexTask.st; }
          if (flexTask.stSpan !== null) { this._stMax = this.stMin + flexTask.stSpan; }
          if (flexTask.fi !== null) { this._fiMin = flexTask.fi; }
          if (flexTask.fiSpan !== null) { this._fiMax = this.fiMin + flexTask.fiSpan; }
          if (flexTask.dur !== null) { this._durMin = flexTask.dur; }
          if (flexTask.durSpan !== null) { this._durMax = this.durMin + flexTask.durSpan; }
          if (flexTask.step !== null) { this._step = flexTask.step; }
        } else {
          this.taskType2Id = flexTask.taskTypeId;
          if (flexTask.dur !== null) { this.dur2Min = flexTask.dur; }
          if (flexTask.durSpan !== null) { this.dur2Max = this.dur2Min + flexTask.durSpan; }
        }
      }
    }

    /** @type {boolean} */
    this.hasTask2 = this.taskType2Id > 0
    /** @type {boolean} */
    this.flexSt = this.stMin !== this.stMax
    /** @type {boolean} */
    this.flexFi = this.fiMin !== this.fiMax
    /** @type {boolean} */
    this.flexDur = this.durMin !== this.durMax
    /** @type {boolean} */
    this.isFix = this.fixTasks.length !== 0;
  }

  get automaticBreaks() {
    if (!this.isFix) { return true; }
    return this._automaticBreaks === true;
  }

  set automaticBreaks(value) {
    this.setValue('_automaticBreaks', value);
  }

  get openForBO() {
    if (this.isFix) { return false; }
    return this._openForBO === true;
  }

  set openForBO(value) {
    this.setValue('_openForBO', value);
  }

  get fixTasks() {
    return this._fixTasks;
  }

  set fixTasks(value) {
    let hasChanged = value.length !== this._fixTasks.length;
    if (!hasChanged) {
      for (let i = 0; i < value.length; i++) {
        const t = value[i];
        const t2 = this._fixTasks[i];
        if (t.st !== t2.st || t.dur !== t2.dur || t.taskTypeId !== t2.taskTypeId) {
          hasChanged = true;
          break;
        }
      }
    }
    if (hasChanged) {
      this.setValue('_fixTasks', value);
      this._fixShift = undefined;
    }
  }

  get stMin() {
    return this._stMin;
  }
  set stMin(value) {
    this.setValue('_stMin', value);
  }

  get stMax() {
    return this._stMax;
  }
  set stMax(value) {
    this.setValue('_stMax', value);
  }

  get fiMin() {
    return this._fiMin;
  }
  set fiMin(value) {
    this.setValue('_fiMin', value);
  }

  get fiMax() {
    return this._fiMax;
  }
  set fiMax(value) {
    this.setValue('_fiMax', value);
  }

  get durMin() {
    return this._durMin;
  }
  set durMin(value) {
    this.setValue('_durMin', value);
  }

  get durMax() {
    return this._durMax;
  }
  set durMax(value) {
    this.setValue('_durMax', value);
  }

  get step() {
    return this._step;
  }
  set step(value) {
    this.setValue('_step', value);
  }

  /**
   * @protected
   * @param {string} prop
   * @param {any} value
   */
  setValue(prop, value) {
    if (value !== this[prop]) {
      /** @type {boolean} */
      this.hasChanges = true;
      this[prop] = value;
    }
  }

  /**
   * @param {Map<number, import("@/model/dn-tasktype.js").TaskType>} taskTypeMap
   */
  getFixShift(taskTypeMap) {
    if (!this._fixShift) {
      let paidWorkMinutes = 0;
      const breaks = [];
      const tasks = [];
      /** @type {{st:number;fi:number}[]} */
      const shiftBoundMinutes = [];
      let stMinute = 1440;
      let fiMinute = 0;
      let durMinutes = 0;
      for (const t of this.fixTasks) {
        const taskFiMinute = t.st + t.dur;
        durMinutes += t.dur;
        if (t.st < stMinute) { stMinute = t.st; }
        if (taskFiMinute > fiMinute) { fiMinute = taskFiMinute; }
        const tt = taskTypeMap.get(t.taskTypeId);
        const task = {
          stMinute: t.st,
          durMinute: t.dur,
          st: Math.round(t.st / 15),
          fi: Math.round(taskFiMinute / 15),
          tasktypeId: t.taskTypeId,
          workFactor: tt.work / 100,
        }

        if (tt.kind === TASK_KIND.break) {
          if (!tt.paidBreak) {
            paidWorkMinutes -= t.dur;
          }
          breaks.push(task);
        } else if (tt.kind === TASK_KIND.task) {
          paidWorkMinutes += t.dur;
          tasks.push(task);
          if (shiftBoundMinutes.length === 0 || shiftBoundMinutes[shiftBoundMinutes.length-1].fi !== t.st) {
            shiftBoundMinutes.push({st:t.st, fi:taskFiMinute});
          } else {
            shiftBoundMinutes[shiftBoundMinutes.length-1].fi = taskFiMinute;
          }
        }
      }

      const stMin = Math.round(stMinute / 15);
      const fiMax = Math.round(fiMinute / 15);

      /** @type {{st:number; fi:number; ttId:number; workFactor:number}[]} */
      const tasksWithoutBreaks = [];
      for (const t of tasks) {
        let nextSt = t.st;
        do {
          let st = nextSt;
          let fi = t.fi;
          for (const b of breaks) {
            if (b.fi > st && b.st < fi) {
              fi = b.st
              nextSt = b.fi;
            }
          }
          if (st < fi) {
            tasksWithoutBreaks.push({ st, fi, ttId: t.tasktypeId, workFactor: t.workFactor })
            if (nextSt < fi) {
              break;
            }
          }
        } while (nextSt < t.fi);
      }

      /** @private */
      this._fixShift = {
        paidWorkMinutes: paidWorkMinutes,
        tasks,
        breaks,
        stMinute,
        fiMinute,
        stMin,
        fiMax,
        durMinutes,
        shiftBoundMinutes,
        tasksWithoutBreaks,
      };
    }

    return this._fixShift;
  }

  /**
   * @param {number} value
   */
  isAllowedEnd(value) {
    if (value + 1 < this.fiMin)
      return false;

    if (this.step === 1)
      return true;

    return (1 + value - this.stMin) % this.step === 0;
  }

  get isEmpty() {
    if (this.isFix) {
      return this.fixTasks.length === 0;
    }
    return this.fiMax <= this.stMin;
  }

  /**
   * @param {DayBounds} dayBounds
   * @param {Map<number, import("@/model/dn-tasktype.js").TaskType>} taskTypeMap
   */
  isDayBoundsOk(dayBounds, taskTypeMap) {
    if (this.isFix) {
      const fixShift = this.getFixShift(taskTypeMap);
      if (this.automaticBreaks) {
        if (fixShift.durMinutes > dayBounds.maxDurQ * 15) { return false; }
      } else {
        if (fixShift.paidWorkMinutes > dayBounds.maxWorkMinutes) { return false; }
      }
      
      if (fixShift.stMinute < dayBounds.minStMinute) { return false; }
      if (fixShift.fiMinute > dayBounds.maxFiMinute) { return false; }
      for (const unavailability of dayBounds.unavailabilites) {
        for (const t of fixShift.tasks) {
          if (t.st < unavailability.fiQ && t.fi > unavailability.stQ) {
            return false;
          }
        }
      }

      return true;
    }

    if (this.durMin > dayBounds.maxDurQ) { return false; }
    if (dayBounds.minStQ > this.stMax) { return false; }
    if (dayBounds.maxFiQ < this.fiMin) { return false; }
    if (this.stMax < this.fiMin) {
      for (const unavailability of dayBounds.unavailabilites) {
        if (this.stMax < unavailability.fiQ && this.fiMin > unavailability.stQ) {
          return false;
        }
      }
    }

    return true;
  }

  /**
   * @param {{ stMin: number; fiMax: number; }} bounds
   * @param {Map<number, import("@/model/dn-tasktype.js").TaskType>} taskTypeMap
   */
  setMaxBounds(bounds, taskTypeMap) {
    let shift;
    if (this.isFix) {
      shift = this.getFixShift(taskTypeMap);
    } else {
      shift = this;
    }
    if (bounds.stMin > shift.stMin)
      bounds.stMin = shift.stMin;
    if (bounds.fiMax < shift.fiMax)
      bounds.fiMax = shift.fiMax;
  }

  /** @param {Shift} other */
  isEqual(other) {
    if (other.isFix !== this.isFix)
      return false;
    if (other.automaticBreaks !== this.automaticBreaks) {
      return false;
    }
    if (other.openForBO !== this.openForBO) {
      return false;
    }
    if (this.isFix) {
      if (this.fixTasks.length !== other.fixTasks.length)
        return false;
      for (let i = 0; i < this.fixTasks.length; i++) {
        const t = this.fixTasks[i];
        const t2 = other.fixTasks[i];
        if (t.st != t2.st || t.dur != t2.dur || t.taskTypeId !== t2.taskTypeId)
          return false;
      }
      return true;
    }

    if (this.hasTask2 != other.hasTask2) {
      return false;
    }

    if (this.hasTask2) {
      if (this.taskType2Id !== other.taskType2Id || this.dur2Min !== other.dur2Min || this.dur2Max !== other.dur2Max) {
        return false;
      }
    }

    return this.taskTypeId === other.taskTypeId && this.stMin === other.stMin && this.stMax === other.stMax &&
      this.fiMin === other.fiMin && this.fiMax === other.fiMax &&
      this.durMin === other.durMin && this.durMax === other.durMax && this.step === other.step;
  }

  isValid() {
    if (this.isFix)
      return true;

    return this.step >= 1
      && this.stMin >= 0 && this.stMin <= this.stMax && this.stMax < 96
      && this.fiMin >= 0 && this.fiMin <= this.fiMax
      && this.durMin <= this.durMax;
  }

  /** @returns {ShiftDto} */
  toDto() {
    const fixTasks = this.isFix ? this.fixTasks : [];
    /** @type {{taskTypeId:number, st:number|null, stSpan:number|null, fi:number|null, fiSpan:number|null, dur:number|null, durSpan:number|null, step:number|null, kind:number}[]} */
    const flexTasks = [];
    if (fixTasks.length === 0) {
      flexTasks.push({ taskTypeId: this.taskTypeId, st: this.stMin, fi: this.fiMin, dur: this.durMin, stSpan: this.stMax - this.stMin, fiSpan: this.fiMax - this.fiMin, durSpan: this.durMax - this.durMin, step: this.step, kind: 0 });
      if (this.hasTask2) {
        flexTasks.push({ taskTypeId: this.taskType2Id, st: null, fi: null, dur: this.dur2Min, stSpan: null, fiSpan: null, durSpan: this.dur2Max - this.dur2Min, step: null, kind: 1 });
      }
    }
    return {
      id: this.id,
      automaticBreaks: this._automaticBreaks,
      openForBO: this._openForBO,
      fixTasks: fixTasks,
      flexTasks: flexTasks,
    };
  }

  /**
   * @param {Map<number, import("@/model/dn-tasktype.js").TaskType>} taskTypeMap
   */
  getDurationText(taskTypeMap) {
    let hours = 0;
    if (this.isFix) {
      for (const t of this.fixTasks) {
        if (taskTypeMap.get(t.taskTypeId).type !== 'break') {
          hours += t.dur / 60;
        }
      }

    } else if (this.durMin === this.durMax) {
      hours = this.durMin / 4;
    } else {
      return this.durMin / 4 + ' - ' + this.durMax / 4 + ' h'
    }

    if (hours > 0) {
      return Math.round(100 * hours) / 100 + ' h';
    }
    return '';
  }
}

/**
 * @param {number} ttId
 */
export function createShift(ttId) {
  const shift = new Shift({ id: null, fixTasks: [], flexTasks: [] });
  shift.isFix = true;
  shift.taskTypeId = ttId;
  return shift;
}


/**
 * @param {Shift} sh
 * @param {Map<number, import("@/model/dn-tasktype.js").TaskType>} taskTypeMap
 * @param {import("@/model/dn-break-settings.js").BreakSettings[]} breakSettings
 */
export function getShiftWorkTime(sh, taskTypeMap, breakSettings) {
  let minWork = 0
  let maxWork = 0
  let includeFlexShift = false
  if (sh) {
    if (sh.isFix) {
      let fixShift = sh.getFixShift(taskTypeMap)
      if (fixShift) {
        minWork += fixShift.paidWorkMinutes
        maxWork += fixShift.paidWorkMinutes
      }
    } else {
      includeFlexShift = true
      let durMin = sh.durMin * 15;
      let durMax = sh.durMax * 15;
      if (breakSettings && taskTypeMap) {
        durMin = getBreakSettingsMinWork(breakSettings, durMin);
        durMax = getBreakSettingsMaxWork(breakSettings, durMax);
      }
      minWork += durMin;
      maxWork += durMax;
    }
  }

  return { minWork, maxWork, includeFlexShift };
}
<template>

        <div v-if="userGuide.display" class="tile is-vertical guideLineTile" id="main-tile">
            <article class="tile is-child guide-area">
                <div class="content">
                    <section>
                        <span class="header">User guide</span>
                        <IconField iconPosition="left"  class="search-bar">
                            <InputIcon class="pi pi-search"></InputIcon>
                            <InputText v-model="searchText" placeholder="Search" style="width:100%" />
                            <InputIcon class="pi pi-times clear-search" @click="searchText=''"></InputIcon>
                        </IconField>
                        <PvButton class="button" icon="pi pi-times" severity="danger" @click="userGuide.display=false"/>
                    </section>

                    <section>
                        <ScrollPanel style="height:calc(100vh - 12.5rem)   ">
                            <!--NO SEARCH TEXT-->
                            <div v-if="searchText == ''">
                                <Accordion
                                v-model:activeIndex="activeIndex"
                                expandIcon="null"
                                collapseIcon="null"
                                @tab-click="setTab"

                                >
                                <AccordionTab
                                    v-for="(item, index) of filteredGuideItems"
                                    :pt="{
                                    root:{
                                        style: {
                                            marginBottom: '1px',
                                            fontFamily: 'proxima-nova, sans-serif'
                                        }
                                    },
                                    header: ({ props, parent }) => ({
                                        class: hideAccordionTab(props, parent, index)
                                    }),
                                    toggleableContent: ({ props, parent }) => ({
                                        class: hideAccordionTab(props, parent, index)
                                    })
                                }">
                                    <template #header>
                                        <div class="header-item">
                                            <span class="card-header-title">{{ item.title }}</span>
                                            <div class="header-right">
                                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                                                    <path v-if="activeIndex===index" d="m212-372 268-267 268 267H212Z"/>
                                                    <path v-else d="M480-322 212-589h536L480-322Z"/>
                                                </svg>
                                                <!--                                        <i class="pi" :class="activeIndex===index ? 'pi-caret-down' : 'pi-caret-up'"/>-->
                                                
                                            </div>
                                        </div>
                                    </template>
                                    
                                        <Accordion
                                        v-model:activeSubIndex="activeSubIndex"
                                        expandIcon="null"
                                        collapseIcon="null"
                                        @tab-click="setTab"

                                        >
                                        <AccordionTab
                                        v-for="(subitem, subindex) of item.subchapters"
                                        :pt="{
                                        root:{
                                            style: {
                                                marginBottom: '1px',
                                                fontFamily: 'proxima-nova, sans-serif'
                                            }
                                        },
                                        header: ({ props, parent }) => ({
                                            class: hideAccordionTab(props, parent, subindex)
                                        }),
                                        toggleableContent: ({ props, parent }) => ({
                                            class: hideAccordionTab(props, parent, subindex),

                                        })
                                        }">
                                        <template #header>
                                            <div class="subheader-item">
                                                <span class="card-subheader-title">{{ subitem.title }}</span>
                                                <div class="header-right">
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">

                                                    </svg>
                                                    <i class="pi pi-external-link" @click="openDialog(subindex, subitem)"/>
                                                </div>
                                            </div>
                                        </template>
                                        <template #default>
                                            <div class="card-content">
                                                <div class="content">
                                                    <div class="intro-text">
                                                        <span>{{ subitem.intro }}</span>
                                                    </div>
                                                    <div v-for="(step, subindex) of subitem.steps" v-bind:key="subindex">
                                                        <span class="step-number">{{ subindex + 1 }}:</span> <span>{{
                                                            step.text
                                                        }}</span>
                                                        <span v-if="step.ref.length" class="step-ref" v-tooltip.left="{ value: step.ref}">i</span>
                                                        <span v-if="step.refMenu" class="menu-ref">{{ step.refMenu }}</span>
                                                        <span v-if="step.link">
                                                            <InlineSvg :src="videoSrc" :width="32" :height="32" class="inline-svg"
                                                            @click="followLink(step.link)"></InlineSvg>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        </AccordionTab>
                                        </Accordion>
                                </AccordionTab>
                                </Accordion>
                            </div>
                            <!--SEARCHING-->
                            <div v-else>
                                        <Accordion 
                                        v-model:activeSubIndex="activeSubIndex"
                                        expandIcon="null"
                                        collapseIcon="null"
                                        @tab-click="setTab"
                                        >
                                        <AccordionTab
                                        v-for="(subitem, subindex) of filteredGuideItems"
                                        :pt="{
                                        root:{
                                            style: {
                                                marginBottom: '1px',
                                                fontFamily: 'proxima-nova, sans-serif'
                                            }
                                        },
                                        header: ({ props, parent }) => ({
                                            class: hideAccordionTab(props, parent, subindex)
                                        }),
                                        toggleableContent: ({ props, parent }) => ({
                                            class: hideAccordionTab(props, parent, subindex)
                                        })
                                        }">
                                        <template #header>
                                            <div class="subheader-item">
                                                <span class="card-subheader-title">{{ subitem.title }}</span>
                                                <div class="header-right">
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                                                        <path v-if="activeSubIndex===subindex" d="m212-372 268-267 268 267H212Z"/>
                                                        <path v-else d="M480-322 212-589h536L480-322Z"/>
                                                    </svg>
                                                    <i class="pi pi-external-link" @click="openDialog(subindex, subitem)"/>
                                                </div>
                                            </div>
                                        </template>
                                        <template #default>
                                            <div class="card-content">
                                                <div class="content">
                                                    <div>
                                                        <span class="intro-text">{{ subitem.intro }}</span>
                                                    </div>
                                                    <div v-for="(step, subindex) of subitem.steps" v-bind:key="subindex">
                                                        <span class="step-number">{{ subindex + 1 }}:</span> <span>{{
                                                            step.text
                                                        }}</span>
                                                        <span v-if="step.ref.length" class="step-ref" v-tooltip.left="{ value: step.ref}">i</span>
                                                        <span v-if="step.refMenu" class="menu-ref">{{ step.refMenu }}</span>
                                                        <span v-if="step.link">
                                                            <InlineSvg :src="videoSrc" :width="32" :height="32" class="inline-svg"
                                                            @click="followLink(step.link)"></InlineSvg>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        </AccordionTab>
                                        </Accordion>
                            </div>
                        </ScrollPanel>
                    </section>
                </div>
            </article>
        </div>
    <PvDialog v-model:visible="dialogVisible"
            :header="popupData.title"
            :style="{ width: '25%'}"
            :pt="{
                header: {
                    style: {
                        cursor: 'pointer'
                    }
                }
            }">
                            
        <div class="card-content">
            
            <div class="content">
                <div>
                    <span class="intro-text">{{ popupData.intro }}</span>
                </div>
                <div v-for="(step, index) of popupData.steps" v-bind:key="index">
                    <span class="step-number">{{ index + 1 }}:</span> <span>{{ step.text }}</span>
                    <span v-if="step.ref.length" class="step-ref" v-tooltip.left="{ value: step.ref,
                                               pt: {
                                                  arrow: {
                                                      style: {
                                                          borderLeftColor: 'var(--primary-color)'
                                                      }
                                                  },
                                                  text: {
                                                    style: {
                                                          backgroundColor: 'var(--primary-color)!important',
                                                          color: 'var(--primary-color-text)!important',
                                                          fontSize: '0.85rem',
                                                          
                                                          borderRadius: '6px',
                                                          fontWeight: '400',
                                                          // whiteSpace: 'nowrap'
                                                      }
                                                  }
                                              }
                                              }">i</span>
                    <span v-if="step.refMenu" class="menu-ref">{{ step.refMenu }}</span>
                    <span v-if="step.link">
                                <InlineSvg :src="videoSrc" :width="32" :height="32" class="inline-svg"
                                           @click="followLink(step.link)"></InlineSvg>
                            </span>
                </div>
            </div>
        </div>
    </PvDialog>
</template>
<script>
import {useDataStore} from "@/stores/dataStore.js";
import dnUserguides from "@/jso/dn-new-user-guide.json";
//import dnUserguides from "@/jso/dn-user-guides.json";
import InlineSvg from "vue-inline-svg";

export default {
    // @ts-ignore
    data() {
        return {
            searchText: '',
            videoSrc: require("@/assets/images/icons/youtube.svg"),
            activeIndex: null,
            activeSubIndex: null,
            activePopupTab: null,
            dialogVisible: false,
            currentTab: null,
            currentSubTab: null,
            popupData: {},
        }
    },
    components: {InlineSvg},
    computed: {
        filteredGuideItems() {
            if (this.searchText == '') {
                return dnUserguides
            }
            let foundSubchaps = []
            //console.log("length: " + dnUserguides.length)
            
            dnUserguides.forEach(chapter => {
                let foundSubChapter = chapter.subchapters.filter(this.guideFilter)
                foundSubChapter.forEach(subchap => {
                    foundSubchaps.push(subchap)
                });

            });
            return foundSubchaps
    //return dnUserguides.filter(this.guideFilter)
        },
        userGuide() {
            return useDataStore().userGuide;
        },
    },
    methods: {
        //får in subchapters istället som item
        guideFilter(item) {
            let searchOK = this.searchText.length <= 2

            if (!searchOK) {
                if (item.title.toLowerCase().includes(this.searchText.toLowerCase()) || 
                    item.intro.toLowerCase().includes(this.searchText.toLowerCase())) {
                    searchOK = true
                }
                item.steps.forEach(step => {
                    if (step.text.toLowerCase().includes(this.searchText.toLowerCase())) {
                        searchOK = true
                    }
                });

            }
            return searchOK
        },
        followLink(link) {
            window.open(link, '_blank');
        },
        openDialog(index, popupData) {
            this.activePopupTab = index;
            this.dialogVisible = true;
            this.popupData = popupData;
        },
        setTab(data) {
            this.currentTab = data.index;
        },
        setSubTab(data) {
            this.currentSubTab = data.subindex;
        },
        hideAccordionTab(props, parent, index) {
            if (this.dialogVisible && this.activePopupTab === index) {
                if (this.currentTab === this.activePopupTab) {
                    this.activeIndex = null;
                    parent.state.d_activeIndex = null;
                }

            }

        }

    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/variables.module.scss";


.tooltip-guide {
    width: auto;
    border-radius: 6px;
    font-size: 0.85rem;
    font-weight: 300;
    -webkit-box-shadow: 0px 1px 2px 1px rgba(0, 1, 0, 0.2);
    box-shadow: 0px 1px 2px 1px rgba(0, 1, 0, 0.2);
    z-index: 38;
    white-space: nowrap;
    position: absolute;
}


.guideLineTile{
    margin-left: 2px;
    margin-right: 12px;
    border-top-left-radius: 8px;
    border-top: 2px solid $light;
    border-left: 5px solid $light;
    max-height: calc(100vh - 5.5rem);
}

.guide-area {
    border-radius: 5px;
    background-color: $white !important;
    position: relative;
    height: 100%;
    padding: 4px;
}

:deep(.p-accordion .p-accordion-header .p-accordion-header-link) {
    background-color: $white;
    display: flex;
    padding: 0;
    border-radius: 0;
    border-width: 0px;
    border-bottom-width:1px;
    border-bottom-color: $primaryXlight;
    text-decoration: none;
    transition: background-color 0.375s;
}
:deep(.p-accordion:hover .p-accordion-header:hover .p-accordion-header-link:hover) {
    background-color: $primaryXlight-light;
    transition: background-color 0.375s;
}
:deep(.p-accordion .hide-tab) {
    display: none;
}

.card-header-title {
    padding: 12px 0px 4px 0px;
    font-size: 1.375rem;
    font-weight: 400;
}

.card-subheader-title {
    padding: 8px 0px 4px 6px;
    color: $dark;
    font-size: 1.125rem;
    font-weight: 400;
}

.card-content {
    border-left: 2px solid $primaryXlight;
    border-bottom: 2px solid $primaryXlight;
    border-top-left-radius: 0px !important; 
    padding: 6px 6px 10px 6px;
}

.button {
    background-color: #fff;
    color: #e73542;
    border-radius: 2px;
    position: absolute;
    margin: 4px;
    right: 2px;
    top: 4px;
    height: 2rem;
    width: 2rem;
    cursor: pointer;

}

.button:hover {
    background-color: #f2f2f2;
}

.button > :deep(.pi) {
    font-size: 0.75em;
}

.clear-search {
    display: block;
    position: absolute;
    right: 10px;
    top: calc(50% - 10px);
    padding: 10px;
    background: $white;
    color: rgba(54, 54, 54, 0.3);
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.75s;
}
.clear-search:hover {
    background: rgba(54, 54, 54, 0.3);
    color: #fff;
}
.content-search {
    font-size: 1rem;
    height: 2rem;
    width: 100%;
}
.search-bar {
    margin-bottom: 0.5rem;
    border-radius: 15px;
    width: 100%;
    display: flex;
}
.p-inputtext {
    border: 2px solid $light;
    border-radius: 10px;
    transition: border 0.75s;
}
.p-inputtext:hover {
    border: 2px solid $primary;
}
.p-inputtext:focus {
    border: 2px solid $primary;
}
.p-inputtext:active {
    border: 2px solid $primary;
}
.content-search:focus {
    outline: none;
    border-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
}
.content-search:hover {
    border-color: hsl(0, 0%, 71%);
}
.content-search::placeholder, .pi-search {
    color: rgba(54, 54, 54, 0.3);
}

.header-item, .subheader-item{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    
}

.header-right {
    display: flex;
    align-items: center;
    padding-right: 12px;
}
.header-right .pi-external-link{
    margin-left: 12px;
}
.header-right .pi-external-link:hover {
    color: rgba(54, 54, 54, 0.5);
}
.content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header {
    display: inline-block;
    color: $mid-grey;
    padding: 8px 16px;
    font-size: 1.75rem;
}

.closebutton {
    background-color: #fff;
    color: red;
    height: 30px;
    text-align: center;
    line-height: 50%;
}

.step-ref {
    color: $light;
    
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    margin: 0px 3px !important;
}

.menu-ref {
    background-color: $primary;
    color: white;
    font-size: 0.75rem;
    font-style: italic;
    border-radius: 4px;
    padding: 1px;
    margin-top: -40px;
}

.step-number {
    font-weight: 600;
    padding-left: 4px;
}
.intro-text {
    padding-bottom:8px;
}

.inline-svg {
    cursor: pointer;
    margin-left: 8px;
    margin-top: -4px;
    margin-bottom: -8px;
}

.floating-guide {
    position: fixed;
    width: calc(calc(100% / 6) - 8px);
    z-index: 1111;
    border: solid #555 1px;
    border-radius: 5px;
    padding: 0 10px;
    background-color: #fff
}

.p-dialog-content > .card-content{
    border-left: none;
}



</style>
import { getBreakSettingsByTag } from "@/model/dn-break-settings.js";
import { postEmployeeSkill } from "@/model/dn-employee.js";
import { simulationData } from "@/stores/dn-simulation-data.js";
import { INFOTYPE } from "@/js/dn-schedule-helper.js";
import { getColorFromName } from "@/js/dn-variables";
import { useDataStore } from '@/stores/dataStore.js';
import { useScheduleStore } from "@/stores/scheduleStore.js";
import variables from "@/assets/css/variables.module.scss";
import { i18n } from "@/i18n.js";
import { addDays } from "@/js/dn-helper";
import {getShiftWorkTime} from '@/model/dn-shift.js';


/** @returns {import("@/model/dn-skill.js").Skill[]} */
export function getSortedSkills(){
  const dataStore = useDataStore();
  return dataStore.skillsCurrentCC.sort((a,b) => (a.numericSort > b.numericSort) ? 1 : ((b.numericSort > a.numericSort) ? -1 : 0))
}

/** @typedef {{id:number; empid:string; name:string; ghost:boolean; skills:boolean[]}} SkillMatrixRow */

export function getSkillsMatrix(){
    const scheduleStore = useScheduleStore();
    const employees=scheduleStore.employees.list;
    const skills = getSortedSkills()
    /** @type {SkillMatrixRow[]} */
    const skillsMatrix=employees.map(function (x) { return {id:x.id, empid: x.empid, name: x.name, ghost:x.ghost, skills: new Array(skills.length).fill(false)}});

    //load matrix
    for(let e=0;e<skillsMatrix.length;e++){
        let emp = employees[e]
        

        for(let s=0;s<skills.length;s++){
            if(emp.skills.includes(skills[s].id)){
                skillsMatrix[e].skills[s]=true //1
            }
        }
    }
    return skillsMatrix
}

export function getSkillsMatrixColumns(sMinMax){
    const scheduleStore = useScheduleStore();
    let setting=scheduleStore.scheduleOptions.scheduleFilter
    let aId=setting.affinity;
    let skills = getSortedSkills()
    let paginatedSkillIds = []
    let  filteredskills = skills.filter((s => s.affinity ==aId||aId==null));
    for(let s=0;s<filteredskills.length;s++){
    if(s>=sMinMax[0]&&s<=sMinMax[1]){
        paginatedSkillIds.push(filteredskills[s].id)
    }
    }

    let columnDefs =
    [{"name":"empid","label":"empid","sticky":true,"width":60},
     {"name":"name","label":"name","sticky":true,"width":300},
     {"name":"copy/paste","label":"copy","sticky":false,"width":10},
]

    for(let s=0;s<skills.length;s++){
        if(paginatedSkillIds.includes(skills[s].id)){
            columnDefs.push({"name":skills[s].name,"label":skills[s].name.slice(0, 4),"field":'skills', "description":skills[s].description,"type":'skill',"index":s, "sticky":false,"width":'20px'})
        }  
    }
    columnDefs.push({"name":"empty","label":"","sticky":false,"width":800})

    return columnDefs
}

export function numberOfSkillsColumns(){
    const scheduleStore = useScheduleStore();
    let setting=scheduleStore.scheduleOptions.scheduleFilter
    let aId=setting.affinity;
    if(aId){
        return getSortedSkills().filter((s => s.affinity ==aId)).length;
    }else{
        return getSortedSkills().length;
    }
   
}

 export async function saveSkillsMatrix(skillsMatrix){
    const scheduleStore = useScheduleStore();
    const employees=scheduleStore.employees.list;
    /** @type {import("@/model/dn-employee.js").EmployeeSkillDto[]} */
    const postEmployeeSkillDto = [];

    /** @type {import("@/model/dn-employee.js").Employee[]} */
    const changedEmps = [];
    let skills = getSortedSkills()
    //load matrix
    for(let i=0;i<skillsMatrix.length;i++){
        for(let e=0;e<employees.length;e++){
            if(skillsMatrix[i].id==employees[e].id){
                let skillList=[]
                for(let s=0;s<skills.length;s++){
                    if(skillsMatrix[i].skills[s]==true){
                        skillList.push(skills[s].id)
                    }
                }
                skillList.sort()

                //check if changed
                let hasChanges = false
                let skillListOrg =employees[e].skills.sort()
                if(skillList.length==skillListOrg.length){
                    for(let s=0;s<skillListOrg.length;s++){
                        if(skillList[s]!==skillListOrg[s]){
                        hasChanges = true
                        break
                        }
                    }
                } else{
                    hasChanges = true
                }
              if(hasChanges){
                employees[e].skills=skillList
                const dto = employees[e].toEmployeeSkillDto();
                changedEmps.push(employees[e])
                if (dto) {
                    postEmployeeSkillDto.push(dto);
                }
            }
            }
        }
    }
    if(postEmployeeSkillDto.length > 0){
        await postEmployeeSkill(postEmployeeSkillDto);
        for (const emp of changedEmps) {
            emp.confirmChanges();
        }
        simulationData.reset();
    }
}

export function getEmpIdsSameTags(emp){
  const scheduleStore = useScheduleStore();
  const empids = [];
  let employees=scheduleStore.employees.list;
  for(let e=0;e<employees.length;e++){
    if(arraysAreEqual(employees[e].tagIds,emp.tagIds)){empids.push(employees[e].id)}
  }
  return empids
}

export function arraysAreEqual(a1,a2){
    a1.sort()
    a2.sort()
    let isEqual = true
    if(a1.length==a2.length){
        for(let i=0;i<a1.length;i++){
            if(a1[i]!=a2[i]){isEqual=false}
        }
    }else{isEqual=false}
    return isEqual
}

export function getAffinityInfoMap(){
    const dataStore = useDataStore();
    const affinities = dataStore.affinities;
    let hasAffinities=affinities != null && affinities.length > 0
    let affinityInfoMap = new Map
    if(hasAffinities){
        const dataStore = useDataStore();
        
        let taskTypes=dataStore.taskTypes.list
        for(let a=0;a<affinities.length;a++){
            for(let i=0;i<taskTypes.length;i++){
                if(taskTypes[i].affinity==affinities[a].id){
                    affinityInfoMap.set(affinities[a].id,{id:affinities[a].id,name:affinities[a].name,color:taskTypes[i].getColor(getColorFromName)})
                }
            }
        }
        return affinityInfoMap
    }
return null
}

export function getAffintyIndicators(emps, svgWidth){
    const svgns= "http://www.w3.org/2000/svg"
    const dataStore = useDataStore();
    const affinities = dataStore.affinities;
    let returnMap = new Map  //key: empid value: indicator
    let hasAffinities=affinities != null && affinities.length > 0
    //prepare affinity data
    
    if(hasAffinities){
        const dataStore = useDataStore();
        let affinityInfoMap = getAffinityInfoMap()
        /* for(let a=0;a<affinities.length;a++){
            for(let i=0;i<taskTypes.length;i++){
                if(taskTypes[i].affinity==affinities[a].id){
                    affinityInfoMap.set(affinities[a].id,{id:affinities[a].id,name:affinities[a].name,color:taskTypes[i].getColor(getColorFromName)})
                }
            }
        } */

        //prepare skill data
        let callGroups = dataStore.skillsCurrentCC;
        let skillsInfoMap = new Map
        for(let i=0;i<callGroups.length;i++){
            skillsInfoMap.set(callGroups[i].id,affinityInfoMap.get(callGroups[i].affinity))
        }
        
        
        //Create indicator plupps
        for(let e=0;e<emps.length;e++){
            let empAffinitiyInfos = new Set
            let emp =emps[e]
            for(let i=0;i<emp.skills.length;i++){
                empAffinitiyInfos.add(skillsInfoMap.get(emp.skills[i]))
            }
            let numberOfAffinties=empAffinitiyInfos.size
            let indicators =[]
            let counter =0
            
            for (const aInfo of empAffinitiyInfos) {
                if (!aInfo) { continue; }
                counter +=1
                const ellipse = document.createElementNS(svgns, "ellipse");
                let tagObject ={ emp: emp,aInfo:aInfo,type:INFOTYPE.skillsInfo }
                ellipse.tag = tagObject;
            
                ellipse.setAttributeNS(null, "rx", 6);
                ellipse.setAttributeNS(null, "ry", 6);
                ellipse.setAttributeNS(null, "cx", svgWidth-6-(counter-1)*14);
                ellipse.setAttributeNS(null, "cy", 7);
                ellipse.setAttributeNS(null, "opacity", 1);
                ellipse.setAttributeNS(null, "fill", aInfo.color);
                indicators.push(ellipse)
            }
            returnMap.set(emp.id,indicators)
        }
       
    }else{

        //Create indicator plupps
        for(let e=0;e<emps.length;e++){
            let emp =emps[e]
            let indicators =[]
                const ellipse = document.createElementNS(svgns, "ellipse");
                let tagObject ={ emp: emp,aInfo:null,type:INFOTYPE.skillsInfo }
                ellipse.tag = tagObject;
                ellipse.setAttributeNS(null, "rx", 6);
                ellipse.setAttributeNS(null, "ry", 6);
                ellipse.setAttributeNS(null, "cx", svgWidth-6);
                ellipse.setAttributeNS(null, "cy", 7);
                ellipse.setAttributeNS(null, "opacity", 1);
                ellipse.setAttributeNS(null, "fill", variables.grey);
                indicators.push(ellipse)


            returnMap.set(emp.id,indicators)
        }
    }
    
    return returnMap
}

export function getSkillsText(emp,affintyInfo){
    const dataStore = useDataStore();
    const callGroups = dataStore.callGroupsCurrentCC;
    let skillInfoText=''
    if(affintyInfo){
        skillInfoText=affintyInfo.name + ':  '
        for (const skill of callGroups) {
            if(emp.skills.includes(skill.id)&&skill.affinity==affintyInfo.id){
                skillInfoText+="<br>"+ skill.name;
            }
        }
    }else{
        skillInfoText= i18n.global.t('employees.skills')+':'
        for (const skill of callGroups) {
            if(emp.skills.includes(skill.id)){
                skillInfoText+="<br>"+ skill.name;
            }
        }
    }
    return skillInfoText
}
//Nghia Lang Codes
/**
 * @param {import("@/model/dn-employee.js").Employee} emp
 */
export function getEmployeeWarning(emp){
    const dataStore = useDataStore();
    /** @type {string[]} */
    const warnings=[];
    if (!dataStore.currentUser) { return warnings; }
    const scheduleStore = useScheduleStore();
    const rotations = scheduleStore.rotations
    const shiftBags= scheduleStore.shiftBags
    const availabilities = scheduleStore.availabilities
    const employeeDisplaySettings = scheduleStore.employeeDisplaySettings
    const taskTypeMap=dataStore.taskTypeMap
    
    //skill warning
    if(emp.skills.length==0&&employeeDisplaySettings.skillsWarning) {warnings.push(i18n.global.t('employees.no-skills'))}

    //shiftbags || rotations
    if(rotations&&shiftBags){
        if(!rotations.byEmp.has(emp.id)&&!shiftBags.byEmp.has(emp.id)&&employeeDisplaySettings.shiftWarning){warnings.push(i18n.global.t('employees.no-shifts'))}
    }

    //switch id
    if(dataStore.currentUser.hasAdherence&&employeeDisplaySettings.switchIdWarning){
        if(!emp.switchid&&!emp.ghost){
            {warnings.push(i18n.global.t('employees.no-switch-id'))}
        }
    }

    //work time  &&emp.empid==84311
    if(employeeDisplaySettings.workTimeWarning   ){

        if(!emp.workTimeOk()) {
            {warnings.push(i18n.global.t('employees.work-time-outside'))}
        }

        //prepare breaksettings
        const breakSettingsIsLoaded = dataStore.breakSettings !== null;
        const breakSettings = breakSettingsIsLoaded ? getBreakSettingsByTag(dataStore.breakSettings, emp, dataStore.currentCC.tagIds) : null;
        if(breakSettings&&taskTypeMap){
            for(const bs of breakSettings){
                bs.calculateUnpaidDuration(taskTypeMap)
            }
        }

        //Check cyclic shedules fit into work time
        let wwt =null//weekly work time
        let rotation= null
        if(rotations && breakSettingsIsLoaded && rotations.byEmp.has(emp.id)) {
            rotation =rotations.byEmp.get(emp.id)
            if(rotation){wwt=rotation.getWeeklyWorkTime(breakSettings,dataStore.taskTypeMap)}
        }
        let empMinMax = getMinMaxWeeklyWork(emp)
        let remainingWorkTime={minWork:empMinMax.min,maxWork:empMinMax.max}

        let remainingWorkTimeAfterCyclicExist=false
        if(wwt){
            remainingWorkTimeAfterCyclicExist=false
            //check cyclic shedule work time
            if(wwt.minWork>empMinMax.max||(!shiftBags.byEmp.has(emp.id)&&wwt.maxWork<empMinMax.min)){
                warnings.push("Cyclic schedule do not fit agent work time")
            }else if(wwt.maxWork<empMinMax.min){  
                remainingWorkTimeAfterCyclicExist=true
                remainingWorkTime.minWork = remainingWorkTime.minWork-wwt.maxWork
                remainingWorkTime.maxWork = remainingWorkTime.maxWork-wwt.minWork
            }
        }

        //Check for shift bag shifts to fill work time
        if(shiftBags && breakSettingsIsLoaded &&shiftBags.byEmp.has(emp.id)&&(!wwt||remainingWorkTimeAfterCyclicExist)){
            const sBag = shiftBags.byEmp.get(emp.id)
            let weeks =1
            if(rotation){weeks=rotation.weeks}

            //check all weekdays
            let shiftCombosOK = true
            //cyclic shedule may have diffferent shifts different days
            for(let w=0;w<weeks;w++){
                let possibleShifts =[]
                for(let d=0;d<7;d++){
                    //days with no cyclic
                    if(!rotation||(rotation&&!rotation.shifts[d+w*7])){
                        for(let s=0;s<sBag.shifts.length;s++){
                            const sh = sBag.shifts[s]
                            if(dayIsIncludedInShiftDaylist(d,sh.dayList)){
                                possibleShifts.push(getShiftWorkTime(sh,taskTypeMap,breakSettings))
                            }
                        }  
                    }
                }
                
                shiftCombosOK=empvaluatePossibleShiftCombosToMeetWorkTime(possibleShifts,remainingWorkTime)
                if(!shiftCombosOK) {break}
            }

            if(!shiftCombosOK){
                if(remainingWorkTimeAfterCyclicExist){warnings.push("Shifts & cyclic schedules do not fit work time")}
                else{warnings.push("Shifts do not fit work time")}
            } 
        }
        }

        //Check shifts and availability match &&emp.empid==84648 &&emp.name=='emp02'
        if(employeeDisplaySettings.shiftWarning){
           
            let rotation=null
            if(rotations && rotations.byEmp.has(emp.id)) {
                rotation =rotations.byEmp.get(emp.id)
            }
            let warningCountArray= new Array(7)
            for(let i=0;i<7;i++){
                warningCountArray[i]={shiftCount:0,errorShifts:new Array()}
            }

            if(rotations&&shiftBags){
            if(shiftBags.byEmp.has(emp.id)&&availabilities.byEmp.has(emp.id)){
                const sBag = shiftBags.byEmp.get(emp.id)
                const avail = availabilities.byEmp.get(emp.id)

                //check all weekdays (monday 0, sunday 6)
                for(let d=0;d<7;d++){
                    for(let s=0;s<sBag.shifts.length;s++){
                        const sh = sBag.shifts[s]
                        if(dayIsIncludedInShiftDaylist(d,sh.dayList)){
                            warningCountArray[d].shiftCount+=1
                            let stOK = false
                            let fiOK = false
                            let durOK = false
                            let shiftOKweek = null
                            for(let w=0;w<avail.weeks;w++){
                                const ava = getDayAvailability(d+w*7,avail)
                                if(d>=0){

                                    //check if fits into availability
                                    if(ava){
                                        if(!shiftOKweek){shiftOKweek=new Array(avail.weeks).fill(false)}
                                            if(sh.isFix){
                                                let fixShift= sh.getFixShift(taskTypeMap)
                                                durOK=true
                                                //number shift parts and availability parts are equal
                                                if(fixShift.tasks.length ==ava.length){
                                                    let doFit = true
                                                    for (let a=0;a<ava.length;a++) {
                                                        const shPart = fixShift.tasks[a]
                                                        const aSpan =ava[a]
                                                            //if(d==4){ console.log('d '+d +'st a '+aSpan.st+' stmin s '+shPart.st);}
                                                        if(shPart.st<aSpan.st){doFit=false}
                                                        if(shPart.fi>aSpan.fi){doFit=false}
                                                    }
                                                    shiftOKweek[w]=doFit
                                                        
                                                //check if shift fits any availability part
                                                }else if(fixShift.tasks.length==1){
                                                    const shPart = fixShift.tasks[0]
                                                    for (let a=0;a<ava.length;a++) {
                                                        const aSpan =ava[a]
                                                        if(shPart.st>=aSpan.st&&shPart.fi<=aSpan.fi){shiftOKweek[w]=true}
                                                    } 
                                                }
                                                //if (ava.length==0){shiftOKweek[w]=false} ???
                                            }else{
                                                for(let a=0;a<ava.length;a++){
                                                    const aSpan =ava[a]
                                                    if(sh.stMax>=aSpan.st&&sh.fiMin<=aSpan.fi&&sh.durMin<=aSpan.fi-aSpan.st){shiftOKweek[w]=true}
                                                }
                                            }
                                    }
                                }else{
                                    stOK = true
                                    fiOK = true
                                    durOK = true

                                }
                            }
                            if(shiftOKweek){
                                stOK = true
                                fiOK = true
                                durOK = true
                                let x = shiftOKweek.filter((s => s ==false)).length;
                                if(x==avail.weeks){stOK=false;fiOK=false}                     
                            }
                            if(!stOK||!fiOK||!durOK){
                                if(!warningCountArray[d].errorShifts.includes(s)){
                                warningCountArray[d].errorShifts.push(s)}
                            }
                        }
                    }
                }

            // check for days where no shift fits
            const weekDayFormat = new Intl.DateTimeFormat(useDataStore().language, { weekday: 'long' });
            let tempWarnings = new Array
            for(let d=0;d<7;d++){
                let dt=addDays( new Date(2000,1,7),d)
                if(warningCountArray[d].errorShifts.length>0&&warningCountArray[d].errorShifts.length==warningCountArray[d].shiftCount){tempWarnings.push("No shift fits availabilty on "+ weekDayFormat.format(dt))}
            }
            if(tempWarnings.length==1){warnings.push(tempWarnings[0])}
            else if(tempWarnings.length>1){warnings.push("No shift fits availabilty on "+tempWarnings.length + " weekdays")}

            // check for shifts that do not fit any days availability
            for(let s=0;s<sBag.shifts.length;s++){
                const sh = sBag.shifts[s]
                let shiftFits = false
                for(let d=0;d<7;d++){
                    if(dayIsIncludedInShiftDaylist(d,sh.dayList)&&!dayHasRotationShift(d,rotation)){
                        if(!warningCountArray[d].errorShifts.includes(s) )  {shiftFits=true}//add if is in rotaiton
                    }
                   
                }
                if(!shiftFits){warnings.push("Shift on row "+(s+1)+ " do not fit availabilities any day")}
            }
        }
    }
    }
    return warnings

    //Helping functions, daylist (mon 1, sat 6, sun 0)
    function dayIsIncludedInShiftDaylist(d,list){
        let day = d+1
        if (day== 7){day=0}
        return list.includes(day)
    }

    function getDayAvailability(d,avail){
        let result = []
        for(let i=0;i<avail.stFi.length-1;i+=2){
            if(avail.stFi[i]<(d+1)*96&&avail.stFi[i]>d*96){
                result.push({st:avail.stFi[i]-d*96,fi:avail.stFi[i+1]-d*96})
            }
        }
        return result
    }

    /**
     * @param {import("@/model/dn-employee.js").Employee} emp
     */
    function getMinMaxWeeklyWork(emp) {
        let retuenValue = { min: emp.agreedWorkWeek, max: emp.agreedWorkWeek }
        const minWorkWeek = emp.minWorkWeek ? emp.minWorkWeek : 0;
        const maxWorkWeek = emp.maxWorkWeek ? emp.maxWorkWeek : 7 * 24 * 60;
        if (emp.agreedWorkWeek > 0) {
            if (maxWorkWeek > emp.agreedWorkWeek) {
                retuenValue.max = maxWorkWeek;
            }
            if (minWorkWeek > 0) {
                retuenValue.min = minWorkWeek;
            }
        } else {
            retuenValue = { min: minWorkWeek, max: maxWorkWeek };
        }
        return retuenValue
    }

    function dayHasRotationShift(d,rotation){
        if(!rotation){return false}
        for(let w=0;w<rotation.weeks;w++){
                if(!rotation.shifts[d+w*7]){return false}
        }
        return true
    }

    function empvaluatePossibleShiftCombosToMeetWorkTime(possibleShifts,remainingWorkTime){
        let shiftCombos=[]
         for(let i=0;i<possibleShifts.length;i++){
             shiftCombos=combine(shiftCombos,possibleShifts)                    
             for(let s=0;s<shiftCombos.length;s++){
                 if(intersect(shiftCombos[s],remainingWorkTime)){return true}
             }
         }
         
         return false
     
         function intersect(a,b){
             return a.maxWork>=b.minWork && a.minWork<=b.maxWork
         }

     
         function combine(a,b){
             let res=[]
             if(a.length==0){return b}
             for(let x=0;x<a.length;x++){
                 for(let i=0;i<b.length;i++){
                     let sh = {minWork:a[x].minWork+b[i].minWork,maxWork:a[x].maxWork+b[i].maxWork}
                     let duplicat= res.some( value => { return value.minWork == sh.minWork&&value.maxWork == sh.maxWork })
                    if(!duplicat){
                        res.push(sh)
                    }
                 }
             }
             
             return res
         }
     }

     
}
export function empSkillsRowIsIncluded(r){
   return empRowIsIncluded(useScheduleStore().employees.getById(r.id))
}

export function empRowIsIncluded(r){
    const scheduleStore = useScheduleStore();
    //if(!scheduleStore.scheduleOptions.scheduleFilter.show){return true}
    let filter=scheduleStore.scheduleOptions.scheduleFilter
    let isIncludedByName=false
    let isIncludedBySkill=false
    let isIncludedByTag = false
    let includeByTermination= true
  
    if(filter.searchText.length>0){
      if( r.name.toLowerCase().includes(filter.searchText.toLowerCase())){isIncludedByName=true}
    }else{isIncludedByName=true}
  
    if(filter.callGroups.length>0){
      for(let c=0;c<filter.callGroups.length;c++){
        if( r.skills.includes(filter.callGroups[c].id)){isIncludedBySkill=true}
      }
    }else{isIncludedBySkill=true}
  
  
    if(filter.tagIds.length>0){
      for(let t=0;t<filter.tagIds.length;t++){
        if( r.tagIds.includes(filter.tagIds[t])){isIncludedByTag=true}
      }
    }else{isIncludedByTag=true}

    if(!filter.includeTerminated){
         if((r.terminationDate >new Date('2000-01-01')&&r.terminationDate<new Date())||!r.active){includeByTermination= false}
    }
   
  
    return isIncludedByName&&isIncludedBySkill&&isIncludedByTag&&includeByTermination
  }





import { fetchAndCheckJson } from '@/js/dn-fetch.js';

/** @typedef {{startTimeUTC?:Date;quarters?:number;intervals?:{st:Date;quarters:number}[];ipoa:number}} CallStatisticsPost */

/** @typedef {{cgId:number;noc:number[];ht:number[]}[]} CallStatisticsData */

/** @typedef {{data?:CallStatisticsData;list?:{st:Date;data:CallStatisticsData}[];isCalculating: boolean}} CallStatisticsResult */

/**
 * @param {CallStatisticsPost} data
 */
export async function getCallStatistics(data) {
  /** @type {CallStatisticsResult} */
  const result = await fetchAndCheckJson(`call-statistics`, 'POST', data);
  return result;
}

export async function recalculateCallGroups() {
  await fetchAndCheckJson(`call-statistics?recalc=1`, 'POST');
}

import { fetchAndCheckJson } from '@/js/dn-fetch.js';
import { parseGenesisCallTrackerTsv } from './dn-genesis.js';
var moment = require("moment-timezone");

/**
 * @typedef {{callid:number|string;timestamp:string;wrap:string|number,talk:string|number;tag1:string|number;tag2:string|number;tag3:string|number;abandon:string|number;switchId?:number}} ImportCallDto
 */

/**
 * @param {string[]} lines
 * @param {number} callImportFileType
 * @param {number} switchId
 * @param {{ (progress: any): void; (arg0: number): void; }} updateProgress
 */
export async function importCallsFile(lines, callImportFileType, switchId, updateProgress) {
  let startIndex = 0;
  let data = [];
  /** @type {string} */
  let start;
  let batchSize = 10000;

  if(callImportFileType == CALL_FILETYPE.infinity||callImportFileType == CALL_FILETYPE.isoDate){

  if (lines[0].toLowerCase() == 'callid;timestamp;wrap;talk;tag1;tag2;tag3;abandons')
    startIndex = 1;

  
  for (let i = startIndex; i < lines.length; i++) {
    const line = lines[i].replace(/ /g, '');
    const callData = line.split(';');
    if (callData.length === 1)
      continue;

    if (callData.length !== 8)
      throw new Error(`Number of fields error on line ${i}`);

    const callid = parseInt(callData[0]);
    if (!isNonNegative32Bit(callid)) {
      throw new Error(`Invalid callid on line ${i}`);
    }

    let timestamp = ""
    if (callImportFileType == CALL_FILETYPE.infinity) {
      const minutes = parseInt(callData[1]);

      if (!(minutes >= 6000000 && minutes < 68000000)) {
        throw new Error(`date error on line ${i}`);
      }

      //the timestamp from text file should be minutes from 1899-12-31 in customer time zone
      //36819360 - 60 * 24 is number of minutes from 1899-12-31 to 1970-01-01
      const dt = new Date((minutes - 36819360 + 60 * 24) * 1000 * 60);
      timestamp = dt.toISOString()
      timestamp = timestamp.substring(0, timestamp.length - 1);
    } else if (callImportFileType == CALL_FILETYPE.isoDate) {
      if (isNaN(Date.parse(callData[1]))) {
        throw new Error(`Bad formatting of timestamp on line ${i}`);
      }
      timestamp = callData[1]
    }

    const wrap = parseInt(callData[2]);
    if (!isNonNegative32Bit(wrap)) {
      throw new Error(`wrap error on line ${i}`);
    }

    const talk = parseInt(callData[3]);
    if (!isNonNegative32Bit(talk)) {
      throw new Error(`talk error on line ${i}`);
    }

    const tag1 = Number(emptyToNull(callData[4]));
    if (!Number.isInteger(tag1)){
      throw new Error(`tag1 not number error on line ${i}`);
    }
    if (tag1 != null && !isNonNegative64Bit(tag1)) {
      throw new Error(`tag1 error on line ${i}`);
    }

    const tag2 = Number(emptyToNull(callData[5]));
    if (!Number.isInteger(tag2)){
      throw new Error(`tag2 not number error on line ${i}`);
    }
    if (tag2 != null && !isNonNegative64Bit(tag2)) {
      throw new Error(`tag2 error on line ${i}`);
    }

    /** @type {null|string|number} */
    let tag3 = emptyToNull(callData[6]);

    if (!Number.isInteger(Number(tag3))){
      throw new Error(`tag3 not number error on line ${i}`);
    }
    
    if (tag3 !== null) {
      tag3 = parseInt(tag3);
      if (!isNonNegative32Bit(tag3)) {
        throw new Error(`tag3 error on line ${i}`);
      }
    }

    const abandon = parseInt(callData[7]);
    if (!(abandon === 0 || abandon === 1)) {
      throw new Error(`abandon expects 0 or 1 error on line ${i}`);
    }

    data.push({
      callid: callid,
      timestamp: timestamp,
      wrap: wrap,
      talk: talk,
      tag1: tag1,
      tag2: tag2,
      tag3: tag3,
      abandon: abandon,
      switchId: switchId
    })
  }
}else if(callImportFileType == CALL_FILETYPE.genesisLS){

  const columnMap = new Map //genesis

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i]//.replace(/ /g, '');
    const callData = line.split(';');
    
    if (callData.length === 1)
      continue;

      //Read columns
      if(i==0){
        for(let c=0;c<callData.length;c++){
          let col = callData[c]
          if(col=="startTime"){columnMap.set(c,col)}
          if(col=="externalId"){columnMap.set(c,col)}
          if(col=="talk"){columnMap.set(c,col)}
          if(col=="wrapup"){columnMap.set(c,col)}
          if(col=="mark1"){columnMap.set(c,col)}
          if(col=="callType"){columnMap.set(c,col)}
        }
      }else{
        let callid=0
        let wrap
        let talk
        let tag1
        let timestamp = ""
        let abandon=0

      for(let c=0;c<callData.length;c++){
        if(columnMap.has(c)){
          let col = columnMap.get(c)
          if(col=="startTime") {
            if (isNaN(Date.parse(callData[c]))) {
              throw new Error(`Bad formatting of timestamp on line ${i}`);
            }
            timestamp= moment(new Date(callData[c])).format("YYYY-MM-DD HH:mm:ss");
            timestamp = timestamp.substring(0,10)+'T'+timestamp.substring(11,19)+'.000'
          }
          if(col=="externalId") {
            callid = parseInt(callData[c]);
              if (!isNonNegative32Bit(callid)) {
                throw new Error(`Invalid callid on line ${i}`);
              }
          }
          if(col=="talk") {
            talk = 1000*parseInt(callData[c]);
              if (!isNonNegative32Bit(talk)) {
                throw new Error(`talk error on line ${i}`);
              }
          }
          if(col=="wrapup") {
            wrap = 1000*parseInt(callData[c]);
              if (!isNonNegative32Bit(wrap)) {
                throw new Error(`wrap error on line ${i}`);
              }
          }
          if(col=="mark1") {
            tag1 = Number(emptyToNull(callData[c]));
            if (!Number.isInteger(tag1)){
              throw new Error(`tag1 not number error on line ${i}`);
            }
            if (tag1 != null && !isNonNegative64Bit(tag1)) {
              throw new Error(`tag1 error on line ${i}`);
            }
          }
          if(col=="callType") {
            if(parseInt(callData[c])==3){abandon =1}else{abandon =0}
              if (!(abandon === 0 || abandon === 1)) {
                throw new Error(`abandon expects 0 or 1 error on line ${i}`);
              }
          }
        }
        
      }

      data.push({
        callid: callid,
        timestamp: timestamp,
        wrap: wrap,
        talk: talk,
        tag1: tag1,
        tag2: 0,
        tag3: 0,
        abandon: abandon,
        switchId: switchId
      })
    }
}
  } else if (callImportFileType === CALL_FILETYPE.genesisCallTracker) {
    batchSize = 1000;
    const report = parseGenesisCallTrackerTsv(lines);
    start = report.start;
    data = report.calls;
  }

  if (data.length === 0)
    throw new Error('No calls to import found');

  let result;
  const total = data.length;
  let left = data.length;
  do {
    let batch;
    if (left <= batchSize) {
      batch = data;
      left = 0;
    } else {
      batch = data.splice(0, batchSize);
      left = data.length;
    }

    if (callImportFileType === CALL_FILETYPE.genesisCallTracker) {
      /** @type {import("@/js/dn-genesis.js").GenesisCallTrackerReport} */
      const batchedReport = { start, switchId, calls:batch };
      result = await fetchAndCheckJson(`import-calls?kind=${CALL_FILETYPE.genesisCallTracker}`, 'POST', batchedReport);
    } else {
      result = await fetchAndCheckJson('import-calls', 'POST', batch);
    }
    
    updateProgress(1 - left / total);
  } while (left > 0);

  return result;
}

/**
 * @param {number} number
 */
function isNonNegative32Bit(number) {
  return number >= 0 && number <= 2147483647;
}

function isNonNegative64Bit(numberAsString) {
  const number = parseInt(numberAsString);
  return number >= 0 || number <= 9007199254740990
}

/**
 * @param {string} value
 */
function emptyToNull(value) {
  if (value.length === 0) {
    return null;
  }
  return value;
}
export const CALL_FILETYPE = createCallFileTypeEnum();

    function createCallFileTypeEnum() {
    const enumObject = {
        isoDate: 0,
        infinity: 1,
        genesisLS: 2,
        genesisCallTracker: 3,

    };

  return Object.freeze(enumObject)}

 
import { addDays, condence, getDisplayResolutionFactor, getNumberOfDaysFromIntervalMinusOne, getShortDate, shuffleArray } from "@/js/dn-helper.js";
import { getForcastOnInterval, getForcastXX } from './dn-forecast.js';
import { getEmpWorkArray, getEmpWorkMap } from "@/model/dn-employee-schedule.js";
import { TASK_KIND } from "@/model/dn-tasktype.js";
import { simulationData } from "@/stores/dn-simulation-data.js";
import * as dnSimModel from './dn-simulation-model.js';
/**
* @typedef {{avgQueue:boolean; datesWithChanges:any[]; employeeOccupancy:boolean; forceUpdate:boolean; numberOfSimIterations:number; occ:boolean; sl:boolean; waitWithin:boolean}} OutputSpec
*/

const _maxWaitBeforeAbandonn = 600
const _numberOfSecondsPerDay = 24 * 60 * 60

export class SimState {

  /**
   * @param {import("@/model/dn-tasktype.js").TaskTypes} taskTypes
   * @param {Date} dateNow
   * @param {Map<string, Map<number, number>>} forecastAdjustmentmap
   * @param {{ historicWeeks: number; adherancePercent: number; }} forecastOptions
   * @param {Map<string, { dt: Date; acgr: number; ahtData: number[]; nocData: number[]; }>} historicDataMap,
   * @param {{ handlingTimePercent: number; handlingTimeVariationPercent: number; callsPercent: number; }} simulationSettings
   * @param {Map<string, number>} specialDaysMap
   * @param {import("@/model/dn-callgroup.js").CallGroup[]} callGroups
   * @param {Date} latestImportDate
   * @param {import("@/model/dn-employee-cc.js").EmployeeCallcenters} employeeCallcenters
   * @param {number} ccId
   * @param {number|null} affinity
   */
  constructor(taskTypes, dateNow, forecastAdjustmentmap, forecastOptions, historicDataMap, simulationSettings, specialDaysMap, callGroups, latestImportDate, employeeCallcenters, ccId, affinity = null) {
    /** @type {number|null} */
    this.affinity = affinity;
    /** @readonly @type {Map<number, import("@/model/dn-tasktype.js").TaskType>} */
    this.affinityTaskTypeById = new Map(taskTypes.list.filter(x => x.affinity === affinity && x.kind === TASK_KIND.task).map(x => [x.id, x]));
    /** @readonly @type {number|null} best task type to plan */
    this.taskTypeId = null;
    let maxWork = -1;
    for (const tt of this.affinityTaskTypeById.values()) {
      if (tt.work > maxWork) {
        maxWork = tt.work;
        this.taskTypeId = tt.id;
      }
    }
    /** @readonly @type {{ handlingTimePercent: number; handlingTimeVariationPercent: number; callsPercent: number; }} */
    this.simulationSettings = simulationSettings;
    /** @readonly @type {Date} */
    this.dateNow = dateNow;
    /** @readonly @type {Map<string, Map<number, number>>} */
    this.forecastAdjustmentmap = forecastAdjustmentmap;
    /** @readonly @type {Date} */
    this.latestImportDate = latestImportDate;
    /** @readonly @type {Map<string, number>} */
    this.specialDaysMap = specialDaysMap;
    /** @readonly @type {Map<number, import("@/model/dn-tasktype.js").TaskType>} */
    this.taskTypeById = taskTypes.byId;
    /** @readonly @type {Map<string, { dt: Date; acgr: number; ahtData: number[]; nocData: number[]; }>} */
    this.historicDataMap = historicDataMap;
    /** @readonly @type {{ historicWeeks: number; adherancePercent: number; }} */
    this.forecastOptions = forecastOptions;
    /** @readonly @type {import("@/model/dn-callgroup.js").CallGroup[]} */
    this.callGroups = callGroups.filter(x => x.affinity === affinity);
    /** @readonly @type {import("@/model/dn-employee-cc.js").EmployeeCallcenters} */
    this.employeeCallcenters = employeeCallcenters;
    /** @readonly @type {number} */
    this.ccId = ccId;
  }
}

export class SimParameters {
  /**
 * @param {SimState} simState
 * @param {import("@/model/dn-employee-schedule.js").EmployeeSchedule[]} schedule
 * @param {{id:number}[]} emps
 * @param {Date} simSt
 * @param {number} length
 */
  constructor(simState, schedule, emps, simSt, length) {
    this.dayForcast = simState.callGroups.map(function (x) {
      return {
        acgr: x,
        forc: getForcastOnInterval(simSt, length, simState.dateNow, simState.specialDaysMap, simState.forecastOptions.historicWeeks, x.id, simState.historicDataMap, simState.forecastAdjustmentmap, simState.latestImportDate)
      }
    })
    /** @readonly @type {any[]} */
    this.daySchedule = schedule.map(function (x) {
      return {
        emp: { id: x.emp.id, skills: x.emp.skills, occupiedUntil: 0 }, scheduleInfoArray: getEmpWorkArray(x, simSt, simState.affinityTaskTypeById, length, simState.employeeCallcenters, simState.ccId)
      }
    });
    /** @readonly @type {import("@/model/dn-callgroup.js").CallGroup[]} */
    this.callGroups = simState.callGroups;
    /** @readonly @type {{id:number}[]} */
    this.emps = emps;
    /** @readonly @type {number} */
    this.length = length;
    /** @readonly @type {Date} */
    this.simSt = simSt;
    /** @readonly @type {{ handlingTimePercent: number; handlingTimeVariationPercent: number; callsPercent: number; }} */
    this.simulationSettings = simState.simulationSettings;
    /** @readonly @type {{adherancePercent: number}} */
    this.forecastOptions = simState.forecastOptions;
  }
}

/**
 * @param {SimParameters} params
 * @param {number} numberOfIterations
 */
export function getSimResult(params, numberOfIterations) {

  const simData = new dnSimModel.SimData(params.callGroups, params.emps, params.length);
  const simStTime = params.simSt.getTime();
  for (let i = 0; i < numberOfIterations; i++) {
    for (const ds of params.daySchedule) {
      ds.emp.occupiedUntil = simStTime;
    }

    const calls = simulate(params.simSt, params.daySchedule, params.dayForcast, params.callGroups, params.simulationSettings, params.forecastOptions.adherancePercent);
    addSimData(simData, calls, params.simSt, numberOfIterations, params.daySchedule);
  }

  return getSimulationResult(params, simData);
}


/**
 * @param {{ emps: {id:number}[]; callGroups: import("@/model/dn-callgroup.js").CallGroup[]; daySchedule: any[]; }} params
 */
function getEmptySimDay(params) {
  const length = 96;
  const simData = new dnSimModel.SimData(params.callGroups, params.emps, length);
  return getSimulationResult(params, simData);
}

/**
 * @param {string} dtKey
 * @param {number|null} affinityId
 */
function getSimulationDataKey(dtKey, affinityId) {
  return dtKey + '_' + affinityId;
}

/**
 * @param {OutputSpec} outputSpec
 * @param {SimState} simState
 * @param {{dateInterval:Date[]}} scheduleOptions
 * @param {import("@/model/dn-employee-schedule.js").EmployeeSchedule[]} schedule
 * @param {?function(number):void} callbackSiminfo
 */
export function getSimData(outputSpec, simState, scheduleOptions, schedule, callbackSiminfo = null) {
  const length = 96;
  let simData = {}
  const numberOfDays = getNumberOfDaysFromIntervalMinusOne(scheduleOptions.dateInterval)
  let dt = new Date
  const emps = schedule.map(function (x) { return x.emp });
  const numberOfIterations = outputSpec.numberOfSimIterations
  const simulationDataMap = simulationData.simulationDataMap;

  // var t0 = performance.now()
  for (let d = 0; d < numberOfDays; d++) {
    if (callbackSiminfo) { callbackSiminfo((d + 1) * 100 / numberOfDays) }
    dt = addDays(new Date(scheduleOptions.dateInterval[0]), d); //eg:Wed Apr 01 2020 00:00:00 GMT+0200 (centraleuropeisk sommartid)
    const dtKey = getShortDate(dt);
    const key = getSimulationDataKey(dtKey, simState.affinity);

    if (!simulationDataMap.has(key) || outputSpec.forceUpdate || outputSpec.datesWithChanges.includes(dtKey)) {
      let simDay;
      if (numberOfIterations > 0) {
        const simParameters = new SimParameters(simState, schedule, emps, dt, length);
        simDay = getSimResult(simParameters, numberOfIterations);
      } else {
        const x = { emps: emps, callGroups: simState.callGroups, daySchedule: [] };
        simDay = getEmptySimDay(x);
      }
      simulationDataMap.set(key, simDay)
    }
  }

  if (outputSpec.sl) {
    simData.serviceLevelDisplayData = getServicelevelDisplayData(simState.affinity, scheduleOptions.dateInterval[0], numberOfDays, simState);
  }
  if (outputSpec.occ) {
    const numberOfDays = getNumberOfDaysFromIntervalMinusOne(scheduleOptions.dateInterval)
    let empWorkMap = getEmpWorkMap(schedule, simState.affinityTaskTypeById, scheduleOptions.dateInterval[0], numberOfDays, length, simState.employeeCallcenters, simState.ccId);
    let x = getOccupancyAndWorkChartData(scheduleOptions.dateInterval[0], numberOfDays, empWorkMap, emps, length, simState);
    simData.occChartData = x.occData;
    simData.workChartData = x.workData;
    simData.work = x.totWork;
    simData.occ = x.totOcc;
  }

  /* if(outputSpec.sickAndVaccationSummary){
    let data=getSickAndVaccationSummary(daySchedule,taskTypeMap)
    simData.numberOfSick=x.numberOfSick;
    simData.numberOfVacation=x.numberOfVacation;
  } */


  if (outputSpec.avgQueue) {
    simData.avgQueueData = getQueueChartData(scheduleOptions.dateInterval[0], numberOfDays, simState);
  }
  if (outputSpec.waitWithin) {
    simData.waitWithinData = getWaitWithinData(scheduleOptions.dateInterval[0], numberOfDays, simState);
  }
  if (outputSpec.employeeOccupancy) {
    simData.employeeOccupancyData = getEmployeeOccupancyData(scheduleOptions.dateInterval[0], numberOfDays, emps, length, simState);
  }
  // var t1 = performance.now()
  // console.log("Timer " + (t1 - t0) + " milliseconds.")
  return simData
}

/**
 * @param {import("@/model/dn-callgroup.js").CallGroup[]} callGroups
 */
function getDataByAffinity(callGroups, emplyeesByAffinity) {
  /** @type {Map<number|null, import("@/model/dn-callgroup.js").CallGroup[]>} */
  const callGroupsByAffinity = new Map();
  for (const cg of callGroups) {
    let cgData = callGroupsByAffinity.get(cg.affinity);
    if (cgData === undefined) {
      cgData = [];
      callGroupsByAffinity.set(cg.affinity, cgData);
    }
    cgData.push(cg);
  }

  const byAffinity = [];
  for (const [affinity, cgs] of callGroupsByAffinity) {
    byAffinity.push({ affinity, callGroups: cgs, slTarget: getAvgSlSetting(cgs), fantomDefaultSkills: cgs.map(function (x) { return x.id }), filteredEmpsByAffinity: emplyeesByAffinity.get(affinity) });
  }

  return byAffinity;
}

/**
 * @param {import("@/model/dn-callgroup.js").CallGroup[]} callGroups
 * @param {any[]} employees
 * @param {any[]} affinities
 */
function getEmployeesByAffinity(callGroups, employees, affinities) {
  const hasAffinities = affinities != null && affinities.length > 0

  /** @type {Map<number|null, any[]>} */
  const returnMap = new Map()

  if (hasAffinities) {

    //prepare data structure
    /** @type {Map<number, number|null>} */
    const cgMap = new Map();
    let hasCallgroupWithNoAffinity = false;
    for (const cg of callGroups) {
      cgMap.set(cg.id, cg.affinity);
      if (cg.affinity === null) {
        hasCallgroupWithNoAffinity = true;
      }
    }
    if (hasCallgroupWithNoAffinity) {
      returnMap.set(null, []);
    }

    for (const affinity of affinities) {
      returnMap.set(affinity.id, []);
    }

    //load arrys with emps connected to each affinity
    for (const emp of employees) {
      for (const s of emp.skills) {
        let a = cgMap.get(s)
        let empArray = returnMap.get(a)
        if (empArray && !empArray.includes(emp)) { empArray.push(emp) }
      }
    }
  } else {
    returnMap.set(null, employees)
  }
  return returnMap
}

/**
 * @param {any} simulationSettings
 * @param {{ dateInterval: Date[]; }} scheduleOptions
 * @param {any[]} employees
 * @param {{ historicWeeks: number; adherancePercent: number}} forecastOptions
 * @param {Map<string, number>} specialDaysMap
 * @param {Date} dateNow
 * @param {Map<string, Map<number, number>>} forecastAdjustmentmap
 * @param {import("@/model/dn-callgroup.js").CallGroup[]} callGroups
 * @param {Map<string, {dt:Date, acgr:number, ahtData:number[], nocData:number[]}>} historicDataMap
 * @param {boolean} forceUpdate
 * @param {number|null} affinityId
 * @param {Date} latestImportDate
 * @param {any[]} affinities
 * @param {number} [numberOfIterations]
 */
export function getTarget(simulationSettings, scheduleOptions, employees,
  forecastOptions, specialDaysMap, dateNow, forecastAdjustmentmap,
  callGroups, historicDataMap, forceUpdate, affinityId, latestImportDate, affinities, numberOfIterations = 1) {
  //function may be called before data are fully loaded
  if (employees.length === 0 || historicDataMap.size === 0) { return []; }

  // var t0 = performance.now()
  /** @type {number[]} */
  let returnArray = [];

  const numberOfDays = getNumberOfDaysFromIntervalMinusOne(scheduleOptions.dateInterval);
  const adheranceFactor = 100 / forecastOptions.adherancePercent;
  if (!simulationData.emplyeesByAffinity || forceUpdate) {
    simulationData.emplyeesByAffinity = getEmployeesByAffinity(callGroups, employees, affinities);
  }
  let byAffinity;
  const targetDataMap = simulationData.targetDataMap;
  for (let d = 0; d < numberOfDays; d++) {
    const dt = addDays(new Date(scheduleOptions.dateInterval[0]), d); //eg:Wed Apr 01 2020 00:00:00 GMT+0200 (centraleuropeisk sommartid)
    let dtKey = getShortDate(dt);

    if (!targetDataMap.has(dtKey) || forceUpdate) {
      /** @type {Map<number|null, number[]>}} */
      const targetByAffinity = new Map();
      if (byAffinity === undefined) {
        byAffinity = getDataByAffinity(callGroups, simulationData.emplyeesByAffinity);
      }

      for (const affinityData of byAffinity) {
        if (affinityData.filteredEmpsByAffinity.length === 0) { continue; }
        /** @type {number[]} */
        const target = new Array(96).fill(0)
        const dayForcast = affinityData.callGroups.map(function (x) {
          return {
            acgr: x,
            forc: getForcastXX(new Date(dt), dateNow, specialDaysMap, forecastOptions.historicWeeks, x.id, historicDataMap, forecastAdjustmentmap, latestImportDate)
          }
        })

        for (let i = 0; i < numberOfIterations; i++) {
          for (let q = 0; q < 96; q++) {
            //expand the quarter with a factor to get at least 100 calls to simulate
            let timeRangeInQuarters = getTimeRangeInQuartersForTargetSim(dayForcast, q)
            let calls = generateQuarterCallsTarget(dt, dayForcast, affinityData.callGroups, simulationSettings, q, timeRangeInQuarters)

            if (calls.length == 0) {
              target[q] = 0
            } else {

              //detrmin initial possible target range based on Erlang C and after one iteration based on previous iterations
              //let targetRange = []
              // if (i == 0 || target[q] < 10) {
              //const erlangA = getErlangA(dayForcast, q);
              // targetRange = [erlangA, erlangA * 3];
              const minTarget = getErlangCTargetWeighted(dayForcast, q);
              const maxTarget = getErlangCTargetSum(dayForcast, q);
              const targetRange = [Math.floor(minTarget), Math.ceil(maxTarget)];

              // } else {
              //   if (i === 1)
              //     targetRange = [(target[q] / (i) * 0.8), (target[q] / (i) * 1.2)];
              //   else
              //     targetRange = [(target[q] / (i) * 0.9), (target[q] / (i) * 1.1)];
              // }
              //narrow posible target range by half in each iteration
              let slLow = 0;
              let slHigh = 100;
              let hasCalculated = [targetRange[0] === 0, false];
              do {
                let testNumberOfAgent = undefined;
                if (targetRange[1] - targetRange[0] < 3) {
                  if (!hasCalculated[0]) {
                    testNumberOfAgent = targetRange[0];
                    hasCalculated[0] = true;
                  } else if (!hasCalculated[1]) {
                    testNumberOfAgent = targetRange[1];
                    hasCalculated[1] = true;
                  }
                }
                if (testNumberOfAgent === undefined)
                  testNumberOfAgent = Math.round((targetRange[0] + targetRange[1]) / 2);

                let emps = []
                for (let e = 0; e < testNumberOfAgent; e++) { emps.push(getFantom(dt, affinityData)) }
                const waitData = getWaitData(emps, calls, q, timeRangeInQuarters, affinityData.callGroups, dt);
                let slQuarter = getTargetServiceLevel(waitData, calls, affinityData.slTarget)
                if (slQuarter < affinityData.slTarget.slPercent) {
                  slLow = slQuarter
                  targetRange[0] = testNumberOfAgent;
                  hasCalculated[0] = true;
                } else {
                  slHigh = slQuarter;
                  targetRange[1] = testNumberOfAgent;
                  hasCalculated[1] = true;
                }

              } while (!hasCalculated[0] || !hasCalculated[1] || (targetRange[1] - targetRange[0] > 2))
              const weight = (slHigh - affinityData.slTarget.slPercent) / (slHigh - slLow);
              let weighted = weight * targetRange[0] + (1 - weight) * targetRange[1];
              weighted = Math.max(minTarget, Math.min(maxTarget, weighted));
              target[q] += Math.max(1, weighted);
            }
          }
        }

        for (let q = 0; q < 96; q++) {
          target[q] = target[q] * adheranceFactor / numberOfIterations
        }

        targetByAffinity.set(affinityData.affinity, target);

        targetDataMap.set(dtKey, targetByAffinity)
        //validation data
        /*        for(let p=0;p<96;p++){
                let noc =0
                let ht=0
                let aht=0
                for(let a = 0;a<dayForcast.length;a++){
                  noc+=dayForcast[a].forc.noc[p]
                  ht+=dayForcast[a].forc.ht[p]
                }
                if(noc>0){aht=ht/noc}
                console.log(noc+';'+aht+';'+target[p])
               } */

      }
    }
    let targetSum;
    const dayTargetByAffinity = targetDataMap.get(dtKey);
    if (dayTargetByAffinity !== undefined) {
      if (affinityId !== null) {
        targetSum = dayTargetByAffinity.get(affinityId);
      } else if (dayTargetByAffinity.size === 1) {
        for (const dayTarget of dayTargetByAffinity.values()) {
          targetSum = dayTarget;
        }
      } else {
        targetSum = new Array(96).fill(0);
        for (const dayTarget of dayTargetByAffinity.values()) {
          for (let q = 0; q < 96; q++) {
            targetSum[q] += dayTarget[q];
          }
        }
      }
    } else {
      targetSum = new Array(96).fill(0);
    }

    returnArray = returnArray.concat(targetSum);
  }

  // console.log(t0 - performance.now())
  return returnArray;


  /**
   * @param {number[]} waitData
   * @param {any[]} calls
   * @param {{ slPercent?: number; slWithin: any; min?: number; max?: number; }} slTarget
   */
  function getTargetServiceLevel(waitData, calls, slTarget) {
    let slQuarter = 0
    let x = waitData.filter(d => d <= slTarget.slWithin).length
    if (x > 0) { slQuarter = Math.round(100 * x / waitData.length) } else { slQuarter = 0 }
    if (calls.length == 0) { slQuarter = 100 }
    return slQuarter
  }

  /**
   * @param {any[]} emps
   * @param {dnSimModel.Call[]} calls
   * @param {number} q
   * @param {number} timeRangeInQuarters
   * @param {import("@/model/dn-callgroup.js").CallGroup[]} callGroups
   * @param {Date} daySt
   */
  function getWaitData(emps, calls, q, timeRangeInQuarters, callGroups, daySt) {
    let waitData = []

    //arange emps by skill in a Map
    /** @type {Map<number, any>} */
    let empsBySkillMap = new Map
    for (let a = 0; a < callGroups.length; a++) {
      const callGroupId = callGroups[a].id;
      let filteredEmps = emps.filter(e => e.skills.includes(callGroupId))
      empsBySkillMap.set(callGroupId, filteredEmps)
    }

    //set initial occupied time to start of day
    for (let ex = 0; ex < emps.length; ex++) {
      emps[ex].occupiedUntil = daySt.getTime();
    }

    const excludeStartup = daySt.getTime() + 1000 * ((q + (3 * timeRangeInQuarters / 15)) * 15 * 60);
    for (let c = 0; c < calls.length; c++) {
      let empWithLongestWait = getEmpWithLongestWait(empsBySkillMap.get(calls[c].callGroupId), calls[c])
      if (empWithLongestWait) {
        const diff = (empWithLongestWait.occupiedUntil - calls[c].msSinceEpoch) / 1000
        if (diff < _maxWaitBeforeAbandonn) {
          calls[c].wait = diff > 0 ? diff : 0;
          empWithLongestWait.occupiedUntil = calls[c].timeStampEnd(100)//adherence is applied after target calculation
        } else {
          calls[c].wait = _maxWaitBeforeAbandonn
        }
      } else { calls[c].wait = _maxWaitBeforeAbandonn }

      //Exclude first minutes to avoid startup effect
      if (calls[c].msSinceEpoch > excludeStartup) {
        waitData.push(calls[c].wait)
      }
    }
    return waitData

    function getEmpWithLongestWait(emps, call) {
      let maxDiff = 10000000
      let indexFound = -1
      for (let ex = 0; ex < emps.length; ex++) {
        const diff = emps[ex].occupiedUntil - call.msSinceEpoch;
        if (diff < maxDiff) {
          indexFound = ex
          maxDiff = diff
        }
      }
      //To be discussed:If I understand this correctly the routing of calls is defined so that the work is spread out evenly. Perhaps alternative one could stop and give the call to the first how has no wait time.
      return emps[indexFound]
    }
  }

  //create a fantom agent with random skills following skill profile of contavt center
  function getFantom(dt, affinityData) {
    let randomEmpId = Math.floor(Math.random() * affinityData.filteredEmpsByAffinity.length)
    let fantom = null
    if (affinityData.filteredEmpsByAffinity[randomEmpId].skills.length > 0) {
      fantom = { occupiedUntil: dt.getTime(), skills: affinityData.filteredEmpsByAffinity[randomEmpId].skills }
    } else {
      fantom = { occupiedUntil: dt.getTime(), skills: affinityData.fantomDefaultSkills }
    }
    return fantom
  }
}


//if fixedNumberOfcalls>1, this number of calls are generated each q. the time range is prolonged so that the same density of calls are produced
/**
 * @param {Date} simSt
 * @param {any[]} dayForcast
 * @param {import("@/model/dn-callgroup.js").CallGroup[]} callGroups
 * @param {{ handlingTimePercent: number; handlingTimeVariationPercent: number; }} simulationSettings
 * @param {number} q
 * @param {number} timeRangeInQuarters
 */
function generateQuarterCallsTarget(simSt, dayForcast, callGroups, simulationSettings, q, timeRangeInQuarters) {
  let returnValue = []
  for (let a = 0; a < dayForcast.length; a++) {
    let aht = dayForcast[a].forc.noc[q] == 0 ? 0 : dayForcast[a].forc.ht[q] / dayForcast[a].forc.noc[q]
    aht = aht * ((100 + simulationSettings.handlingTimePercent) / 100)
    let ht = aht * (1 + (Math.random() - 0.5) * (2 * simulationSettings.handlingTimeVariationPercent / 100))
    //if(q==0){console.log(timeRangeInQuarters+ '  ' +dayForcast[a].forc.noc[q])}
    for (let i = 0; i < dayForcast[a].forc.noc[q] * timeRangeInQuarters; i++) {
      const secondsToAdd = q * 15 * 60 + Math.random() * 15 * 60 * timeRangeInQuarters;
      const c = new dnSimModel.Call(simSt.getTime() + secondsToAdd * 1000, ht, callGroups[a].id)
      returnValue.push(c)
    }
    //if(q==0){ console.log('q: '+q+ ' calls: '+ returnValue.length)}
  }
  return returnValue.sort(function (a, b) { return a.msSinceEpoch - b.msSinceEpoch; });
}

/**
* @param {{acgr: import("@/model/dn-callgroup.js").CallGroup, forc: {ht:number[], noc:number[]}}[]} dayForcast
* @param {number} q
*/
function getErlangA(dayForcast, q) {
  let returnValue = 0
  for (let a = 0; a < dayForcast.length; a++) {
    returnValue += dayForcast[a].forc.ht[q]
  }

  return returnValue / 900;
}
/**
* @param {{acgr: import("@/model/dn-callgroup.js").CallGroup, forc: {ht:number[], noc:number[]}}[]} dayForcast
* @param {number} q
*/
function getErlangCTargetWeighted(dayForcast, q) {
  let ht = 0;
  let noc = 0;
  let slPercent = 100;
  let slWithin = 0;
  for (let a = 0; a < dayForcast.length; a++) {
    const df = dayForcast[a];
    if (df.forc.noc[q] > 0) {
      ht += df.forc.ht[q];
      noc += df.forc.noc[q];
      slPercent = Math.min(slPercent, df.acgr.slPercent);
      slWithin = Math.max(slWithin, df.acgr.slWithin);
    }
  }

  const e = ht / 900;
  const aht = noc == 0 ? 0 : ht / noc;
  return getErlangCTarget(e, aht, slWithin, slPercent);
}

/**
* @param {{acgr: import("@/model/dn-callgroup.js").CallGroup, forc: {ht:number[], noc:number[]}}[]} dayForcast
* @param {number} q
*/
function getErlangCTargetSum(dayForcast, q) {
  let sum = 0
  for (let a = 0; a < dayForcast.length; a++) {
    const df = dayForcast[a];
    const e = df.forc.ht[q] / 900;
    const aht = df.forc.noc[q] == 0 ? 0 : df.forc.ht[q] / df.forc.noc[q];
    sum += getErlangCTarget(e, aht, df.acgr.slWithin, df.acgr.slPercent)
  }

  return sum;
}


/**
 * @param {Date} dt
 * @param {Date} dateNow
 * @param {Map<string, number>} specialDaysMap
 * @param {any} forecastOptions
 * @param {Map<string, {dt:Date, acgr:number, ahtData:number[], nocData:number[]}>} historicDataMap
 * @param {Map<string, Map<number, number>>} forecastAdjustmentmap
 * @param {import("@/model/dn-callgroup.js").CallGroup[]} callGroupsToInclude
 * @param {Date} latestImportDate
 */
export function getErlangCTargetArray(dt, dateNow, specialDaysMap, forecastOptions, historicDataMap, forecastAdjustmentmap, callGroupsToInclude, latestImportDate) {
  const minArray = new Array(96).fill(0);
  const maxArray = new Array(96).fill(0);

  /** @type {Map<number|null, import("@/model/dn-callgroup.js").CallGroup[]>} */
  const cgByAffinity = new Map();
  for (const cg of callGroupsToInclude) {
    let cgs = cgByAffinity.get(cg.affinity)
    if (cgs === undefined) {
      cgs = [];
      cgByAffinity.set(cg.affinity, cgs);
    }
    cgs.push(cg);
  }

  const adherenceFactor = forecastOptions.adherancePercent / 100;
  for (const callGroups of cgByAffinity.values()) {
    const totForc = { noc: new Array(96).fill(0), ht: new Array(96).fill(0) }
    let slSetting = getAvgSlSetting(callGroups)
    /** @type {number[]} */
    const sum = new Array(96).fill(0);
    for (let a = 0; a < callGroups.length; a++) {
      let forc = getForcastXX(new Date(dt), dateNow, specialDaysMap, forecastOptions.historicWeeks, callGroups[a].id, historicDataMap, forecastAdjustmentmap, latestImportDate)
      for (let q = 0; q < 96; q++) {
        const e = forc.ht[q] / 900;
        const aht = forc.noc[q] == 0 ? 0 : forc.ht[q] / forc.noc[q];
        totForc.ht[q] += forc.ht[q]
        totForc.noc[q] += forc.noc[q]
        sum[q] += getErlangCTarget(e, aht, callGroups[a].slWithin, callGroups[a].slPercent) / adherenceFactor;
      }
    }
    for (let q = 0; q < 96; q++) {
      if (sum[q] > 0) {
        maxArray[q] += Math.max(1.0, sum[q]);
      }
      const e = totForc.ht[q] / 900;
      const aht = totForc.noc[q] == 0 ? 0 : totForc.ht[q] / totForc.noc[q];
      minArray[q] += getErlangCTarget(e, aht, slSetting.slWithin, slSetting.slPercent) / adherenceFactor;
    }
  }

  return { minArray, maxArray };
}


function getTimeRangeInQuartersForTargetSim(dayForcast, q) {
  let timeRangeInQuarters = 1
  let forcSum = 0
  for (let a = 0; a < dayForcast.length; a++) { forcSum += dayForcast[a].forc.noc[q] }
  if (forcSum > 0) { timeRangeInQuarters = 60 / forcSum }
  if (timeRangeInQuarters < 1.5) { timeRangeInQuarters = 1.5 }
  return timeRangeInQuarters
}

/**
 * @param {Date} simSt
 * @param {{emp:{id:number, skills:number[], occupiedUntil: number}, scheduleInfoArray:number[]}[]} daySchedule
 * @param {{ acgr: import("@/model/dn-callgroup.js").CallGroup; forc: { noc: number[]; ht: number[]; }}[]} dayForcast
 * @param {import("@/model/dn-callgroup.js").CallGroup[]} callGroups
 * @param {{ handlingTimePercent: number; handlingTimeVariationPercent: number; callsPercent: number; }} simulationSettings
 * @param {number} adherancePercent
 */
function simulate(simSt, daySchedule, dayForcast, callGroups, simulationSettings, adherancePercent) {
  let calls = generateQuarterCalls(simSt, dayForcast, simulationSettings)

  if (!simulationData.callGroupPrioInfoMap) { loadCallGroupPrioInfo(callGroups) }
  const maxPrioSecondInQueue = simulationData.callGroupPrioMax
  const callGroupPrioInfoMap = simulationData.callGroupPrioInfoMap

  let lastQuarter = -1

  /** @type {Map<number, {emp:{id:number, skills:number[], occupiedUntil: number}, scheduleInfoArray:number[]}[]>} */
  const daySchedulesQuarterBySkillMap = new Map();
  shuffleArray(daySchedule);

  for (let c = 0; c < calls.length; c++) {
    const call = calls[c]
    const q = Math.floor((calls[c].msSinceEpoch - simSt.getTime()) / (1000 * 60 * 15));
    if (q != lastQuarter) {
      for (let a = 0; a < callGroups.length; a++) {
        let callGroupId = callGroups[a].id
        let filteredDaySchedules = daySchedule.filter(d => d.emp.skills.includes(callGroupId) && d.scheduleInfoArray[q] > 0)

        daySchedulesQuarterBySkillMap.set(callGroupId, filteredDaySchedules)
      }
      lastQuarter = q
    }

    if (maxPrioSecondInQueue > 0) {
      for (let i = 1; i < 100; i++) {
        if (i + c < calls.length) {
          const callX = calls[i + c]
          let qx = Math.floor((callX.msSinceEpoch - simSt.getTime()) / (1000 * 60 * 15))
          if (qx == lastQuarter) {
            if (!callX.processed && (callX.msSinceEpoch - call.msSinceEpoch) < callGroupPrioInfoMap.get(callX.callGroupId) * 1000) {
              processCall(callX, qx)
            }
          }
        }
      }
    }
    if (!call.processed) { processCall(call, q) }
  }
  //if(calls[c].callGroupId==1&&calls[c].empId>0){ console.log(calls[c].tostring())}
  return calls

  function processCall(call, q) {
    //DaySchedules relevant for the actual quarter and skill
    let skilledAndWorkingDayScheduls = daySchedulesQuarterBySkillMap.get(call.callGroupId)
    if (skilledAndWorkingDayScheduls && skilledAndWorkingDayScheduls.length > 0) {
      const empDayScheduleWithMaxWait = getDayScheduleWithLowestWait(skilledAndWorkingDayScheduls, call);
      if (empDayScheduleWithMaxWait !== undefined) {
        const diff = (empDayScheduleWithMaxWait.emp.occupiedUntil - call.msSinceEpoch) / 1000;
        if (diff < _maxWaitBeforeAbandonn) {
          call.wait = diff > 0 ? diff : 0;
          call.empId = empDayScheduleWithMaxWait.emp.id;
          empDayScheduleWithMaxWait.emp.occupiedUntil = call.timeStampEnd(adherancePercent, empDayScheduleWithMaxWait.scheduleInfoArray[q])
        } else {
          call.wait = _maxWaitBeforeAbandonn
        }
      } else {
        call.wait = _maxWaitBeforeAbandonn;
      }
    } else {
      call.wait = _maxWaitBeforeAbandonn
    }
    call.processed = true
    //console.log(call.tostring());
  }


  /**
   * @param {{emp:{id:number, skills:number[], occupiedUntil: number}, scheduleInfoArray:number[]}[]} skilledAndWorkingDayScheduls
   * @param {dnSimModel.Call} call
   */
  function getDayScheduleWithLowestWait(skilledAndWorkingDayScheduls, call) {
    let maxDiff = Number.MAX_VALUE;
    let indexFound = -1
    for (let ex = 0; ex < skilledAndWorkingDayScheduls.length; ex++) {
      let diff = skilledAndWorkingDayScheduls[ex].emp.occupiedUntil - call.msSinceEpoch
      if (maxDiff === null || diff < maxDiff) {
        indexFound = ex
        maxDiff = diff
      }
    }

    //To be discussed:If I understand this correctly the routing of calls is defined so that the work is spread out evenly. Perhaps alternative one could stop and give the call to the first how has no wait time.
    return skilledAndWorkingDayScheduls[indexFound]
  }

}
/**
 * @param {dnSimModel.SimData} simDay
 * @param {dnSimModel.Call[]} calls
 * @param {Date} simSt
 * @param {number} numberOfIterations
 * @param {{emp:{id:number}, scheduleInfoArray:number[]}[]} daySchedule
 */
function addSimData(simDay, calls, simSt, numberOfIterations, daySchedule) {
  const length = simDay.length;
  const msPerIndex = 1000 * 60 * 15;
  const msTot = msPerIndex * length;

  //Prepare wait&ht data
  for (let c = 0; c < calls.length; c++) {
    const iCallStart = Math.floor(length * (calls[c].msSinceEpoch - simSt.getTime()) / msTot);
    simDay.waitMap.get(0)[iCallStart].push(calls[c].wait)
    simDay.waitMap.get(calls[c].callGroupId)[iCallStart].push(calls[c].wait)
    simDay.waitTotalMap.get(0).push(calls[c].wait)
    simDay.waitTotalMap.get(calls[c].callGroupId).push(calls[c].wait)
    if (calls[c].empId) {
      let ixTalkStart = length * (calls[c].msSinceEpoch + calls[c].wait * 1000 - simSt.getTime()) / msTot
      let iTalkStart = Math.floor(ixTalkStart)
      let x = calls[c].ht
      //long calls are distributed over several periods
      do {
        let xPart = 0
        if (x < (iTalkStart + 1 - ixTalkStart) * msPerIndex) { xPart = x } else { xPart = (iTalkStart + 1 - ixTalkStart) * msPerIndex }
        x = x - xPart
        if (iTalkStart < length) { simDay.occupancyEmpMap.get(calls[c].empId)[iTalkStart] += xPart / numberOfIterations }
        iTalkStart += 1
        ixTalkStart = iTalkStart
      } while (x > 0);
    }
  }

  //set zero occupancy if no work
  for (let ex = 0; ex < daySchedule.length; ex++) {
    let occupArray = simDay.occupancyEmpMap.get(daySchedule[ex].emp.id)
    let workArray = daySchedule[ex].scheduleInfoArray
    for (let q = 0; q < length; q++) {
      if (workArray[q] == 0) { occupArray[q] = 0 }
    }
  }
}


/**
 * @param {{callGroups:import("@/model/dn-callgroup.js").CallGroup[];emps:{id:number}[];daySchedule:any[]}} params
 * @param {dnSimModel.SimData} simDay
 */
function getSimulationResult(params, simDay) {
  const callGroups = params.callGroups;
  const emps = params.emps;
  const numberOfDayIntervals = simDay.length;
  const numberOfSecondsPerInterval = 900;
  //if(!callGroups){return simDay}
  let callGroupIds = callGroupIdsWith0(callGroups)

  //Sl total
  for (let a = 0; a < callGroupIds.length; a++) {
    let slWithin = (a == 0) ? getAvgSlSetting(callGroups).slWithin : callGroups[a - 1].slWithin
    let waitsData = simDay.waitTotalMap.get(callGroupIds[a])
    if (waitsData.length > 0) {
      if (waitsData.filter(d => d <= slWithin).length > 0) {
        simDay.slTotalMap.set(callGroupIds[a], Math.round(100 * waitsData.filter(d => d <= slWithin).length / waitsData.length))
      }
    }


    //Sl and waitqueue
    for (let i = 0; i < numberOfDayIntervals; i++) {
      let waitsData2 = simDay.waitMap.get(callGroupIds[a])[i].sort(function (x, y) { return x - y });
      if (waitsData2.length > 0) {
        if (waitsData2.filter(d => d <= slWithin).length > 0) {
          simDay.slMap.get(callGroupIds[a])[i] = Math.round(100 * waitsData2.filter(d => d <= slWithin).length / waitsData2.length)
        }

        simDay.queueAvgMap.get(callGroupIds[a])[i] = waitsData2.reduce((a, b) => a + b, 0) / (_numberOfSecondsPerDay / numberOfDayIntervals);
        simDay.waitWithinMap.get(callGroupIds[a])[i] = waitsData2[Math.round(waitsData2.length * 80 / 100)];
      }
      else {
        simDay.slMap.get(callGroupIds[a])[i] = null//if forcast is zero
      }
    }

    //Zero coverage NOT reported on total or department level
    if(a!=0){
      let slArray = simDay.slMap.get(callGroupIds[a])
      if (slArray.includes(0)) {
        for (let i = 0; i < slArray.length; i++) {
          if (slArray[i] == 0) {
            if (params.daySchedule.filter(d => d.emp.skills.includes(callGroupIds[a]) && d.scheduleInfoArray[i] > 0).length == 0) {
              slArray[i] = -1
            }
          }
        }
      }
    }
  }

  //Occupancy data
  let occData = simDay.occupancyEmpMap.get(0)
  for (let e = 0; e < emps.length; e++) {
    let occEmpData = simDay.occupancyEmpMap.get(emps[e].id)
    for (let i = 0; i < numberOfDayIntervals; i++) {//calls over interval border
      if (occEmpData[i] > numberOfSecondsPerInterval && i < numberOfDayIntervals - 1) {
        //console.log((emps[e].id + '-'+ occEmpData[i]))
        occEmpData[i + 1] += occEmpData[i] - numberOfSecondsPerInterval
        occEmpData[i] = numberOfSecondsPerInterval
      }
      occData[i] += occEmpData[i]
    }
  }

  return simDay
}

/** 
 * @typedef {{ acgrid: number|null, affinity: number|null, name: string, slMin: number, slAvg: number, slMax: number, slPeneltyScore: number, slData: any[],
 * slSetting: { slPercent: number, slWithin: number, min: number, max: number } }} ServicelevelDisplayData
 */

/**
 * @param {number|null} affinity
 * @param {string | number | Date} stDt
 * @param {number} numberOfDays
 * @param {SimState} simState
 */
function getServicelevelDisplayData(affinity, stDt, numberOfDays, simState) {

  const callGroups = simState.callGroups;
  let callGroupIds = callGroupIdsWith0(callGroups)
  /** @type {ServicelevelDisplayData[]} */
  const returnArray = [];
  let displayResolutionFactor = getDisplayResolutionFactor(numberOfDays)

  returnArray.push({ acgrid: null, affinity, name: "Service level", slMin: 0, slAvg: 0, slMax: 0,slPeneltyScore:0, slData: [], slSetting: getAvgSlSetting(callGroups) })
  for (let a = 0; a < callGroups.length; a++) {
    returnArray.push({
      acgrid: callGroups[a].id, affinity, name: callGroups[a].name.slice(0, 10), slMin: 0, slAvg: 0, slMax: 0,slPeneltyScore:0, slData: [],
      slSetting: { slPercent: callGroups[a].slPercent, slWithin: callGroups[a].slWithin, min: callGroups[a].slPercent - 30, max: 100 }
    })
  }

  let dt = new Date
  for (let d = 0; d < numberOfDays; d++) {
    dt = addDays(new Date(stDt), d); //eg:Wed Apr 01 2020 00:00:00 GMT+0200 (centraleuropeisk sommartid)
    let dtKey = getShortDate(dt);
    const key = getSimulationDataKey(dtKey, simState.affinity);
    const simDay = simulationData.simulationDataMap.get(key)
    for (let a = 0; a < callGroupIds.length; a++) {
      returnArray[a].slData = returnArray[a].slData.concat(simDay.slMap.get(callGroupIds[a]))
      //returnArray[a].slData = returnArray[a].slData.concat(condence(simDay.slMap.get(callGroupIds[a]), displayResolutionFactor))
      returnArray[a].slAvg += Math.round(simDay.slTotalMap.get(callGroupIds[a]))
    }
  }
  //console.log(returnArray);

  if(numberOfDays>0){
  for (let a = 0; a < callGroupIds.length; a++) {
    returnArray[a].slMin = Math.min(...returnArray[a].slData.filter(d =>d!==null ))
    returnArray[a].slMax = Math.max(...returnArray[a].slData)
    returnArray[a].slAvg = (numberOfDays == 0) ? 0 : Math.round(returnArray[a].slAvg / numberOfDays)
    returnArray[a].slPeneltyScore = (returnArray[a].slData.filter(d =>d==-1 ).length*10+returnArray[a].slData.filter(d =>d===0 ).length*5+returnArray[a].slData.filter(d =>d>0&&d<returnArray[a].slSetting.slWithin*100 ).length/4)/numberOfDays
    //console.log(returnArray[a].name + ' '+ returnArray[a].slPeneltyScore);
  }
  }

  return returnArray
}

/** 
 * @typedef {{ label: string; dt: null; parameterData: number[]; scaleIndex: number; }} ChartData
 */

/**
 * @param {string | number | Date} stDt
 * @param {number} numberOfDays
 * @param {Map<number, Map<string, number[]>>} empWorkMap
 * @param {any[]} emps
 * @param {number} resolution
 * @param {SimState} simState
 */
function getOccupancyAndWorkChartData(stDt, numberOfDays, empWorkMap, emps, resolution, simState) {
  /** @type {ChartData[]} */
  const returnArrayOcc = [];
  /** @type {ChartData[]} */
  const returnArrayWork = []
  let occArray = []
  let workArray = []
  let occWorkArray = []
  let totWork = 0
  let totOccWork = 0
  let totOcc = 0
  let displayResolutionFactor = getDisplayResolutionFactor(numberOfDays)

  let dt = new Date
  for (let d = 0; d < numberOfDays; d++) {
    dt = addDays(new Date(stDt), d); //eg:Wed Apr 01 2020 00:00:00 GMT+0200 (centraleuropeisk sommartid)
    let dtKey = getShortDate(dt);
    let empSumArray = Array(resolution).fill(0)
    const key = getSimulationDataKey(dtKey, simState.affinity);
    const simDay = simulationData.simulationDataMap.get(key);
    for (let e = 0; e < emps.length; e++) {
      let empWork = empWorkMap.get(emps[e].id).get(dtKey)
      let empOccArray = simDay.occupancyEmpMap.get(emps[e].id)
      for (let i = 0; i < resolution; i++) {
        if (empWork[i] == 0) { empOccArray[i] = 0 } //set zero occupancy if no work
        empSumArray[i] += empWork[i]
      }
    }

    occWorkArray = occWorkArray.concat(condence(simDay.occupancyEmpMap.get(0), displayResolutionFactor))
    workArray = workArray.concat(condence(empSumArray, displayResolutionFactor))
  }
  for (let i = 0; i < occWorkArray.length; i++) {
    totOccWork += occWorkArray[i]
    totWork += workArray[i] / 4
    occArray.push(workArray[i] > 0 ? Math.round(100 * occWorkArray[i] / (workArray[i] * (_numberOfSecondsPerDay / resolution))) : 0)
    occWorkArray[i] = occWorkArray[i] / (_numberOfSecondsPerDay / resolution)
  }
  if (totWork > 0) { totOcc = 100 * (totOccWork / (60 * 60)) / totWork }
  returnArrayOcc.push({ label: "Occupancy", dt: null, parameterData: occArray, scaleIndex: 0 })
  returnArrayWork.push({ label: "Work", dt: null, parameterData: workArray, scaleIndex: 0 })
  returnArrayWork.push({ label: "Occ Work", dt: null, parameterData: occWorkArray, scaleIndex: 0 })
  return { occData: returnArrayOcc, workData: returnArrayWork, totWork: totWork, totOcc: totOcc }
}

/**
 * @param {string | number | Date} stDt
 * @param {number} numberOfDays
 * @param {SimState} simState
 */
function getQueueChartData(stDt, numberOfDays, simState) {

  const callGroups = simState.callGroups;
  let callGroupIds = callGroupIdsWith0(callGroups)
  /** @type {ChartData[]} */
  const returnArrayAvg = []
  let queueAvgArray = new Array(callGroupIds.length).fill([])

  let displayResolutionFactor = getDisplayResolutionFactor(numberOfDays)
  let dt = new Date
  for (let d = 0; d < numberOfDays; d++) {
    dt = addDays(new Date(stDt), d); //eg:Wed Apr 01 2020 00:00:00 GMT+0200 (centraleuropeisk sommartid)
    let dtKey = getShortDate(dt);
    const key = getSimulationDataKey(dtKey, simState.affinity);
    let simDay = simulationData.simulationDataMap.get(key)
    for (let a = 0; a < callGroupIds.length; a++) {
      queueAvgArray[a] = queueAvgArray[a].concat(condence(simDay.queueAvgMap.get(callGroupIds[a]), displayResolutionFactor))
    }
  }

  for (let a = 0; a < callGroupIds.length; a++) {
    if (a == 0) {
      returnArrayAvg.push({ label: "All", dt: null, parameterData: queueAvgArray[a], scaleIndex: 0 })
    } else {
      returnArrayAvg.push({ label: callGroups[a - 1].name.slice(0, 10), dt: null, parameterData: queueAvgArray[a], scaleIndex: 0 })
    }
  }
  return returnArrayAvg
}

/**
 * @param {string | number | Date} stDt
 * @param {number} numberOfDays
 * @param {SimState} simState
 */
function getWaitWithinData(stDt, numberOfDays, simState) {
  const callGroups = simState.callGroups;
  let callGroupIds = callGroupIdsWith0(callGroups)
  /** @type {ChartData[]} */
  const returnArray = [];
  let waitWithinArray = new Array(callGroupIds.length).fill([])

  let displayResolutionFactor = getDisplayResolutionFactor(numberOfDays)
  let dt = new Date
  for (let d = 0; d < numberOfDays; d++) {
    dt = addDays(new Date(stDt), d); //eg:Wed Apr 01 2020 00:00:00 GMT+0200 (centraleuropeisk sommartid)
    let dtKey = getShortDate(dt);
    const key = getSimulationDataKey(dtKey, simState.affinity);
    let simDay = simulationData.simulationDataMap.get(key)
    for (let a = 0; a < callGroupIds.length; a++) {
      waitWithinArray[a] = waitWithinArray[a].concat(condence(simDay.waitWithinMap.get(callGroupIds[a]), displayResolutionFactor))
    }
  }

  for (let a = 0; a < callGroupIds.length; a++) {
    if (a == 0) {
      returnArray.push({ label: "All", dt: null, parameterData: waitWithinArray[a], scaleIndex: 0 })
    } else {
      returnArray.push({ label: callGroups[a - 1].name.slice(0, 10), dt: null, parameterData: waitWithinArray[a], scaleIndex: 0 })
    }
  }
  return returnArray
}

/** 
 * @typedef {Map<number, number[]>} EmployeeOccupancyData
 */

/**
 * Get calculated occupancy per employee
 * @param {string | number | Date} stDt
 * @param {number} numberOfDays
 * @param {any[]} emps
 * @param {number} resolution
 * @param {SimState} simState
 */
function getEmployeeOccupancyData(stDt, numberOfDays, emps, resolution, simState) {
  /** @type {EmployeeOccupancyData} */
  const occupancyEmpMap = new Map
  for (let e = 0; e < emps.length; e++) { occupancyEmpMap.set(emps[e].id, []) }
  let displayResolutionFactor = getDisplayResolutionFactor(numberOfDays)

  //data from each day
  let dt = new Date
  for (let d = 0; d < numberOfDays; d++) {
    dt = addDays(new Date(stDt), d); //eg:Wed Apr 01 2020 00:00:00 GMT+0200 (centraleuropeisk sommartid)
    let dtKey = getShortDate(dt);
    const key = getSimulationDataKey(dtKey, simState.affinity);
    let simDay = simulationData.simulationDataMap.get(key);
    for (let e = 0; e < emps.length; e++) {
      let occEmp = occupancyEmpMap.get(emps[e].id)
      occEmp = occEmp.concat(condence(simDay.occupancyEmpMap.get(emps[e].id), displayResolutionFactor))
      occupancyEmpMap.set(emps[e].id, occEmp)
    }
  }

  //Calcualte occupancy from busy seconds
  for (let e = 0; e < emps.length; e++) {
    let occEmp = occupancyEmpMap.get(emps[e].id)
    occEmp = occEmp.map(function (x) { return x / (_numberOfSecondsPerDay / resolution) });
    occupancyEmpMap.set(emps[e].id, occEmp)
  }

  return occupancyEmpMap
}

//HELPER FUNCTIONS
function callGroupIdsWith0(callGroups) {
  return [0].concat(callGroups.map(function (obj) {
    return obj.id;
  }))
}

/**
 * @param {import("@/model/dn-callgroup.js").CallGroup[]} callGroups
 */
function getAvgSlSetting(callGroups) {
  let sumP = 0;
  let sumS = 0;
  for (let a = 0; a < callGroups.length; a++) {
    sumP += callGroups[a].slPercent
    sumS += callGroups[a].slWithin
  }
  if (callGroups.length > 0 && sumP > 0 && sumS > 0) {
    return { slPercent: Math.round(sumP / callGroups.length), slWithin: Math.round(sumS / callGroups.length), min: Math.round(sumP / callGroups.length) - 30, max: 100 }
  } else {
    return { slPercent: 80, slWithin: 20, min: 50, max: 100 }
  }
}

/**
 * @param {Date} simSt
 * @param {{ acgr: import("@/model/dn-callgroup.js").CallGroup; forc: { noc: number[]; ht: number[]; }}[]} dayForcast
 * @param {{ handlingTimePercent: number; handlingTimeVariationPercent: number; callsPercent: number; }} simulationSettings
 */
function generateQuarterCalls(simSt, dayForcast, simulationSettings) {
  /** @type {dnSimModel.Call[]} */
  const returnValue = [];
  if (dayForcast.length === 0) {
    return returnValue;
  }

  const length = dayForcast[0].forc.ht.length;
  for (let q = 0; q < length; q++) {
    for (const df of dayForcast) {
      let aht = df.forc.noc[q] == 0 ? 0 : df.forc.ht[q] / df.forc.noc[q]
      aht = aht * ((100 + simulationSettings.handlingTimePercent) / 100)
      const ht = aht * (1 + (Math.random() - 0.5) * (2 * simulationSettings.handlingTimeVariationPercent / 100))
      for (let i = 0; i < df.forc.noc[q] * (100 + simulationSettings.callsPercent) / 100; i++) {
        const secondsToAdd = q * 15 * 60 + Math.random() * 15 * 60;
        const c = new dnSimModel.Call(simSt.getTime() + secondsToAdd * 1000, ht, df.acgr.id)
        returnValue.push(c)
      }
    }
  }
  return returnValue.sort(function (a, b) { return a.msSinceEpoch - b.msSinceEpoch; });
}

/**
 * @param {number} e
 * @param {number} m
 */
function getErlangB(e, m) {
  let invB = 1;
  for (let i = 1; i <= m; i++) {
    invB = 1 + invB * i / e;
  }
  return 1 / invB;
}

/**
 * @param {number} e
 * @param {number} m
 */
function getErlangC(e, m) {
  const erlB = getErlangB(e, m);
  return m * erlB / (m - e * (1 - erlB));
}

/**
 * @param {number} e
 * @param {number} m
 * @param {number} aht
 * @param {number} slWithin
 */
function getServiceLevel(e, m, aht, slWithin) {
  if (m < e)
    return 0;
  const erlC = getErlangC(e, m);
  const sl = 1 - erlC * Math.exp(-(m - e) * (slWithin / aht));
  if (sl < 0)
    return 0;
  return 100 * sl;
}

/**
 * @param {number} e
 * @param {number} aht
 * @param {number} slWithin
 * @param {number} slPercent
 */
function getErlangCTarget(e, aht, slWithin, slPercent) {
  if (e <= 0 || slPercent <= 0)
    return 0;
  let m = Math.floor(e);
  let sl = 0;
  let slLast = 0;
  while (sl < slPercent) {
    m++;
    slLast = sl;
    sl = getServiceLevel(e, m, aht, slWithin);
  }

  return m - (sl - slPercent) / (sl - slLast);
}

function loadCallGroupPrioInfo(callGroups) {
  let callGroupPrioInfoMap = new Map
  let maxPrioSecondInQueue = 0
  for (let i = 0; i < callGroups.length; i++) {
    if (callGroups[i].prioSecondsInQueue > maxPrioSecondInQueue) { maxPrioSecondInQueue = callGroups[i].prioSecondsInQueue }
    /* if(callGroups[i].id==9||callGroups[i].id==10||callGroups[i].id==12){
      callGroupPrioInfoMap.set(callGroups[i].id,15)
      maxPrioSecondInQueue=60
    } */
    callGroupPrioInfoMap.set(callGroups[i].id, callGroups[i].prioSecondsInQueue)
  }
  simulationData.callGroupPrioInfoMap = callGroupPrioInfoMap
  simulationData.callGroupPrioMax = maxPrioSecondInQueue
}
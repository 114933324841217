<template>
  <div id="app" @keydown.q.ctrl.prevent="reSimulate" :class="[{ 'isdark': currentRoute != 'login'&&isAgentLogin&&isDark }]">
    <Header v-if="currentRoute != 'login'" />
    <div class="tile is-ancestor" >
      <div  :class="[{ 'is-12': !userGuide,'is-9': userGuide}, 'tile','is-vertical']" >
        <div class="section" id="main">
          <router-view></router-view>
        </div>
      </div>

      <Guide />
    </div>
    <Footer v-if="currentRoute != 'login'&&!isLowHeight" />
  </div>
</template>

<script>
import Header from "./components/Navigation/Header.vue";
import Footer from "./components/Navigation/Footer.vue";
import Guide from "./components/Settings/Guide.vue";
import { EventBus } from '@/js/dn-event-bus.js';
import { useDataStore } from "@/stores/dataStore.js";
import { useScheduleStore } from "@/stores/scheduleStore.js";

export default {
  components: {Header,Footer,Guide},
  data() {
    return {
      user: { name: "System", admin: true, isLoggedIn: true },
      selectedAptId: 0,
      isLowHeight:false,
    };
  },
  methods: {
    sizeChange: function () {this.isLowHeight = window.innerHeight < 650 },
    setFirstDayOfWeek() {
      this.$primevue.config.locale.firstDayOfWeek = this.firstDayOfWeek;
    },
    reSimulate() {
      EventBus.emit("resimulate");
    }
  },
  computed: {
    currentRoute() {return this.$route.name;},
    firstDayOfWeek() { return useDataStore().generalEmpAppSettings.weekStartDay; },
    userGuide() { return useDataStore().userGuide.display; },
    isAgentLogin() { 
      const user = useDataStore().currentUser;
      if (user) { return (user.isAgent) }
      else { return (false) }
     },
    isDark(){
      return useScheduleStore().scheduleOptions.agentScheduleDarkView;
    }
  },
  created() {
    useDataStore().loadLanguage();
    useScheduleStore().loadLocalScheduleOptions();
    if (this.firstDayOfWeek !== 0) {
      this.setFirstDayOfWeek();
    }
  },
  watch: {
    firstDayOfWeek() {
      this.setFirstDayOfWeek();
    }
  },
  mounted() {window.addEventListener("resize", this.sizeChange);},
  beforeUnmount() {window.removeEventListener("resize", this.sizeChange);},
};
</script>

<style lang="scss">
@import url("https://use.typekit.net/jqt3jzd.css");
@import "~bulma/sass/utilities/_all";
@import "./assets/css/variables.module.scss";

$primary-light: findLightColor($primary);
$primary-dark: findDarkColor($primary);
$primary-invert: findColorInvert($primary);
$twitter: #4099ff;
$twitter-invert: findColorInvert($twitter);

/* $datepicker-background-color:red;  */

$control-radius: 30px;
$control-radius-small: 30px;

// Lists and maps
$custom-colors: null !default;
$custom-shades: null !default;

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: mergeColorMaps(
  (
    "white": (
      $white,
      $black,
    ),
    "black": (
      $black,
      $white,
    ),
    "light": (
      $light,
      $light-invert,
    ),
    "dark": (
      $dark,
      $dark-invert,
    ),
    "primary": (
      $primary,
      $primary-invert,
      $primary-light,
      $primary-dark,
    ),
    "link": (
      $link,
      $link-invert,
      $link-light,
      $link-dark,
    ),
    "info": (
      $info,
      $info-invert,
      $info-light,
      $info-dark,
    ),
    "success": (
      $success,
      $success-invert,
      $success-light,
      $success-dark,
    ),
    "warning": (
      $warning,
      $warning-invert,
      $warning-light,
      $warning-dark,
    ),
    "danger": (
      $danger,
      $danger-invert,
      $danger-light,
      $danger-dark,
    ),
    "stroke1": ($stroke1, $white),
    "stroke2": ($stroke2, $black),
    "stroke3": ($stroke3, $white),
    "stroke4": ($stroke4, $white),
    "stroke5": ($stroke5, $white),
    "stroke6": ($stroke6, $white),
    "free": ($free, $white),
  ),
  $custom-colors
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

$input-focus-border-color: rgba($black, 0.5);
$input-focus-box-shadow-color: rgba($black, 0.1);
$input-focus-box-shadow-size: 0 0 5px 2px;

$button-focus-border-color: rgba($black, 0.5);
$button-focus-box-shadow-size: 0 0 5px 2px;
$button-focus-box-shadow-color: rgba($black, 0.1);

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";

body {
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
}

#app {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
}
.isdark{
  background: black;
}

body {
  overflow: hidden; /* Hide scrollbars */
}

#main {
  flex-grow: 1;
}




.section {
  padding: 0 1.5rem 2rem 1.5rem;
 
}


.no_highlights {
  -webkit-tap-highlight-color: transparent!important;
  -webkit-touch-callout: none!important;
  -webkit-user-select: none!important;
  -khtml-user-select: none!important;
  -moz-user-select: none!important;
  -ms-user-select: none!important;
  user-select: none!important;
}

@media screen and (max-width: $mobileBreakPoint) {
  .section {
    padding: 0rem 1.5rem 86px 1.5rem;
  }
}
</style>


export class EmployeeContext {
  constructor() {
    /** @private @type {Set<number>} */
    this.highlightedEmpIds = new Set();
    /** @private @type {number[]} */
    this._checkedEmpIds = [];
    /** @private @type {number} */
    this._activeTab = 0;
    /** @type {number} */
    this.previousTab = 0;
    /** @type {string} */
    this.selectedSection = 'general';
    /** @private @type {boolean} */
    this._hasCheckedNotHighlighted = false;
    /** @private @type {boolean} */
    this._hasModifiedData = false;
    /** @private @type {number} */
    this._modifedDataTab = 0;
    /** @type {null|import("@/model/dn-employee.js").Employee} */
    this.selectedEmp = null;
  }

  get activeTab() {
    return this._activeTab;
  }
  set activeTab(value) {
    this.previousTab = this._activeTab;
    this._activeTab = value;
  }

  get checkedEmpIds() {
    return this._checkedEmpIds;
  }
  set checkedEmpIds(value) {
    /** @type {number[]} */
    this.oldCheckedEmpIds = this._checkedEmpIds;
    this._checkedEmpIds = value;
    this.calcHasCheckedNotHighlighted();
  }

  get hasCheckedNotHighlighted() {
    return this._hasCheckedNotHighlighted;
  }

  get hasModifiedData() {
    return this._hasModifiedData;
  }
  set hasModifiedData(value) {
    if (this._hasModifiedData !== value) {
      this._hasModifiedData = value;
      if (value) {
        this._modifedDataTab = this.activeTab;
      }
    }
  }

  get hasModifiedDataInOtherTab() {
    return this.hasModifiedData && this._modifedDataTab !== this.activeTab;
  }

  /** @private */
  calcHasCheckedNotHighlighted() {
    let newValue = false;
    for (const empid of this._checkedEmpIds) {
      if (!this.highlightedEmpIds.has(empid)) {
        newValue = true;
        break;
      }
    }

    if (this._hasCheckedNotHighlighted !== newValue) {
      this._hasCheckedNotHighlighted = newValue;
    }
  }

  /**
   * @param {number} empId
   */
  isHighlighted(empId) {
    return this.highlightedEmpIds.has(empId);
  }

  /**
   * @param {Iterable<number>} empIds
   */
  setHighlighedEmps(empIds) {
    /** @type {Set<number>} */
    this.oldHighlightedEmpIds = this.highlightedEmpIds;
    this.highlightedEmpIds = new Set(empIds);
    this.calcHasCheckedNotHighlighted();
  }

  revertModifiedData() {
    this.hasModifiedData = false;
    if (this.selectedEmp) {
      this.selectedEmp.revert();
    }
  }

  reset() {
    this.selectedEmp = null;
    this._checkedEmpIds = [],
    this.highlightedEmpIds = new Set([]);
  }
}
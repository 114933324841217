// @ts-check
export class Call {
  /**
   * @param {number} msSinceEpoch
   * @param {number} ht
   * @param {number} callGroupId
   */
  constructor(msSinceEpoch, ht, callGroupId) {
    /** @readonly @type {number} */
    this.msSinceEpoch = msSinceEpoch;
    /** @readonly @type {number} */
    this.ht = ht;
    /** @readonly @type {number} */
    this.callGroupId = callGroupId;
    /** @type {null|number} */
    this.empId = null;
    /** @type {boolean} */
    this.processed =false



  }

  get wait() {
    return this._wait
  }
  /**
   * @param {number} newValue
   */
  set wait(newValue) {
    this._wait = newValue
  }

  /**
   * @param {number} adherancePercent
   * @param {number} workFactor
   */
  timeStampEnd(adherancePercent, workFactor = 1) {
    //compensate end of call for workFactor
    if (adherancePercent > 0) {
      workFactor = workFactor * adherancePercent / 100;
    }
    return this.msSinceEpoch + 1000 * (this.wait + this.ht / (workFactor));
  }

  tostring() {
  let str = this.empId > 0 ? (' Answered by: ' + this.empId + ' ht: ' + Math.round(this.ht)) : " ABANDONNED after"
   return 'at ' + this.timeStampEnd(100) + str + ' wait: ' + this.wait + ' call group: ' + this.callGroupId
 }
}

export class SimData {
  /**
   * @param {import("@/model/dn-callgroup.js").CallGroup[]} callGroups
   * @param {{id:number}[]} emps
   * @param {number} length
   */
  constructor(callGroups, emps, length) {
    /** @private @readonly @type {import("@/model/dn-callgroup.js").CallGroup[]} */
    this._callGroups = callGroups;
    /** @readonly @type {number} */
    this.length = length;
    /** 
     * key=callGroupId, values in array is null if no forecast, -1 if no planned.
     * @readonly @type {Map<number, number[]>} 
    */
    this.slMap = new Map // key=callGroupId
    /** @private @readonly @type {Map<number, number>} */
    this._slTotalMap = new Map // key=callGroupId
    this._waitTotalMap = new Map // key=callGroupId
    /** @private @readonly @type {Map<number, number[][]>} */
    this._waitMap = new Map// key=callGroupId
    /** @private @readonly @type {Map<number, any[]>} */
    this._waitWithinMap = new Map// key=callGroupId
    this._queueAvgMap = new Map // key=callGroupId [calls in que],[]
    /** @private @readonly @type {Map<number, number[]>} */
    this._occupancyEmpMap = this.getInitiatedOccupancyMap(emps)//key=empId,value [seconds of work]
    this.initiateValueMap(this.slMap)
    this.initateTotalsValueMap(this._slTotalMap)
    this.initiateArrayMap(this._waitMap)
    this.initateTotalsArrayMap(this._waitTotalMap)
    this.initiateValueMap(this._queueAvgMap)
    this.initiateValueMap(this._waitWithinMap)
  }

  get slTotalMap() { return this._slTotalMap }

  get waitTotalMap() { return this._waitTotalMap }

  get waitMap() { return this._waitMap }

  get waitWithinMap() { return this._waitWithinMap }

  get queueAvgMap() { return this._queueAvgMap }

  get occupancyEmpMap() { return this._occupancyEmpMap }

  /**
   * @param {number} id
   */
  getCallGroup(id) {
    return this._callGroups.find(x => x.id === id);
  }

  /**
   * @private
   * @param {Map<number, any[]>} xMap
   */
  initiateArrayMap(xMap) {
    let intervalItems = Array(this.length)
    for (let i = 0; i < this.length; i++) { intervalItems[i] = [] }
    xMap.set(0, intervalItems)
    for (let a = 0; a < this._callGroups.length; a++) {
      let intervalItems = Array(this.length)
      for (let i = 0; i < this.length; i++) { intervalItems[i] = [] }
      xMap.set(this._callGroups[a].id, intervalItems)
    }
  }

  /**
   * @private
   * @param {Map<any, any>} xMap
   */
  initiateValueMap(xMap) {
    let intervalItems = Array(this.length)//key0 contains total
    for (let i = 0; i < this.length; i++) { intervalItems[i] = 0 }
    xMap.set(0, intervalItems)
    for (let a = 0; a < this._callGroups.length; a++) {
      let intervalItems = Array(this.length)
      for (let i = 0; i < this.length; i++) { intervalItems[i] = 0 }
      xMap.set(this._callGroups[a].id, intervalItems)
    }
  }

  /**
   * @private
   * @param {Map<any, any>} xMap
   */
  initateTotalsArrayMap(xMap) {
    xMap.set(0, [])//key0 contains total
    for (let a = 0; a < this._callGroups.length; a++) {
      xMap.set(this._callGroups[a].id, [])
    }
  }

  /**
   * @private
   * @param {Map<number, number>} xMap
   */
  initateTotalsValueMap(xMap) {
    xMap.set(0, 0)//key0 contains total
    for (let a = 0; a < this._callGroups.length; a++) {
      xMap.set(this._callGroups[a].id, 0)
    }
  }

  /**
   * @private
   * @param {{id:number}[]} emps
   */
  getInitiatedOccupancyMap(emps) {
    /** @type {Map<number, number[]>} */
    const xMap = new Map //key0 contains total all emps
    let intervalItems = Array(this.length)
    for (let i = 0; i < this.length; i++) { intervalItems[i] = 0 }
    xMap.set(0, intervalItems)

    for (let e = 0; e < emps.length; e++) {
      let intervalItems = Array(this.length)
      for (let i = 0; i < this.length; i++) { intervalItems[i] = 0 }
      xMap.set(emps[e].id, intervalItems)
    }
    return xMap
  }
}

import { defineStore } from 'pinia';
import { useDataStore } from './dataStore';

export const useReportStore = defineStore('report', {
  state: () => ({
    reportOptions: {
      includePaidBreaks: false,
      joinCodes: false,
      /** @type {number} */
      numHoursWeeklyOvertime: null,
      reportCalendarDays: false,
      reportCsvSemicolon: false,
      reportDatePicker: new Date(0),
      /** @type {number[]} */
      reportFilter:[],
      reportKind: 0,
      reportTimeRangePicker: [],
      selectedEmp: null,
      selectedTasks: new Set(),
      selectedTasktype:null,
      separateColumns: false,
      weekendAsHoliday: false,
    },
  }),
  actions: {
    toggleSelectedTask(task){
      if(!task.checked) {
        this.reportOptions.selectedTasks.add(task.id)
      }
      else{
        this.reportOptions.selectedTasks.delete(task.id)
      }
    },
    selectedTasktoString(){
      if (this.reportOptions.selectedTasks.size == 0) {
        return "No tasks selected"
      }
      let thisTaskname = "";
      let stringArray = []
      let taskTypesMap = useDataStore().taskTypes.byId
      for (const taskTypeID of this.reportOptions.selectedTasks) {
        thisTaskname = taskTypesMap.get(taskTypeID).name
        stringArray.push(thisTaskname)
      }

      return stringArray.join(", ");
    }
  }
});

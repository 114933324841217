<template>
  <div class="navbar-wrapper" id="application-header">
    <div @click="layOutChange" >
    <b-message v-if="showExpireWarning" :title=expireMessage type="is-warning"></b-message>
    <b-message v-if="refreshedDueToError" :title="$t('navigation.refreshed-due-to-error-msg')" type="is-success" />
    <b-message v-if="showSettings&&relevantInvoiceStatus==1" :title="$t('navigation.new-invoice-long')" type="is-success" />
    <b-message v-if="showSettings&&relevantInvoiceStatus==3" :title="$t('navigation.unpaid-invoice-long')" type="is-warning" />
    <b-message v-if="showSettings&&relevantInvoiceStatus==4" :title="$t('navigation.overdue-invoice-long')" type="is-danger" />
  </div>
    <div class="container">

      <b-navbar v-if="!isAgentLogin"> 
        <template #brand>
          <b-navbar-item id="brand" tag="router-link" :to="{ path: '/' }">
            <img src="@/assets/simTree_logotext_white_bg.svg" class="no_highlights loggo" />
          </b-navbar-item>
          <b-navbar-item v-if="isNormalLogin" class="mt-3 no_highlights" :active="isPage('portfolio')" @click="goToView('portfolio')">
            {{ $t('navigation.home') }}
          </b-navbar-item>
          <!-- <b-navbar-item v-if="isAgentLogin" class="mt-3 no_highlights" tag="router-link" :to="{ path: '/agenthomescr' }">   tag="router-link" :to="{ path: '/' }"
            Schedule
          </b-navbar-item>
          <b-navbar-item v-if="isAgentLogin" class="mt-3 no_highlights" tag="router-link" :to="{ path: '/trade' }">
            Trade
          </b-navbar-item> -->
          <b-navbar-item v-if="isNormalLogin && !forecastIsAvailable" class="mt-3 no_highlights" >
            {{ $t('navigation.loading') }}...
          </b-navbar-item>
          <b-navbar-item v-if="showForecast" class="mt-3 no_highlights" :active="isPage('forecast')" @click="goToView('forecast')">
            {{ $t('navigation.forecast') }}
          </b-navbar-item>
          <b-navbar-item v-if="showSchedule" class="mt-3 no_highlights" :active="isPage('schedule')" @click="goToView('schedule')">
            {{ $t('navigation.schedule') }}
          </b-navbar-item>
          <b-navbar-item v-if="showSchedule"  class="mt-3 no_highlights" :active="isPage('employees')" @click="goToView('employees')" >
            {{ $t('navigation.employees') }}
          </b-navbar-item>
          <b-navbar-item v-if="showReport" class="mt-3 no_highlights" :active="isPage('report')" @click="goToView('report')" >
            {{ $t('navigation.report') }}
          </b-navbar-item>
          <b-navbar-item v-if="showSettings" class="mt-3 no_highlights" :active="isPage('settings')" @click="goToView('settings')" >
            {{ $t('navigation.settings') }}
          </b-navbar-item>
        </template>

        <template #end>
          <div @click="checkInvoiceStatus">
          <p v-if="showSettings&&relevantInvoiceStatus==1" class="is-size-6 no_highlights invoice-info invoice-color-info" 
          v-tooltip="$t('navigation.new-invoice-long')" >{{ $t('navigation.new-invoice') }}</p>
          <p v-if="showSettings&&relevantInvoiceStatus==3" class="is-size-6 no_highlights invoice-info invoice-color-due" 
          v-tooltip="$t('navigation.unpaid-invoice-long')" >{{ $t('navigation.unpaid-invoice') }}</p>
          <p v-if="showSettings&&relevantInvoiceStatus==4" class="is-size-6 no_highlights invoice-info invoice-color-overdue" 
          v-tooltip="$t('navigation.overdue-invoice-long')" >{{ $t('navigation.overdue-invoice') }}</p>
        </div>


          <b-tooltip
            label="supervisor authority: readonly of non published schedules"
            position="is-bottom"
          >
            <p v-if="supervisorInfo" class="is-size-6 supervior-info">{{ $t('navigation.published-only') }}</p>
          </b-tooltip>

          <i v-if="showCurrentTimezone" class="pi pi-clock" style="margin-top: 20px;margin-right: 12px;" v-tooltip.bottom=currentTimezone></i>
          
          <i v-if="showEmpFilter" class="pi pi-filter" style="margin-top: 20px;margin-right: 4px;" v-tooltip.bottom="$t('schedule.filter-tt')" @click="scheduleFilter.show=!scheduleFilter.show"></i>
          <p v-if="showEmpFilter" class="is-size-6 filter-info">{{ scheduleFilter.filterMsg }}</p>
     

          <p v-if="showReadOnlyInfo" class="is-size-6 supervior-info">{{ $t('navigation.read-only') }}</p>
          
          <p  v-if="showAffintyDropDown" class="affinity-info">
              <b-dropdown v-model="scheduleFilter.affinity" aria-role="list" style="margin-top:10px" :max-height="600" class="dropdown-botton">
                <template #trigger
                >
                <b-button icon-right="menu-down" style="min-width:100px"  >
                  {{ getAffinityText() }}
                </b-button>
                </template>
                <b-dropdown-item
                @click="filterChange"
                  aria-role="listitem"
                  class="dropdown-item"
                  v-for="(affinity, index) in affinitySelection()"
                  :key="index"
                  :value="affinity.id"
                >
                  <span class="dropdown-align">
                    <p class="indicatorText">{{ affinity.name }}</p>
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </p>
            <p v-if="isCurrentCCSelected" class="is-size-6" style="margin-top:19px; margin-left:50px;white-space: nowrap;">{{ currentCC.name }}</p>
          
          
          
          <p v-if="isCurrentCCSelected" class="mt-3 mr-1 no_highlights"></p>
          
          

          <b-navbar-dropdown class="mt-3  no_highlights" :label="$t('navigation.user')">
            <b-navbar-item v-if="!isSuperAdmin && currentUser !== null && currentUser.hasAccessSettings&&!currentUser.isSupervisor"
              @click="goToView('admin')">
              {{ $t('navigation.admin') }}
            </b-navbar-item>
            <b-navbar-item v-if="!isSuperAdmin && currentUser !== null && currentUser.hasAccessSettings&&!currentUser.isSupervisor"
              @click="goToView('invoice')">
              {{ $t('navigation.invoice') }}
            </b-navbar-item>
            <b-navbar-item v-if="currentUser !== null"  @click="goToView('user')">
              {{ $t('navigation.security-settings') }}
            </b-navbar-item>
            <b-navbar-item v-if="currentUser !== null"  @click="activeSelectAvatar=true">
              {{ $t('navigation.avatar') }}
            </b-navbar-item>
            <b-navbar-item v-if="!isSuperAdmin" @click="userGuide.display = !userGuide.display">
              {{ $t('navigation.user-guide') }}
            </b-navbar-item>
            <b-navbar-item @click="logOut()">
              {{ $t('navigation.log-out') }}
            </b-navbar-item>
                <b-menu-item style="margin-left: 15px; margin-top: 5px">
                <template #label >
                  <span class="dropdown-subitem">{{ $t('navigation.language') }}</span>
                  <b-dropdown aria-role="list" class="is-pulled-right" position="is-bottom-left" v-model="language">
                    <template #trigger>
                      <Icon  style="height: 20px;margin-right: 10px;margin-top: 5px" name="chevron_right" size="12" />
                    </template>
                    <b-dropdown-item v-for="(lang, i) in languages" :key="`lang-${i}`" :value="lang" @click="setLanguage(lang)">
                      {{ lang }}
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-menu-item>

            <b-navbar-item v-if="isPage('portfolio')" @click="clearLocalStorage()">
              {{ $t('navigation.clear-cookies') }}
            </b-navbar-item>
          </b-navbar-dropdown>
        </template>

      </b-navbar>
    </div>
    <!-- <RawData class :data="affinitySelection()" :title="'affinitySelection'" /> -->
    <Modal :active=activeSelectAvatar :title="$t('navigation.avatar-setting')" v-on:close="activeSelectAvatar=false">
        <template v-slot:body>
          <AvatarSelector ref="avatarSelector" :user="currentUser" />
        </template>
        <template v-slot:footer>
          <Button type="secondary" @click="activeSelectAvatar=false">{{ $t('global.cancel') }}</Button>
          <Button type="black-button" @click="okSelectAvatar" >{{ $t('global.ok') }}</Button>
        </template>
    </Modal>
  </div>
</template>

<script>
import RawData from "@/components/Base/RawData.vue";
import AvatarSelector from "@/components/Settings/AvatarSelector.vue";

import { getSupportedCultures, loadLanguageAsync } from '@/i18n.js';
import * as dnLocalStorage from '@/js/dn-localStorage.js';
import * as dn_schedulehelper from '@/js/dn-schedule-helper.js';
import Icon from "@/components/Base/Icon.vue";
import { EventBus } from "@/js/dn-event-bus.js";
import { useDataStore } from "@/stores/dataStore.js";
import { useFileImportStore } from "@/stores/fileImportStore.js";
import { useForecastStore } from "@/stores/forecastStore";
import { useScheduleStore } from "@/stores/scheduleStore.js";
import Modal from "@/components/Base/Modal.vue";
import Button from "@/components/Base/Button.vue";
import { saveUsers } from "@/model/dn-user";

export default {
    // @ts-ignore
    components: {Icon,RawData, Modal,Button,AvatarSelector },
  name: "Header",

  data() {
    return {
      languages: getSupportedCultures(),
      activeSelectAvatar:false

    };
  },
  computed: {
    useMode(){  return useDataStore().getMode  },
    scheduleFilter() { return useScheduleStore().scheduleOptions.scheduleFilter; },
    affinities() { return useDataStore().affinities; },
    hasAffinity() {
        const callGroups = this.callGroups;
        return callGroups.some(x => x.affinity !== null);
      },
    callGroups() { return useDataStore().skillsCurrentCC; },
    currentCC() { return useDataStore().currentCC; },
    currentUser() { return useDataStore().currentUser; },
    currentTimezone() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    },
    showCurrentTimezone() {
      /** @type {string} */
      const routeName = this.$route.name;
      return routeName !== 'employees' && routeName !== 'settings';
    },
    employeeContext() { return useScheduleStore().employeeContext; },
    language: {
      get() {
        return useDataStore().language;
      },
      /**
      * @param {string} lang
      */
      set(lang) {
        useDataStore().language = lang;
      }
    },
    forecastIsAvailable() { return useForecastStore().forecastOptions.forecastIsLoaded; },
    refreshedDueToError() { return useDataStore().refreshedDueToError; },
    userGuide() { return useDataStore().userGuide; },
    showAffintyDropDown: function(){
      return (this.isPage('schedule')||(this.isPage('employees')&&this.employeeContext.activeTab==1))&&this.hasAffinity && this.affinities
    },
    showEmpFilter(){
      return this.isPage('schedule')||this.isPage('employees')
    },
    isAgentLogin: function () {
      return this.currentUser != null && this.currentUser.isAgent;
    },
    isCurrentCCSelected: function () {
      return this.currentCC != null;
    },

    isNormalLogin: function () {
      return this.currentUser != null && !this.currentUser.isAgent && !this.currentUser.isSuperAdmin;
    },
    isSuperAdmin: function () {
      return this.currentUser != null && this.currentUser.isSuperAdmin;
    },
    showForecast() {
      return this.forecastIsAvailable && this.currentUser !== null && this.currentUser.hasAccessForecasting && this.useMode == 0;
    },
    showReport() {
      return this.forecastIsAvailable && this.currentUser !== null && this.currentUser.hasAccessReports && this.isCurrentCCSelected;
    },
    showSchedule() {
      return this.forecastIsAvailable && this.currentUser !== null && this.currentUser.hasAccessScheduling && this.isCurrentCCSelected;
    },
    supervisorInfo(){
      if (this.isPage('schedule')) {
        return this.currentUser !== null && (this.currentUser.isSupervisor)
      }
    },
    showReadOnlyInfo() {
      if (this.isPage('schedule')) {
        return this.currentUser !== null && (!this.currentUser.canEditScheduling && this.isCurrentCCSelected)
      } else if (this.isPage('settings')) {
        return this.currentUser !== null && (!this.currentUser.canEditSettings)
      } else if (this.isPage('forecast')) {
        return this.currentUser !== null && (!this.currentUser.canEditForecasting)
      }
      return false;
    },
    showSettings() {
      return this.forecastIsAvailable && this.currentUser !== null && this.currentUser.hasAccessSettings;
    },
    fileImportInfo() { return useFileImportStore().fileImportInfo; },
    scheduleNeedsSave() { return useScheduleStore().scheduleNeedsToSave; },
    showExpireWarning() {
      return this.currentUser != null && this.currentUser.endTime !== null;
    },
    relevantInvoiceStatus(){return useDataStore().relevantInvoiceStatus},
   
    expireMessage() {
      return this.$t('navigation.program-will-stop-working') + `${this.currentUser.endTime}.`;
    }
  },
  methods: {
    checkInvoiceStatus(){     
      useDataStore().loadRelevantInvoiceStatus()},
    layOutChange(){
      setTimeout(() => {
        EventBus.emit('scheduleLayoutChange');
    }, 500);
  },
    okSelectAvatar(){   
      this.currentUser.initials=this.$refs.avatarSelector.initials
      this.currentUser.colorId=this.$refs.avatarSelector.colorId
      saveUsers([this.currentUser])
      this.activeSelectAvatar=false
    },
    filterChange(){
        EventBus.emit("simulationChange");
        EventBus.emit("schedulefilterchange")
      },
    affinitySelection() {
        const sel = [{id:null, name:'All departments'}];
        const callGroups = this.callGroups;
        const affinities = this.affinities;

        /** @type {Set<number>} */
        const usedAffinities = new Set();
        for (const cg of callGroups) {
          if (cg.affinity !== null) {
            usedAffinities.add(cg.affinity);
          }
        }
        for (const a of affinities) {
          if (usedAffinities.has(a.id)) {
            sel.push(a);
          }
        }
        return sel;
      },
    getAffinityText() {
      const id = this.scheduleFilter.affinity;
      if (id === null) {
        return "All departments";
      }

      /** @type {import("@/model/dn-affinity.js").Affinity[]} */
      const affinities = this.affinities;
      const affinity = affinities.find(x => x.id === id);
      if (affinity)
        return affinity.name;
      return id;
    },
    /**
    * @param {string} lang
    */
    setLanguage(lang){
      dnLocalStorage.setLanguage(lang);
      loadLanguageAsync(lang);
      EventBus.emit('calculateReport');
    },
    async logOut() {
      const dataStore = useDataStore();
      this.userGuide.display = false
      dn_schedulehelper.saveLocalScheduleSettings()
      try {
        if (this.scheduleNeedsSave) {
          this.$buefy.dialog.confirm({
            message: `${this.$t('global.unsaved-schedule')}` ,
            onConfirm: () => {
              dataStore.logOut();
              this.$router.push({ name: "login" });
            }
          })
        } else {
          dataStore.logOut();
          this.$router.push({ name: "login" });
        }
      } catch (e) {
        console.log(e);
      }
    },
    isPage: function (page) {
      return this.$route.name == page;
    },
    clearLocalStorage: function () {
      dnLocalStorage.clearLocalStorage()
    },
    goToView(name) {
      if (this.$route.name !== name) {
        /** @type {import("@/model/dn-employee-context.js").EmployeeContext} */
        const employeeContext = this.employeeContext;
        if (employeeContext.hasModifiedData) {
          this.$buefy.dialog.confirm({
            message: `${this.$t('global.discard-changes')}`,
            cancelText: `${this.$t('global.no')}`,
            confirmText: `${this.$t('global.yes')}`,
            onConfirm: () => {
              employeeContext.revertModifiedData();
              this.$router.push({ name: name });
            }
          });
        } else {
          this.$router.push({ name: name });
        }
      }
    },
  },
  watch: {
    fileImportInfo: {
      handler: function () {
        const fileImportInfo = this.fileImportInfo;
        if (fileImportInfo.error) {
          this.$buefy.toast.open({
            message: "File " + this.fileImportInfo.fileName + " has error(s)",
            type: "is-danger",
            position: "is-top",
            duration: 2000,
          });
        }
        else if (fileImportInfo.nofImported > 0) {
          this.$buefy.toast.open({
            message: fileImportInfo.nofImported + " files imported",
            type: "is-white",
            position: "is-top",
            duration: 2000,
          });
        }
        const forecastStore = useForecastStore();
        forecastStore.reloadForecastAndHistoricData();
      },
      deep: true
    },
  },
  created() { },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/variables.module.scss";
@import "@/assets/css/common.module.scss";
.dropdown-botton{
  border-radius: 4px;
  height:22px;
  width:80px;
  margin-left:5px;
  margin-top:5px
}
.supervior-info{
  color:$primary;
  margin-top:19px;
  margin-right:10px;
  min-width: 120px;
}
.filter-info{
  color:$primary;
  margin-top:19px;
  font-size: 12px;
  margin-right:10px;
  min-width: 160px;
}

.invoice-info{
  
  margin-top:19px;
  margin-right:10px;
  min-width: 120px;
}
.invoice-color-info{
  color:$success
}
.invoice-color-due{
  color:$warning
}
.invoice-color-overdue{
  font-weight: 900;
  color:$danger;
}
.affinity-info{
  margin-right:14px;
  min-width: 120px;
}

#brand {
  margin-right: 20px;
  margin-top: 5px;
  padding: 0px;

}

.loggo {
  margin-left: -10px;
  margin-right: -10px;
  padding: 0px;
  width: 180px;
  height: 30px;
}

.navbar-wrapper {
  border-bottom: 1.5px solid $dark;
  margin-bottom: 2rem;

  .container {
    margin: 0 1.5rem;
    margin-right: 160px;
    max-width: 100% !important;
  }

  .navbar {
    align-items: bottom;

    .comp-icon {
      margin-right: 2px;
    }

    .tag {
      margin-left: 0.5rem;
    }
  }
}

.mobile-navbar {
  display: none;
}

@media screen and (max-width: $mobileBreakPoint) {
  :deep(.navbar) {
    display: none;
  }

  .mobile-navbar {
    display: flex;
  }

  .navbar-wrapper {
    background-color: $white;
    position: sticky;
    top: 0;
    z-index: 10;
  }
}

@media screen and (max-width: $desktopBreakPoint) {
  .navbar-brand {
    .search-btn {
      margin-left: auto;
    }

    .navbar-item {
      padding-left: 0;
    }
  }

  :deep(.navbar-burger) {
    margin-left: 0;
  }
}

@media screen and (min-width: $desktopBreakPoint) {
  :deep(.navbar-brand) {
    width: calc(100% - 8rem);
  }

  :deep(.navbar-end) {
    width:300px;
    align-items:right;
  }

  :deep(.navbar-menu) {
    flex-grow: 0;
  }

  :deep(.navbar-dropdown) {
    border-top: 1px solid #dbdbdb;
    left: auto;
    right: 0;
  }

  .dropdown-subitem{
  color:$dark;
  font-size:15px;
}
:deep(li)
{
    list-style-type: none;
}

}</style>

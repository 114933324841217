import { createRouter, createWebHistory, createWebHashHistory  } from "vue-router";
import { getLoginInfo } from '@/js/dn-localStorage.js';
import { externalLogin, updateAccessToken } from "./js/dn-fetch";
import { useDataStore } from "@/stores/dataStore.js";
import { useForecastStore } from "@/stores/forecastStore.js";
import { useScheduleStore } from "@/stores/scheduleStore.js";
import { resetStores } from "@/stores/dn-store";

const routes = [
  {
    path: "/",
    name: "portfolio",
    component: () => import("./views/Portfolio.vue"),
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("./views/AdminView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./views/Login.vue"),
  },
  {
    path: "/forecast",
    name: "forecast",
    component: () => import("./views/Forecast.vue"),
  },
  {
    path: "/schedule",
    name: "schedule",
    component: () => import("./views/Schedule.vue"),
  },
  {
    path: "/report",
    name: "report",
    component: () => import("./views/Report.vue"),
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("./views/Settings.vue"),
  },
  {
    path: "/agenthomescr",
    name: "agenthomescr",
    component: () => import("./views/AgentHomeScr.vue"),
  },
  {
    path: "/trade",
    name: "trade",
    component: () => import("./views/AgentTrade.vue"),
  },
  {
    path: "/super-admin",
    name: "super-admin",
    component: () => import("./views/SuperAdminView.vue"),
  },
  {
    path: "/employees",
    name: "employees",
    component: () => import("./views/Employees.vue"),
  },
  {
    path: "/invoice",
    name: "invoice",
    component: () => import("./views/Invoice.vue"),
  },
  {
    path: "/team-schedule",
    name: "team-schedule",
    component: () => import("./views/AgentTeamSchedule.vue"),
  },
  {
    path: "/agent-stats",
    name: "agent-stats",
    component: () => import("./views/AgentStats.vue"),
  },
  {
    path: "/user",
    name: "user",
    component: () => import("./views/User.vue"),
  }
];

export function createAppRouter() {
  const router = createRouter({
    history: createWebHashHistory(),
    routes,
  });

  router.beforeEach((to, from, next) => {
    const externalPath = '/external/';
    if (to.path.startsWith(externalPath)) {
      const afterExternalLogin = (isAgent) => {
        if (isAgent === true) {
          next({ name: "agenthomescr" });
        } else if (isAgent === false) {
          next({ name: "" });
        }
        else {
          next({ name: "login" })
        }
      };
      const key = to.path.slice(externalPath.length);
      externalLogin(key, afterExternalLogin);
      return;
    }
    const loginInfo = getLoginInfo();
    if (loginInfo === null && to.name !== "login") {
      next({ name: "login" });
    }
    else if (from.name === "login") {
      init();
      next();
    }
    else if (to.name === "login") {
      if (loginInfo === null) {
        resetStores();
        next();
      } else if (loginInfo.isAgent) {
        next({ name: "agenthomescr" });
      } else if (loginInfo.isSuperAdmin) {
        next({ name: "super-admin" });
      } else {
        next({ name: "portfolio" });
      }
    }
    else if (to.name === "portfolio" && loginInfo.isAgent) {
      next({ name: "agenthomescr" });
    }
    else if (to.name === "portfolio" && loginInfo.isSuperAdmin) {
      next({ name: "super-admin" });
    }
    else {
      const dataStore = useDataStore();
      if (!dataStore.currentUser) { initUser() };
      next();
    }
  }
  );

  router.afterEach((to, from) => {
    if (from.name === null) {
      if (hasRefreshed()) {
        const dataStore = useDataStore();
        dataStore.refreshedDueToError = true;
      }
      
    } 
    else {
        if (from.name === 'settings'){
          const dataStore = useDataStore();
          dataStore.loadRelevantInvoiceStatus()}
      }
      if (hasRefreshed()) {
        window.sessionStorage.setItem('refreshed-due-to-error', 'false');
        const dataStore = useDataStore();
        dataStore.refreshedDueToError = false;   
    }


    
    if(hasNotCheckedInvoiceStatus()){
      useDataStore().loadRelevantInvoiceStatus
    }
  })

  router.onError(err => {
    if (err.name === 'ChunkLoadError') {
      if (!hasRefreshed()) {
        window.sessionStorage.setItem('refreshed-due-to-error', 'true');
        window.location.reload();
      }
    }
  })

  return router;
}

function hasRefreshed() {
  return window.sessionStorage.getItem('refreshed-due-to-error') === 'true';
}

function hasNotCheckedInvoiceStatus() {
  return ((new Date().getTime()-useDataStore().lastRelevantInvoiceStatuscheck.getTime())>1000*60*60*24)
}

async function initUser() {
  const loginInfo = getLoginInfo();
  if (loginInfo.needRefresh())
    await updateAccessToken(loginInfo);

  const dataStore = useDataStore();
  await dataStore.loadCurrentUser();
  if (!loginInfo.isSuperAdmin)
    init()
}

async function init() {
  const loginInfo = getLoginInfo();
  if (loginInfo.isSuperAdmin)
    return;
  const dataStore = useDataStore();
  await dataStore.init();
  dataStore.loadDateNow();
  const scheduleStore = useScheduleStore();
  const forecastStore = useForecastStore();
  forecastStore.forecastOptions.forecastIsLoaded = false;
  scheduleStore.loadLocalAgentScheduleOptions();
  scheduleStore.loadSimulationSettings();
  dataStore.loadGeneralSettings();
  dataStore.loadAffinities();
  dataStore.loadCallCenters().then(() => {
    scheduleStore.loadCurrentCCData();
  });
  dataStore.loadHolidays();
  
  dataStore.loadRelevantInvoiceStatus();
  dataStore.agreements.load();
  if (!loginInfo.isAgent) {
    
    forecastStore.initForecast();
    dataStore.loadTagUsage();
  }
}
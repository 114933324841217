import { fetchAndCheckJson } from '@/js/dn-fetch.js';
import { fromISODate, toISODate } from '@/js/dn-helper';

function createSettingsColumnsEnum() {
  const enumObject = {
    delete: 1,
    number: 2,
    date: 3,
    department: 4,
    cc: 5,
    text: 6,
    payrollRuleKind: 7,
    payrollRuleDayKind: 8,
    payrollRuleWeekLimit: 9,
    payrollTaskType: 10,
    timeOfDay: 11,
  };

  return Object.freeze(enumObject);
}

export const SETTINGS_COLUMN_KIND = createSettingsColumnsEnum();

function createSettingsDetailEnum() {
  const enumObject = {
    breakSetting: 1,
    callGroup: 2,
    bo: 3,
  };

  return Object.freeze(enumObject);
}

export const SETTINGS_DETAIL_KIND = createSettingsDetailEnum();

/**
* @typedef {{key:string; value:number}} SettingDto
*/

export async function getSettings() {
  /** @type {SettingDto[]} */
  const rows = await fetchAndCheckJson(`settings`, 'GET');
  return rows;
}

/**
 * @param {SettingDto[]} data
 */
export async function postSettings(data) {
  if (data.length === 0) { return; }
  await fetchAndCheckJson(`settings`, 'POST', data);
}

/**
 * @param {boolean} value
 */
export async function saveReportCalendarDays(value) {
  await postSettings([{ key: GENERAL_SETTINGS.reportCalendarDays, value: value ? 1 : 0 }])
}

/**
 * @param {boolean} value
 */
export async function saveReportCsvSemicolon(value) {
  await postSettings([{ key: GENERAL_SETTINGS.reportCsvSemicolon, value: value ? 1 : 0 }])
}

/**
 * @param {number} value
 */
export async function savenumHoursWeeklyOvertime(value) {
  await postSettings([{ key: GENERAL_SETTINGS.numHoursWeeklyOvertime, value: value}])
}

/**
 * @param {boolean} value
 */
export async function saveWeekendAsHoliday(value) {
  await postSettings([{ key: GENERAL_SETTINGS.weekendAsHoliday, value: value ? 1 : 0}])
}

export const GENERAL_SETTINGS = createGeneralSettingsEnum();

function createGeneralSettingsEnum() {
  const enumObject = {
    historicWeeks: 'historicWeeks',
    forecastWeeks: 'forecastWeeks',
    numberOfHistoricSpecialDays: 'numberOfHistoricSpecialDays',
    occupacyFte: 'occupacyFte',
    adherancePercent: 'adherancePercent',
    percentOfAbandonsToInclude: 'percentOfAbandonsToInclude',
    adherenceBase: 'adherenceBase',
    graceMinutes: 'graceMinutes',
    adherenceLimit: 'adherenceLimit',
    closeGaps: 'closeGaps',
    reportCalendarDays: 'reportCalendarDays',
    reportCsvSemicolon:'reportCsvSemicolon',
    unScheduledBreakMinutes:'unScheduledBreakMinutes',
    numHoursWeeklyOvertime: 'numHoursWeeklyOvertime',
    weekendAsHoliday: 'weekendAsHoliday'
  };

  return Object.freeze(enumObject);
}

export const GENERAL_EMPAPP_SETTINGS = createGeneralEmpSettingsEnum();
/* ADDED ALLREQUESTSDISABLED */
function createGeneralEmpSettingsEnum() {
  const enumObject = {
    publishingWeekDay: 'publishingWeekDay',
    publishingNumberOfDays: 'publishingNumberOfDays',
    publishingFixedDateMs: 'publishingFixedDateMs',
    allRequestsDisabled: 'allRequestsDisabled',
    requestClosedNumberOfDays: 'requestClosedNumberOfDays',
    requestvaccationClosedNumberOfDays: 'requestvaccationClosedNumberOfDays',
    sickRequestDisabled: 'sickRequestDisabled',
    tradeDisabled: 'tradeDisabled',
    hideTeamSchedule: 'hideTeamSchedule',
    showOnlyOwnTeam: 'showOnlyOwnTeam',
    weekStartDay: 'weekStartDay',
    statsPayrollCodes:'statsPayrollCodes',
    statsAdherence:'statsAdherence',
    statsDetailedAdherence:'statsDetailedAdherence',
    statsPayrollCodesPublishingNumberOfDays:'statsPayrollCodesPublishingNumberOfDays',
  };

  return Object.freeze(enumObject);
}

export class GeneralOptions {
  constructor(forecastSetting, adherenceSetting, historicWeeks, forecastWeeks, numberOfHistoricSpecialDays, occupacyFte, 
              adherancePercent, percentOfAbandonsToInclude, adherenceBase, graceMinutes, unScheduledBreakMinutes, 
              adherenceLimit, numHoursWeeklyOvertime, weekendAsHoliday, closeGaps) {
    if (forecastSetting && adherenceSetting) {
      this._historicWeeks = forecastSetting.historicWeeks
      this._forecastWeeks = forecastSetting.forecastWeeks
      this._numberOfHistoricSpecialDays = forecastSetting.numberOfHistoricSpecialDays
      this._occupacyFte = forecastSetting.occupacyFte
      this._adherancePercent = forecastSetting.adherancePercent
      this._percentOfAbandonsToInclude = forecastSetting.percentOfAbandonsToInclude
      this._adherenceBase = adherenceSetting.adherenceBase
      this._graceMinutes = adherenceSetting.graceMinutes
      this._unScheduledBreakMinutes = adherenceSetting.unScheduledBreakMinutes
      this._closeGaps = adherenceSetting.closeGaps
      this._adherenceLimit = adherenceSetting.adherenceLimit
      this._numHoursWeeklyOvertime = adherenceSetting.numHoursWeeklyOvertime
      this._weekendAsHoliday = adherenceSetting.weekendAsHoliday
    }
    else {
      /** @private @type {number} */
      this._historicWeeks = historicWeeks;
      /** @private @type {number} */
      this._forecastWeeks = forecastWeeks;
      /** @private @type {number} */
      this._numberOfHistoricSpecialDays = numberOfHistoricSpecialDays;
      /** @private @type {number} */
      this._occupacyFte = occupacyFte;
      /** @private @type {number} */
      this._adherancePercent = adherancePercent;
      /** @private @type {number} */
      this._percentOfAbandonsToInclude = percentOfAbandonsToInclude;
      /** @private @type {number} */
      this._adherenceBase = adherenceBase;
      /** @private @type {number} */
      this._graceMinutes = graceMinutes;
      /** @private @type {number} */
      this._unScheduledBreakMinutes = unScheduledBreakMinutes;
      /** @private @type {number} */
      this._closeGaps = closeGaps;
      /** @private @type {number} */
      this._adherenceLimit = adherenceLimit;
      /** @private @type {number} */
      this._numHoursWeeklyOvertime = numHoursWeeklyOvertime;
      /** @private @type {boolean} */
      this._weekendAsHoliday = weekendAsHoliday;
    }
    /** @private @type {boolean} */
    this._hasChangesForecast = false;
    this._hasChangesAdherence = false;
  }

  get historicWeeks() {
    return this._historicWeeks
  }
  set historicWeeks(newValue) {
    if (this._historicWeeks !== newValue) {
      this._historicWeeks = newValue;
      this._hasChangesForecast = true;
    }
  }

  get forecastWeeks() {
    return this._forecastWeeks
  }
  set forecastWeeks(newValue) {
    if (this._forecastWeeks !== newValue) {
      this._forecastWeeks = newValue;
      this._hasChangesForecast = true;
    }
  }

  get numberOfHistoricSpecialDays() {
    return this._numberOfHistoricSpecialDays
  }
  set numberOfHistoricSpecialDays(newValue) {
    if (this._numberOfHistoricSpecialDays !== newValue) {
      this._numberOfHistoricSpecialDays = newValue;
      this._hasChangesForecast = true;
    }
  }

  get occupacyFte() {
    return this._occupacyFte
  }
  set occupacyFte(newValue) {
    if (this._occupacyFte !== newValue) {
      this._occupacyFte = newValue
      this._hasChangesForecast = true
    }
  }

  get adherancePercent() {
    return this._adherancePercent
  }
  set adherancePercent(newValue) {
    if (this._adherancePercent !== newValue) {
      this._adherancePercent = newValue
      this._hasChangesForecast = true
    }
  }

  get percentOfAbandonsToInclude() {
    return this._percentOfAbandonsToInclude
  }
  set percentOfAbandonsToInclude(newValue) {
    if (this._percentOfAbandonsToInclude !== newValue) {
      this._percentOfAbandonsToInclude = newValue
      this._hasChangesForecast = true
    }
  }

  get adherenceBase() {
    return this._adherenceBase
  }
  set adherenceBase(newValue) {
    if (this._adherenceBase !== newValue) {
      this._adherenceBase = newValue
      this._hasChangesAdherence = true
    }
  }

  get graceMinutes() {
    return this._graceMinutes
  }
  set graceMinutes(newValue) {
    if (this._graceMinutes !== newValue) {
      this._graceMinutes = newValue
      this._hasChangesAdherence = true
    }
  }

  get unScheduledBreakMinutes() {
    return this._unScheduledBreakMinutes
  }
  set unScheduledBreakMinutes(newValue) {
    if (this._unScheduledBreakMinutes !== newValue) {
      this._unScheduledBreakMinutes = newValue
      this._hasChangesAdherence = true
    }
  }

  get closeGaps() {
    return this._closeGaps
  }
  set closeGaps(newValue) {
    if (this._closeGaps !== newValue) {
      this._closeGaps = newValue
      this._hasChangesAdherence = true
    }
  }

  get adherenceLimit() {
    return this._adherenceLimit
  }
  set adherenceLimit(newValue) {
    if (this._adherenceLimit !== newValue) {
      this._adherenceLimit = newValue
      this._hasChangesAdherence = true
    }
  }

  get hasChanges() {
    return this._hasChangesForecast || this._hasChangesAdherence
  }

  get hasChangesForecast() {
    return this._hasChangesForecast
  }

  get hasChangesAdherence() {
    return this._hasChangesAdherence
  }
  confirmChanges() {
    this._hasChangesForecast = false
    this._hasChangesAdherence = false
  }

  get numHoursWeeklyOvertime() {
    return this._numHoursWeeklyOvertime
  }
  set numHoursWeeklyOvertime(newValue) {
    if (this._numHoursWeeklyOvertime !== newValue) {
      this._numHoursWeeklyOvertime = newValue
    }
  }

  get weekendAsHoliday() {
    return this._weekendAsHoliday
  }
  set weekendAsHoliday(newValue) {
    if (this._weekendAsHoliday !== newValue) {
      this._weekendAsHoliday = newValue
    }
  }

}

export class GeneralEmpAppSettings {
  /**
   * @param {{ publishingWeekDay: number; publishingNumberOfDays: number; requestClosedNumberOfDays: number; requestvaccationClosedNumberOfDays: number
   * ; sickRequestDisabled: boolean; weekStartDay: number; publishingFixedDate: Date; allRequestsDisabled: boolean; tradeDisabled: boolean; hideTeamSchedule: boolean; showOnlyOwnTeam: boolean
   * ;statsPayrollCodes:boolean;   statsAdherence:boolean;  statsDetailedAdherence:boolean;  statsPayrollCodesPublishingNumberOfDays:number }} setting
   */
  constructor(setting) {

    /** @private @type {number} */
    this._publishingWeekDay = setting.publishingWeekDay

    /** @private @type {number} */
    this._publishingNumberOfDays = setting.publishingNumberOfDays

    /** @private @type {Date} */
    this._publishingFixedDate = setting.publishingFixedDate

    /** @private @type {Boolean} */
    this._usePublishFixedDate = false
    if(setting.publishingFixedDate){this._usePublishFixedDate = true}

    /** @private @type {boolean} */
    this._allRequestsDisabled = setting.allRequestsDisabled

    /** @private @type {number} */
    this._requestClosedNumberOfDays = setting.requestClosedNumberOfDays

    /** @private @type {number} */
    this._requestvaccationClosedNumberOfDays = setting.requestvaccationClosedNumberOfDays

    /** @private @type {boolean} */
    this._sickRequestDisabled = setting.sickRequestDisabled

    /** @private @type {boolean} */
    this._tradeDisabled = setting.tradeDisabled

    /** @private @type {boolean} */
    this.hideTeamSchedule = setting.hideTeamSchedule

    /** @private @type {boolean} */
    this.statsPayrollCodes = setting.statsPayrollCodes

    /** @private @type {boolean} */
    this.statsAdherence = setting.statsAdherence

    /** @private @type {boolean} */
    this.statsDetailedAdherence = setting.statsDetailedAdherence

    /** @private @type {number} */
    this.statsPayrollCodesPublishingNumberOfDays = setting.statsPayrollCodesPublishingNumberOfDays

    /** @private @type {boolean} */
    this._showOnlyOwnTeam = setting.showOnlyOwnTeam

    /** @private @type {number} */
    this._weekStartDay = setting.weekStartDay

    /** @private @type {boolean} */
    this._hasChanges = false;
  }

  get publishingWeekDay() {
    return this._publishingWeekDay
  }
  set publishingWeekDay(newValue) {
    if (this._publishingWeekDay !== newValue) {
      this._publishingWeekDay = newValue
      this._hasChanges = true
    }
  }

  get publishingNumberOfDays() {
    return this._publishingNumberOfDays
  }
  set publishingNumberOfDays(newValue) {
    if (this._publishingNumberOfDays !== newValue) {
      this._publishingNumberOfDays = newValue
      this._hasChanges = true
    }
  }

  get publishingFixedDate() {
    return this._publishingFixedDate
  }
  set publishingFixedDate(newValue) {
    if (this._publishingFixedDate !== newValue) {
      this._publishingFixedDate = newValue
      this._hasChanges = true
    }
  }

  set publishingFixedDateNoChange(newValue) {
      this._publishingFixedDate = newValue
  }

  get usePublishFixedDate() {
    return this._usePublishFixedDate 
  }
  set usePublishFixedDate(newValue) {
    if (this._usePublishFixedDate  !== newValue) {
      this._usePublishFixedDate  = newValue
      this._hasChanges = true
    }
  }

  get allRequestsDisabled() {
    return this._allRequestsDisabled
  }
  set allRequestsDisabled(newValue) {
    if (this._allRequestsDisabled !== newValue) {
      this._allRequestsDisabled = newValue
      this._hasChanges = true
    }
  }

  get requestClosedNumberOfDays() {
    return this._requestClosedNumberOfDays
  }
  set requestClosedNumberOfDays(newValue) {
    if (this._requestClosedNumberOfDays !== newValue) {
      this._requestClosedNumberOfDays = newValue
      this._hasChanges = true
    }
  }

  get requestvaccationClosedNumberOfDays() {
    return this._requestvaccationClosedNumberOfDays
  }
  set requestvaccationClosedNumberOfDays(newValue) {
    if (this._requestvaccationClosedNumberOfDays !== newValue) {
      this._requestvaccationClosedNumberOfDays = newValue
      this._hasChanges = true
    }
  }

  get sickRequestDisabled() {
    return this._sickRequestDisabled
  }
  set sickRequestDisabled(newValue) {
    if (this._sickRequestDisabled !== newValue) {
      this._sickRequestDisabled = newValue
      this._hasChanges = true
    }
  }

  get tradeDisabled() {
    return this._tradeDisabled
  }
  set tradeDisabled(newValue) {
    if (this._tradeDisabled !== newValue) {
      this._tradeDisabled = newValue
      this._hasChanges = true
    }
  }

  get hideTeamSchedule() {
    return this._hideTeamSchedule
  }
  set hideTeamSchedule(newValue) {
    if (this._hideTeamSchedule !== newValue) {
      this._hideTeamSchedule = newValue
      this._hasChanges = true
    }
  }

  get statsPayrollCodes() {
    return this._statsPayrollCodes
  }
  set statsPayrollCodes(newValue) {
    if (this._statsPayrollCodes !== newValue) {
      this._statsPayrollCodes = newValue
      this._hasChanges = true
    }
  }

  get statsAdherence() {
    return this._statsAdherence
  }
  set statsAdherence(newValue) {
    if (this._statsAdherence !== newValue) {
      this._statsAdherence = newValue
      this._hasChanges = true
    }
  }

  get statsDetailedAdherence() {
    return this._statsDetailedAdherence
  }
  set statsDetailedAdherence(newValue) {
    if (this._statsDetailedAdherence !== newValue) {
      this._statsDetailedAdherence = newValue
      this._hasChanges = true
    }
  }

  get statsPayrollCodesPublishingNumberOfDays() {
    return this._statsPayrollCodesPublishingNumberOfDays
  }
  set statsPayrollCodesPublishingNumberOfDays(newValue) {
    if (this._statsPayrollCodesPublishingNumberOfDays !== newValue) {
      this._statsPayrollCodesPublishingNumberOfDays = newValue
      this._hasChanges = true
    }
  }

  get showOnlyOwnTeam() {
    return this._showOnlyOwnTeam
  }
  set showOnlyOwnTeam(newValue) {
    if (this._showOnlyOwnTeam !== newValue) {
      this._showOnlyOwnTeam = newValue
      this._hasChanges = true
    }
  }

  get weekStartDay() {
    return this._weekStartDay;
  }
  set weekStartDay(newValue) {
    if (this._weekStartDay !== newValue) {
      this._weekStartDay = newValue
      this._hasChanges = true
    }
  }

  get hasChanges() {
    return this._hasChanges
  }
  confirmChanges() { this._hasChanges = false }
}

export class HolidaySettings{
  /**
   * @param {number} id
   * @param {Date} date
   * @param {string} description
   */
  constructor(id, date, description) {
    /** @private @type {number} */
    this._id = id;
    /** @private @type {Date} */
    this._date = date;
    /** @private @type {string} */
    this._description = description;
    /** @private @type {boolean} */
    this._hasChanges = false;
    /** @private @type {boolean} */
    this._isToDelete = false;
  }

  get id(){
    return this._id
  }

  get date() {
    return this._date
  }

  set date(newValue) {
    this._date = newValue;
    this._hasChanges = true;
  }

  get description() {
    return this._description
  }

  set description(newValue) {
    this._description = newValue;
    this._hasChanges = true;
  }

  get hasChanges() {
    return this._hasChanges
  }
  confirmChanges(){this._hasChanges=false}

  get isToDelete() {
  return this._isToDelete
  }

  toDelete() {
    this._isToDelete = true
  }

}

export async function getHolidays() {
   /** @type {{id:number;date:string;description:string}[]} */
  const result = await fetchAndCheckJson(`holiday`, 'GET');
  const holidays = result.map(x => new HolidaySettings(x.id, fromISODate(x.date),x.description));
  return holidays;
}


/**
 * @param {HolidaySettings[]} holidays
 */
export async function saveHolidays(holidays) {
  return new Promise((resolve) => {
    let itemsToDelete = []
    let promesis = []
    for (let i = 0; i < holidays.length; i++) {
      const holiday = holidays[i];
      if (holiday.isToDelete) {
        promesis.push(fetchAndCheckJson(`holiday/${holiday.id}`, 'DELETE'))
        itemsToDelete.push(holiday)
      } else if (holidays[i].hasChanges) {
        if (holidays[i].id == -1) {
          promesis.push(fetchAndCheckJson(`holiday`, 'POST', { date: toISODate(holiday.date), description: holiday.description }));
        } else {
          promesis.push(fetchAndCheckJson(`holiday/${holiday.id}`, 'PUT', { date: toISODate(holiday.date), description: holiday.description }));
        }
        holiday.confirmChanges();
      }
    }
    for (let i = 0; i < itemsToDelete.length; i++) {
      var index = holidays.indexOf(itemsToDelete[i]);
      holidays.splice(index, 1);
    }
    Promise.all(promesis).then(() => {
      resolve(holidays);
    }).catch(error => {
      console.error(error.message)
    });
  });
}